import React,{useEffect, useState} from 'react'
import './defineParameters.css'
import selectedIcon from '../../../../assests/icons/concentricSelected.png'
import { defineCustomParameters,selectSelfServiceState} from '../../../../redux/Slices/SelfServiceDecision'
import { useSelector,useDispatch } from 'react-redux'
import { useForm } from "react-hook-form";
import DefineParameterMode from './DefineParametersContents/DefineParameterMode'
import Spinner from '../../../../svg/Spinner/Spinner'
import { PostDataStatus } from '../../../../library/Variables'
import DefineParameterSuccess from './DefineParametersContents/DefineParameterSuccess'
import DefineSelectOptions from './DefineParametersContents/ParamComponents/DefineSelectOptions'
import SelectOptionsSuccessful from './DefineParametersContents/ParamComponents/SelectOptionsSuccessful'
import arrowToLeft from '../../../../assests/icons/arrowToLeft.png'
import Utils from '../../../../library/Utils'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetParameter, resetRules } from '../../../../redux/Slices/SelfServiceDecision'

function DefineParameters({accessToken}) {
    
    const dispatch = useDispatch()
    const [headers, setHeader] = useState(['Parameter Name','Type of Parameter','Last Updated','Action'])
    const [isComputed,setIsComputed] = useState(false)
    const { register,getValues,setValue, formState: { errors }, handleSubmit,trigger, reset } = useForm()
    const navigate = useNavigate()
    const saveScoreCard = ()=>{
        const allFields = getValues()
        const cardObj = {
                    ...allFields,
                    isComputed:allFields.action!==''?true:false}
        setParameterState(cardObj)

        if(!Utils.isFieldEmpty(cardObj.name) && !Utils.isFieldEmpty(cardObj.displayType) 
            && !Utils.isFieldEmpty(cardObj.isComputed)){
                dispatch(defineCustomParameters({data:cardObj,accessToken}))
        }

        else{
            toast.info('Fill all fields before submitting.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
        }
        
      
    }

    const goBack = ()=>{
        navigate(-1)
        dispatch(resetParameter())
    }

    const [parameterState,setParameterState] = useState()
    const selfServiceState = useSelector(selectSelfServiceState)
    const [ParameterDisplayed,setParameterDisplayed] = useState(<DefineParameterMode
                                                                setIsComputed={setIsComputed} register={register} 
                                                                saveScoreCard={saveScoreCard} accessToken={accessToken}/>)
    const rules = [{labelOne:'Name',
                    titleOne:'Rule 1',
                    labelTwo:'Select Parameter',
                    titleTwo:'Select Parameter'},
                    {labelOne:'Rule Points',
                    titleOne:'input Point',
                    labelTwo:'Action',
                    titleTwo:'Select Action'},
                    {labelOne:'Value',
                    titleOne:'input Value',
                    labelTwo:'Passing Sentiments',
                    titleTwo:'Select Passing Sentiments'}
                ]

    useEffect(()=>{
        if(selfServiceState.customParametersCreationStatus===PostDataStatus.INITIAL){
            setParameterDisplayed(<DefineParameterMode  setIsComputed={setIsComputed} 
                                    register={register} saveScoreCard={saveScoreCard} accessToken={accessToken}/>)
        }

        else if(selfServiceState.customParametersCreationStatus===PostDataStatus.FETCHING){
            setParameterDisplayed(<Spinner/>)
        }

        else if(selfServiceState.customParametersCreationStatus===PostDataStatus.SUCCESS){
            let Options;
            if(selfServiceState.parameterOptionsStatus === PostDataStatus.INITIAL){
                Options =   <div style={{display:selfServiceState.customParametersState.displayType==='SELECT'?'block':'none',
                                width:"65%",marginTop:'30px'}}>
                                <DefineSelectOptions  
                                response={selfServiceState.customParametersState} 
                                accessToken={accessToken}
                                />
                            </div>
            }

            else if (selfServiceState.parameterOptionsStatus === PostDataStatus.FETCHING){
                Options =   <div style={{display:selfServiceState.customParametersState.displayType==='SELECT'?'block':'none',
                                marginTop:'30px'}}>
                                <Spinner/>
                            </div>
            }

            else if (selfServiceState.parameterOptionsStatus === PostDataStatus.SUCCESS){
                Options =   <div style={{display:selfServiceState.customParametersState.displayType==='SELECT'?'block':'none',
                                marginTop:'30px',width:'65%'}}>
                                <SelectOptionsSuccessful />
                            </div>
            }
            const newDisplay = 
                                <div style={{display:'flex',justifyContent:"space-between",alignItems:"start",columnGap:"20px"}}>
                                    <DefineParameterSuccess parameterState={parameterState}/>
                                    {Options}
                                </div>
            setParameterDisplayed(newDisplay)
        }

    },[selfServiceState.customParametersCreationStatus, selfServiceState.parameterOptionsStatus])
  return (
    <div style={{padding:'30px 55px',boxSizing:"border-box"}}>
        <section onClick={()=>goBack()} style={{display:'flex',cursor:'pointer',alignItems:'center',justifyContent:'left',columnGap:'5px',marginBottom:'35px'}}>
            <span style={{display:'flex',alignItems:'center',justifyContent:'center'}}><img src={arrowToLeft}/></span>
            <span style={{color:'rgba(31, 34, 40, 1)',fontSize:"16px"}}>Back to History</span>
        </section>
        <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"}}>
            <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                    Decision Engine
                </span>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"20px"}}>
                    Customize your Decision Engine
                </span>
            </div>

            {selfServiceState.parameterOptionsStatus === PostDataStatus.SUCCESS && <div onClick={()=>{dispatch(resetRules()); 
                            navigate('/DecisionEngineSelfService/RulesCreation')}} 
                            className='createEngineBut'>
                    Create Rules
            </div>}
        
        </section>

        {ParameterDisplayed}

    </div>
  )
}

export default DefineParameters