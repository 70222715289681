import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus, PostDataStatus } from '../../library/Variables';
let trackNo

  export const getCacDataAsync = createAsyncThunk('getCacData', async (params) => {
    trackNo = params.rcNumber
    let data = {
        rcNumber: params.rcNumber,
    }
    data = JSON.stringify(data)
    
   const cacData = await client.post('/verify/cac',data,
   {
     "headers":
     {
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
     }
   });

    return cacData;
   }
 );

 export const getAdvancedCacDataAsync = createAsyncThunk('getAdvancedCacData', async (params) => {
  trackNo = params.rcNumber
  let data = {
      rcNumber: params.rcNumber,
  }
  data = JSON.stringify(data)
  
 const cacData = await client.post('/verify/cac/advanced',data,
 {
   "headers":
   {
    "Authorization": "Bearer " + params.accessToken,
    'Content-Type': 'application/json'
   }
 });

  return cacData;
 }
);

 export const getTinDataAsync = createAsyncThunk('getTinData', async (params) => {
  trackNo = params.tin
  let data = {
    tin: params.tin
  }
  data = JSON.stringify(data)
 const tinData = await client.post('/verify/tin',data,
 {
   "headers":
   {
    "Authorization": "Bearer " + params.accessToken,
    'Content-Type': 'application/json'
   }
 });
  return tinData;
 }
);




export const getStandAloneKybDataHistory = createAsyncThunk('getStandAloneKybDataHistory',async (params)=> {
  try {
    const response = await client.get(
      `/verify/kychistory/${params.page}/${params.perPage}/BUSINESS`,
      {
        headers: {
          Authorization: "Bearer " + params.accessToken,
        },
      }
    );
 // This should now correctly log the data
    return response.data; // Make sure to return the correct data
  } catch (error) {
    console.error('Error fetching KYB history', error);
    throw error; // Throw error so it can be handled by Redux
  }
})

export const getReportDetailKyb = createAsyncThunk('KybReportData',async(params)=>{
  const type = params.type.toLowerCase()
  let reportData
  if(type === 'tin'){
    reportData = await client.get(`/verify/history/${type}/${params.uuid}`,{
      "headers":{
        "Authorization": "Bearer " + params.accessToken,
      }
    });
  }

  else{
    reportData = await client.get(`/verify/history/cac/${params.uuid}`,{
      "headers":{
        "Authorization": "Bearer " + params.accessToken,
      }
    });
  }
  return reportData.data;
})


export const kybDataSlice = createSlice({
  name: 'kybEntireData',
  initialState: {
    activeExtractions: [],

    cacExtraction: [],
    cacStatus:PostDataStatus.INITIAL,
    cacIsError:false,
    cacErrorMessage:null,


    advancedCacExtraction: [],
    advancedCacStatus:PostDataStatus.INITIAL,
    advancedCacIsError:false,
    advancedCacErrorMessage:null,


    tinExtraction:[],
    tinStatus:PostDataStatus.INITIAL,
    tinIsError:false,
    tinErrorMessage:null,


    extractionStatus: PostDataStatus.INITIAL,
    historyStatus: PostDataStatus.INITIAL,
    isError: false,
    errorMessage: null,
    reportData:null,
    reportStatus:FetchDataStatus.INITIAL,
    reportErrorMessage:null,
    isReportError:false,
    standAloneStatus:FetchDataStatus.INITIAL,
    standAloneData:null,
    standAloneErrorMessage:null
  },
  reducers: {
   
    addToExtractedData: (state,action) => {
      state.activeExtractions = [...state.activeExtractions,action.payload]
    },

    resetKybData: (state)=>{
      state.extractionStatus = PostDataStatus.INITIAL
      state.isError = false
      state.errorMessage = null
    },

    resetReportKyb: (state)=>{
      state.reportData = null;
      state.reportStatus = FetchDataStatus.INITIAL
      state.reportErrorMessage = null
      state.isReportError = false
    },

    resetTinData: (state)=>{
      state.tinStatus = PostDataStatus.INITIAL;
      state.tinErrorMessage = null;
      state.tinExtraction = [];
      state.tinIsError = false;
      
    },

    resetCacData: (state)=>{
      state.cacStatus = PostDataStatus.INITIAL
      state.cacErrorMessage = null
      state.cacExtraction = []
      state.cacIsError = false
    },

    resetAdvancedCacData: (state)=>{
      state.advancedCacStatus = PostDataStatus.INITIAL
      state.advancedCacErrorMessage = null
      state.advancedCacExtraction = []
      state.advancedCacIsError = false
    }
  },
  
  extraReducers(builder) {
  builder.addCase(getCacDataAsync.pending, (state) => {
    state.cacStatus = PostDataStatus.FETCHING;
  }).addCase(getCacDataAsync.fulfilled, (state,action) => {
    const newObj = action.payload
    const mimeType = 'data:image/jpeg;base64,'
    //const img = mimeType + action.payload['photo']
    const mainObj = {}
    mainObj.uuid = newObj.uuid
    mainObj.verificationType = newObj.type
    mainObj.status = 'FOUND'
    mainObj.fullName = newObj.approvedName
    mainObj.dateCreated = newObj?.dateCreated ? newObj?.dateCreated:''
    mainObj.trackingNumber = newObj.rcNumber
    state.extractionStatus = PostDataStatus.SUCCESS;
    state.cacStatus = PostDataStatus.SUCCESS
    //state.activeExtractions =  state.activeExtractions
    state.cacExtraction = action.payload
  }).addCase(getCacDataAsync.rejected, (state,action) => {
    state.cacStatus = PostDataStatus.FAILURE
    state.cacIsError = true;
    state.cacErrorMessage = action.error.message;
  })

  builder.addCase(getAdvancedCacDataAsync.pending, (state) => {
    state.advancedCacStatus = PostDataStatus.FETCHING;
  }).addCase(getAdvancedCacDataAsync.fulfilled, (state,action) => {
    const newObj = action.payload
    const mimeType = 'data:image/jpeg;base64,'
    //const img = mimeType + action.payload['photo']
    const mainObj = {}
    mainObj.uuid = newObj.uuid
    mainObj.verificationType = 'Advanced CAC'
    mainObj.status = 'FOUND'
    mainObj.fullName = newObj.approvedName
    mainObj.dateCreated = newObj?.dateCreated ? newObj?.dateCreated:''
    mainObj.trackingNumber = newObj.rcNumber
    state.advancedCacStatus = PostDataStatus.SUCCESS
    state.activeExtractions = [mainObj, state.activeExtractions]
    state.advancedCacExtraction = action.payload
  }).addCase(getAdvancedCacDataAsync.rejected, (state,action) => {
    state.advancedCacStatus = PostDataStatus.FAILURE;
    state.advancedCacIsError = true;
    state.advancedCacErrorMessage = action.error.message;
  })
  

  .addCase(getTinDataAsync.pending, (state) => {
    state.tinStatus = PostDataStatus.FETCHING;
  })
  .addCase(getTinDataAsync.fulfilled, (state,action) => {
    const newObj = action.payload
    const mainObj = {}
    mainObj.uuid = newObj.uuid
    mainObj.verificationType = newObj.type
    mainObj.status = 'FOUND'
    mainObj.fullName = newObj.taxpayerName
    mainObj.dateCreated = newObj?.dateCreated ? newObj?.dateCreated:''
    mainObj.trackingNumber = newObj.cacRegNumber
    state.extractionStatus = PostDataStatus.SUCCESS;
    state.tinStatus = PostDataStatus.SUCCESS
    //state.activeExtractions = [mainObj,...state.activeExtractions]
    state.tinExtraction = action.payload
  }).addCase(getTinDataAsync.rejected, (state,action) => {
    state.tinStatus = PostDataStatus.FAILURE;
    state.tinIsError = true;
    state.tinErrorMessage = action.error.message;
  })

  .addCase(getReportDetailKyb.pending, (state)=>{
    state.reportStatus = FetchDataStatus.FETCHING
  })
  
  .addCase(getReportDetailKyb.fulfilled, (state,action)=>{
    state.reportStatus = FetchDataStatus.SUCCESS
    state.reportData = action.payload
  })
  
  .addCase(getReportDetailKyb.rejected,(state,action)=>{
    state.reportStatus = FetchDataStatus.FAILURE
    state.reportErrorMessage = action.error.message
    state.isReportError = true
  })



  .addCase(getStandAloneKybDataHistory.pending, (state)=>{
    state.standAloneStatus = FetchDataStatus.FETCHING
  })
  .addCase(getStandAloneKybDataHistory.fulfilled,(state,action)=>{
    state.standAloneStatus = FetchDataStatus.SUCCESS
    state.activeExtractions = action.payload
  })
  .addCase(getStandAloneKybDataHistory.rejected,(state,action)=>{
    state.standAloneStatus = FetchDataStatus.FAILURE
    state.standAloneErrorMessage = action.error.message
  })
  
  }
})


export const { addToExtractedData,resetKybData,resetAdvancedCacData,resetCacData,resetTinData} = kybDataSlice.actions;


//export const selectUpdateCreditScoreStatus = state => state.creditScore.updateCreditScoreStatus;
export const selecKybExtractions = state => state.kybEntireData.activeExtractions;
export const kybEtxractionStatus = state => state.kybEntireData.extractionStatus
export const selectKybEntireDataState = state => state.kybEntireData
export const kybErrorState = state => state.kybEntireData.isError
export const kybErrorMsg = state => state.kybEntireData.errorMessage

export const {resetReportKyb} = kybDataSlice.actions


export default kybDataSlice.reducer;


/**
 * .addCase(getAllKycDataHistory.fulfilled, (state,action)=>{
    let mainArray = action.payload
    let newArray = []
    const mimeType = 'data:image/jpeg;base64,'
    for(let i=0;i<mainArray.length;i++){
      const newObj = mainArray[i]
      let img;
      let signature
      if(action.payload[i]['base64Image']){
        img = mimeType + action.payload[i]['base64Image']
      }
      
      if(action.payload[i]['photo']){
        img = action.payload[i]['photo']
      }
      if(action.payload[i]['signature']){
        signature = action.payload[i]['signature']
      }
      else{
        img = null;
      }
      
      const mainObj = {}
      delete newObj.base64Image;
      delete newObj.photo;
      delete newObj.signature;
      mainObj.data = newObj
      mainObj.kycType = action.payload[i]['verificationType']
      mainObj.status = 'FOUND'
      mainObj.img = img
      mainObj.signature = signature
      newArray.push(mainObj)
    }

    state.activeExtractions = [...newArray]
    state.historyStatus = PostDataStatus.SUCCESS;
  })
 */