import React,{useState,useEffect} from 'react'
import { addParameter, deleteParameter } from '../../../../../../redux/Slices/ParameterStateHolder'
import { useDispatch } from 'react-redux'

function ParamOptions({feature,register,optionArray,setOptionArray,setLengthOfFeatures,lengthOfFeatures,index}) {
    const [option,setOption] = useState('')
    const [added,setAdded] = useState()
    const [valueSaved,setValueSaved] = useState(false)
    const dispatch = useDispatch()
    useEffect(()=>{
        if(option===''){
            setAdded(true)
        }
        if(option.length>0){
            setAdded(false)
        }
    },[option])

    const OnParamChange = (e)=>{
        if(!valueSaved){
            setOption(e.target.value)
        }
        
    }

   

    const storeOption = (index)=>{
        if(!added){
            const mainArr = [...lengthOfFeatures]
            const arrayObject = {key:index,value:option}
            mainArr[index] = arrayObject
            setLengthOfFeatures(mainArr)
            //setOptionArray([...optionArray,arrayObject])
            setAdded(true)
            setValueSaved(true)
            dispatch(addParameter(arrayObject))
        }
        
    }

    const removeOption = (index)=>{
        let mainArr = [...lengthOfFeatures]
        mainArr.splice(index,1)
        setOptionArray(mainArr)
        dispatch(deleteParameter(index))

        let features = [...lengthOfFeatures]
        features.splice(index,1)
        setLengthOfFeatures(features)
    }
  return (
    <div  className='individualSection'>
            <input  style={{border:'2px solid rgba(223, 232, 255, 1)',height:"45px",width:'55%',paddingLeft:"30px"}} 
                placeholder='input option' autocomplete="off" type='text'
                value={valueSaved?feature:option}
                onChange={(e)=>OnParamChange(e)}
                
            />
            <div onClick={()=>storeOption(index)} className= {added?'addButton':'addButtonSelected'} >+</div>
            <div onClick={()=>removeOption(index)} className='addButtonSelected' >-</div>
                     
    </div>
  )
}

export default ParamOptions