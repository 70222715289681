import React from 'react'
import profileDemoIcon from '../../../../../../../assests/icons/profileDemoIcon.svg'
import gamblingRateIcon from '../../../../../../../assests/icons/gamblingRateIcon.svg'
import Utils from '../../../../../../../library/Utils'
import DetailsCard from '../../../../../../../components/DetailsCard/DetailsCard'
import List from '../../../../../../../components/List/List'
import { selectConfigurationState } from '../../../../../../../redux/Slices/Configuration'
import { useSelector } from 'react-redux'
import './behavioural.css'

function BehaviouralFlow() {
    const configurationState = useSelector(selectConfigurationState);
    const configuration = configurationState.configuration
 
  return (
    
    
    <div >
        
        <div style={{display:'flex',
                    columnGap:"10px",
                    justifyContent:"space-between",
                    marginBottom:"25px",
                    height:"200px",
                    alignItems:"center"}}>

            <section style={{
                boxSizing:"border-box",
                display:"flex",
                justifyContent:'space-between',
                backgroundColor:"white",
                borderRadius:"10px",
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)',
                padding:'20px',
                height:'100%',
                width:'40%',
            }}>

                <div >
                    <section>
                        <img src={profileDemoIcon}/>
                    </section>

                    <section>
                        Account Activity
                    </section>

                    <section style={{
                        color:'#2A38A4',
                        fontSize:"26px",
                        fontWeight:"700"
                    }}>
                        30%
                    </section>
                </div>

                <div>
                    <section>
                        <img src={gamblingRateIcon}/>
                    </section>

                    <section>
                        Gambling Rate
                    </section>

                    <section style={{
                        color:'#2A38A4',
                        fontSize:"26px",
                        fontWeight:"700"
                    }}>
                        5%
                    </section>
                </div>


                <div>
                    <section>
                        <img src={profileDemoIcon}/>
                    </section>

                    <section>
                        Account Sweep
                    </section>

                    <section style={{
                        color:'#2A38A4',
                        fontSize:"26px",
                        fontWeight:"700"
                    }}>
                        NO
                    </section>
                </div>
            </section>

            <div className="pipeHolder">

                <section style={{
                    display:'flex',
                    columnGap:"5px",
                    justifyContent:"center",
                    alignItems:"end",
                    width:"30%"
                }}>
                    <div style={{
                        position:'relative',
                        border:'2px solid #2A38A4',
                        width:"20px",
                        borderRadius:'0px 0px 3px 3px',
                        backgroundColor:'#DCE5F1',
                        height:"120px",
                        
                    }}>
                        <section style={{
                            position:'absolute',
                            bottom:"0px",
                            width:"100%",
                            height:'10%',
                            backgroundColor:"#2A38A4",
                            borderRadius:'0px 0px 3px 3px'
                        }}>

                        </section>

                    </div>
                    <div className='dc-status-bar-details'>
                        <p>Inflow Irregularity</p>
                        <h2 className="detail-header">10%</h2>
                    </div>
                </section>

                <section style={{
                    height:'100%',
                    width:'45%',
                    display:"flex",
                    justifyContent:"space-between",
                    flexDirection:"column"
                }}>
                    <div style={{}}>
                        <section style={{
                            fontWeight:"400",
                            color:"black",
                            textAlign:"left"
                        }}>Monthly Inflow To Outflow Rate</section>
                        <section style={{
                            fontWeight:'700',
                            color:"#2A38A4",
                            textAlign:"left",
                            fontSize:'24px'
                        }}>EQUAL</section>
                    </div>

                    <div>
                        <section style={{
                            fontWeight:"400",
                            color:"black",
                            textAlign:"left"
                        }}>Overall Inflow To Outflow Rate</section>

                        <section style={{
                            fontWeight:'700',
                            color:"#2A38A4",
                            textAlign:"left",
                            fontSize:"24px"
                        }}>EQUAL</section>
                    </div>
                </section>

                

            </div>

        </div>
        

    

        <div className="st-details-card-container-row st-details-double-colored-bottom-row">
            <DetailsCard subtitle={'Loan Amount'} detailClassName={'st-detail-color-background-container'} titleClass="detail-header"
            title={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, 300000))} 
            detailContent={<>
            <List listClass={'st-details-list'} listContainerClass={'st-detail-color-background-list-container'}
                listContent={[
                { header: 'Loan To Inflow Rate', detail: Utils.checkNull(Utils.formatPercentage(0.10))},
                { header: 'Average Monthly Loan Amount', detail: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, 30000))},
                { header: 'Credit Loan Transaction(s)', detail: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, 0))},
                
                ]} />
            </>} />
            <DetailsCard subtitle={'Loan Repayment Amount'} detailClassName={'st-detail-color-background-container'} titleClass="detail-header"
            title={Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, 200000))}
            detailContent={<>
            <List listClass={'st-details-list'} listContainerClass={'st-detail-color-background-list-container'}
                listContent={[
                    { header: 'Loan Repayment to Inflow Rate', detail: Utils.checkNull(Utils.formatPercentage(0.10))},
                    { header: 'Average Monthly Loan Repayments', detail: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, 20000))},
                    { header: 'Debit Repayment Transaction(s)', detail: Utils.checkNull(Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, 0))},
                    
                ]} />
            </>} />
        </div>
    </div>

        
  )
}

export default BehaviouralFlow