import React,{useState} from 'react'
import arrowToLeft from '../../assests/icons/arrowToLeft.png'
import SelfProceed from './Proceed'
import DefineScoreCard from './CreateDecision/DefineScoreCard/DefineScoreCard'
import DefineParameters from './CreateDecision/DefineParameters/DefineParameters'
import DefineRulesInitials from './RulesDefinition/DefineRulesInitials'
import PreviewScoreCard from './PreviewCard'



function DecisionSelfService({accessToken}) {
    const [sectionToShow,setSection] = useState('proceed')
    const [previewCard,setPreviewCard] = useState()
  return (
    <div style={{padding:'30px 55px',boxSizing:"border-box"}}>
        
        {sectionToShow==='proceed'?<SelfProceed accessToken={accessToken} previewCard={previewCard} 
                                                setPreviewCard={setPreviewCard} setSection={setSection}/>
        :sectionToShow==='preview'?<DefineScoreCard accessToken={accessToken} setSection={setSection}/>
        :sectionToShow==='viewScoreCard'?<PreviewScoreCard accessToken={accessToken} cardObj={previewCard} setSection={setSection}/>:''}
    </div>
  )
}

export default DecisionSelfService