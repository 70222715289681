import React,{useState,useEffect} from 'react'
import selectedIcon from '../../../../../assests/icons/concentricSelected.png'
import unselectedIcon from '../../../../../assests/icons/nonSelected.png'
import dropDownIcon from '../../../../../assests/icons/arrowDownWhite.png'
import DeleteIcon from '../../../../../assests/icons/Delete.svg'
import dropArrow from '../../../../../assests/icons/dropDownIcon.png'
import checkBoxIcon from '../../../../../assests/icons/checkBoxed.png'
import unCheckBoxedIcon from '../../../../../assests/icons/unCheckBoxed.png'
import { getSelfServiceCustomParams,selectSelfServiceState } from '../../../../../redux/Slices/SelfServiceDecision'
import Spinner from '../../../../../svg/Spinner/Spinner'
import { PostDataStatus,FetchDataStatus } from '../../../../../library/Variables'
import { useSelector,useDispatch } from 'react-redux'

function DefineParameterMode({register,saveScoreCard,setIsComputed,accessToken}) {
    const [toggleState,setToggleState] = useState('rule')
    const [parameterState,setParameterState] = useState('custom')
    const [showParameter,setShowParameter] = useState(false)
    const [progress,setProgress] = useState(false)
    const [addParameter,setParameter] = useState(false)
    const selfServiceState = useSelector(selectSelfServiceState)
    const TypesOfInput = ['TEXT','NUMBER','SELECT','CURRENCY']
    const firstAction = ['ADD','SUBTRACT','MULTIPLY','DIVIDE']
    const dispatch = useDispatch()


    useEffect(()=>{
        if(selfServiceState.selfServiceParametersStatus !== FetchDataStatus.SUCCESS){
            dispatch(getSelfServiceCustomParams({accessToken}))
        }
    },[])


    useEffect(()=>{
        
        if(addParameter){
            setIsComputed(true)
        }
        else{
            setIsComputed(false)
        }
    },[addParameter])
  return (
        <section style={{margin:'30px 0px',padding:'20px 0px'}}>{
            selfServiceState.selfServiceParametersStatus === FetchDataStatus.SUCCESS?
            <>
            <p style={{color:"rgba(85, 87, 90, 1)",fontSize:"18px"}}>Define Parameters</p>
            <section style={{minHeight:"350px",padding:"30px 25px",boxSizing:"border-box",display:'flex',flexDirection:"column",rowGap:'20px',justifyContent:"space-between",borderRadius:"5px",backgroundColor:"white",marginTop:"20px",boxShadow:" 0 3px 6px rgba(0, 0, 0, .1)"}}>
                <div style={{display:'flex',flexDirection:'column'}}> 
                    <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Parameter Name</label>
                    <input  style={{border:'2px solid rgba(223, 232, 255, 1)',height:"45px",paddingLeft:"30px"}} 
                            placeholder='input name' autocomplete="off" type='text'
                            {...register('name', 
                                { 
                                    required:true, 
                                })
                            }   
                            
                    />
                               
                </div>

                <div style={{display:'flex',flexDirection:'column'}}> 
                
                    <div style={{display:'flex',justifyContent:"left",columnGap:"15px"}}>
                        <section style={{display:'flex',flexDirection:"column",alignItems:"start",justifyContent:"center",columnGap:"10px"}}>
                            <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px",textAlign:"left"}}>Input Type</label>
                            
                            <select  style={{display:'flex',alignItems:"center",height:"45px",width:"300px",
                                    border:"1px solid rgba(223, 232, 255, 1)",justifyContent:"space-between",
                                    padding:"0px 7px",outline:"none"}} {...register('displayType', { required: true})} 
                            >
                                <option value="">Select an option</option>
                                {TypesOfInput.map((element, i) => {
                                    return <option value={element} key={i}>{element}</option>
                                })}
                            </select>
                           
                        </section>
                       
                    </div>
                </div>
                <div >
                    <section onClick={()=>{setParameter(!addParameter)}} className='addParams' >
                        <span className='imageHolder'>
                            <img src={addParameter?checkBoxIcon:unCheckBoxedIcon}/>
                        </span>
                        <span>
                            Computed Parameter
                        </span>
                    </section>

                    <div style={{display:addParameter?'flex':'none',columnGap:'15px',marginTop:'30px'}}>
                        <section style={{display:'flex',flexDirection:"column",alignItems:"start",justifyContent:"center",columnGap:"10px"}}>
                            <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px",textAlign:"left"}}>Parameter One</label>

                            <select  style={{display:'flex',alignItems:"center",height:"45px",width:"300px",
                                    border:"1px solid rgba(223, 232, 255, 1)",justifyContent:"space-between",
                                    padding:"0px 7px",outline:"none"}} {...register('field1', { required: true})} 
                            >
                                <option value="">Select an action</option>
                                {selfServiceState.selfServiceParameterData.map((element, i) => {
                                    return <option value={element.name} key={i}>{element.name}</option>
                                })}
                            </select>
                           
                        </section>

                        <section style={{display:'flex',flexDirection:"column",alignItems:"start",justifyContent:"center",columnGap:"10px"}}>
                            <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px",textAlign:"left"}}>Action</label>
                            
                            <select  style={{display:'flex',alignItems:"center",height:"45px",width:"300px",
                                    border:"1px solid rgba(223, 232, 255, 1)",justifyContent:"space-between",
                                    padding:"0px 7px",outline:"none"}} {...register('action', { required: true})} 
                            >
                                <option value="">Select an action</option>
                                {firstAction.map((element, i) => {
                                    return <option value={element} key={i}>{element}</option>
                                })}
                            </select>
                           
                        </section>

                        <section style={{display:'flex',flexDirection:"column",alignItems:"start",justifyContent:"center",columnGap:"10px"}}>
                            <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px",textAlign:"left"}}>Parameter Two</label>
                        
                            <select  style={{display:'flex',alignItems:"center",height:"45px",width:"300px",
                                    border:"1px solid rgba(223, 232, 255, 1)",justifyContent:"space-between",
                                    padding:"0px 7px",outline:"none"}} {...register('field2', { required: true})} 
                            >
                                <option value="">Select an action</option>
                                {selfServiceState.selfServiceParameterData.map((element, i) => {
                                    return <option value={element.name} key={i}>{element.name}</option>
                                })}
                            </select>
                           
                        </section>
                    </div>
                    <section style={{display:'flex',alignItems:'center',justifyContent:'end'}}>
                                    <div className='createParameter' onClick={()=>saveScoreCard()} >Save</div>
                                </section>
                </div>
            
            </section>
            </>:
            <Spinner/>}
        </section>
  )
}

export default DefineParameterMode