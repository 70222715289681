import React, { useEffect, useState } from "react";
import Utils from "../../../library/Utils";
import Modal from "react-modal";
import Accordion from "../../../components/Accordion/Accordion";
import ConfirmBox from "../../../components/Modals/ConfirmBox";
import { 
  useSelector,
  useDispatch
} from "react-redux";
import {
  resetAffordability,
  newAffordabilityModelAsync,
  selectAffordabilityState,
  getAffordabilityDetails,
  resetAffordabilityStatus
} from "../../../redux/Slices/Affordability";
import {
  resetWallet
} from "../../../redux/Slices/Wallet";
import "./Affordability.css";
import { useForm } from "react-hook-form";
import Spinner from "../../../svg/Spinner/Spinner";
import { FetchDataStatus } from "../../../library/Variables";

if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

function Affordability(props) {
  let accBodiesArray = [];
  let content = null;

  const configuration = props.configuration;
  const keyFromRoute = props.keyFromRoute;
  const accessToken = props.accessToken;
  const analytics = (props.analytics).activeStatement;

  const dispatch = useDispatch();
  const affordability = useSelector(selectAffordabilityState);
  const [modalIsOpen, setIsOpen] = useState(false);
  const { register, watch, formState: { errors }, handleSubmit, reset } = useForm();
  const watchDti = watch("dti");
  const watchDuration = watch("loanTenure");
  const watchAvgLoanRepay = watch("loanRepay", parseFloat(analytics?.behavioralAnalysis?.averageMonthlyLoanRepaymentAmount));

  


  const params = { 
    accessToken: accessToken,
    keyFromRoute: keyFromRoute
  };

  const customStyles = {
    content: {
      transition: "0.125s ease-in-out",
      transform: "scale(1)",
      height: "33%",
      width: "50%",
      top: "33%",
      left: "33%",
      padding: "0 0 0 0",
      overflowY: "scroll"
    },
    overlay: {
      zIndex: 1000,
      position: "absolute"
    }
  };

  function openModal(){
    setIsOpen(true);
  }

  function afterOpenModal(){}

  function closeModal(){
    setIsOpen(false);
    reset();
    dispatch(resetWallet());
    dispatch(resetAffordability());
  }

  const onSubmit = () => {
    openModal();
  }

  const handleConfirmCallback = () => {
    let generateNewAffordabilityParams = {
      dti: parseFloat(watchDti)/100,
      duration: parseFloat(watchDuration),
      avgLoanRepay: parseFloat(watchAvgLoanRepay),
      accessToken: accessToken,
      keyFromRoute: keyFromRoute
    }
        
    dispatch(newAffordabilityModelAsync(generateNewAffordabilityParams));
  }

  useEffect(() => {
    if(affordability.affordabilityDetailsStatus === FetchDataStatus.INITIAL) {
      dispatch(getAffordabilityDetails(params));
    }
  }, [affordability])

  if(affordability.affordabilityDetailsStatus === FetchDataStatus.SUCCESS && !Utils.isFieldEmpty(affordability.activeAffordability)) {
    affordability.activeAffordability.forEach((element) => {
      let accContent = <div className="affordability-breakdown-container">
        <h2 className="detail-header">Breakdown</h2>
        <div className="affordability-breakdown-details">
          <div className="affordability-breakdown-column-one">
            <div className="aff-calculated-details">
              <p>Total Affordability</p>
              <h3>{Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.affordability_amount)}</h3>
            </div>
            <div className="aff-calculated-details">
              <p>Monthly Affordability</p>
              <h3>{Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element["monthly_disposable_income/monthly_affordability_amount"])}</h3>
            </div>
          </div>
          <div  className="affordability-breakdown-column-two">
            <div className="aff-details-list">
              <p>Monthly Loan Repayment</p>
              <p>{Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, element.average_monthly_loan_repayment_amount)}</p>
            </div>
            <div className="aff-details-list">
              <p>DTI</p>
              <p>{Utils.formatPercentage(element.dti)}</p>
            </div>
            <div className="aff-details-list">
              
              {configuration?.displayName==='MoneyBuddy'?<p>{element.loanTenure +" days"}</p>
              :<p>{element.loanTenure +" months"}</p>}
            </div>
          </div>
        </div>
      </div>

      if(configuration?.displayName==='MoneyBuddy'){
        accBodiesArray.push({
          headers: [Utils.formatLongDate(element.createdDate), element.loanTenure + " days", Utils.formatPercentage(element.dti)],
          content: accContent
        });
      }

      else if (configuration?.displayName!=='MoneyBuddy'){
        accBodiesArray.push({
          headers: [Utils.formatLongDate(element.createdDate), element.loanTenure + " months", Utils.formatPercentage(element.dti)],
          content: accContent
        });
      }
      
    });

    content = <div className="affordability-details-container">
      <div className="affordability-details-header-row" >
        <h4 className="detail-header">Date</h4>
        <h4 className="detail-header">Loan Tenure</h4>
        <h4 className="detail-header">DTI</h4>
      </div>
      <Accordion accordionBodies={accBodiesArray} />
    </div>
  }

  return (
    <>
      <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles}
        contentLabel="Add Affordability Model" >
        <ConfirmBox close={closeModal} typeStatus={affordability.addAffordabilityStatus}
          initialStateTitle={"Generate New Affordability"} failTitle={"New Affordability Failed"}
          initialStateMessage={"This action will incur a charge to your account. Are you sure you wish to proceed?"}
          fetchingTitle={"Generating Affordability. Please wait..."} successTitle={"Affordability Generated"}
          successMessage={"Analysis has been completed."}
          resetStatus={() => dispatch(resetAffordabilityStatus())} parentCallback={() => handleConfirmCallback()} />
      </Modal>
      <div className="affordability-body">
        <div className="affordability-new-container">
          <div className="affordability-new-text-container">
            <div className="affordability-header-info">
              <h1 className="detail-header">Generate New Affordability</h1>
              <div className="tooltip">
                <img src="/assets/icons/info-circle-color.svg" alt="" />
                <span className="tooltip-text">
                  {"Our affordability model helps to predict future ranges of disposable income, and liability using past income. " +
                  "The model uses liability status, loan application and transaction data. This helps  " 
                  + configuration.displayName + " to drive more informed lending decisions"}
                </span>
              </div>
            </div>
            <p className="affordability-header-note text-medium" >
              A charge will be incurred each time a new Affordability Model is generated.
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="affordability-form-container">
            <div className="affordability-form-fields">
              <div className="aff-inputs-container">
                <div className="aff-inputs">
                  <h4>DTI (%)</h4>
                  <input className="inputs" type="text"  {...register("dti", { 
                    required: "DTI is required",
                    pattern: {
                      value: /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/i,
                      message: "DTI must be between 1 to 100"
                    },
                    min: {
                      value: 0.01,
                      message: "DTI must be greater than 0"
                    }
                  } )} />
                  <span className="aff-error-fields error-msg">
                    {errors.dti?.message}</span>
                </div>
                <div className="aff-inputs">
                  {configuration.displayName==='MoneyBuddy'?
                  <h4>Loan Tenure (days)</h4>
                  :<h4>Loan Tenure (months)</h4>}
                  <input className="inputs" type="text" {...register("loanTenure", 
                    { required: "Loan Tenure is required", 
                      pattern: {
                        value: /^(\d?)+(\.\d{1,2})?$/,
                        message: "Loan Duration must be a number"
                      },
                      min: {
                        value: 0.01,
                        message: "Loan Duration must be greater than 0"
                      } } )} />
                  <span className="aff-error-fields error-msg">
                    {errors.loanTenure?.message}</span>
                </div>
                <div className="aff-inputs">
                  <h4>Monthly Loan Repayment <span>(optional)</span></h4>
                  <input className="inputs" type="text" defaultValue={watchAvgLoanRepay}
                    {...register("loanRepay", { required: "Enter 0 if excluding from calculation" } )} />
                  <span className="aff-error-fields error-msg">
                    {errors.loanRepay?.message}</span>
                </div>
              </div>
              <p className="aff-default-fields-message text-medium">
                <span>Note: </span>
                Default values come from the corresponding Statement Analytics. To override default values, change them in 
                the input fields above.</p>
            </div>
            <div className="aff-btn">
              <button type="submit" className="button-square button-solid">Generate</button>
            </div>
          </form>
        </div>
        {content}
      </div>
    </>
  )
}

export default Affordability;