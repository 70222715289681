import React from 'react'
import debitArrow from '../../../../../../../assests/icons/debitArrowIcon.svg'
import creditArrow from '../../../../../../../assests/icons/creditArrowIcon.svg'
import DetailsCard from '../../../../../../../components/DetailsCard/DetailsCard'
import List from '../../../../../../../components/List/List'
import { Doughnut } from 'react-chartjs-2';
import Utils from '../../../../../../../library/Utils'

function PatternComponent() {
    let pieLabels = ['Less Than ₦10,000', 'Between ₦10,000 To ₦100,000', 'Between ₦100,000 To ₦500,000', 'Between ₦500,000 To ₦1,000,000', 'Between ₦1000,000 To ₦1,500,000', 'Between ₦1,500,000 To ₦2,000,000','Greater Than ₦2,000,000']

    let pieTransactionsData = [
        0.10,
        0.25,
        0.30,
        0.10,
        0.10,
        0.05,
        0.10
      ];
      let pieBalanceData = [
        0.05,
        0.20,
        0.05,
        0.25,
        0.25,
        0.15,
        0.05
      ];

      const pieChartColors = ["#4EB151", "#FF725E", "#CEF266", "#F2D366", "#FFAB5E", "#87DE65", "#DD2E15"];

      const pieChartOptions = {
        cutout: 90,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
        callbacks: {
          label: function(context) {
            let dataset = context.dataset.data
            let total = 1;
            let currentValue = dataset[context.dataIndex];
            let percentage = Utils.formatPercentage(currentValue/total);
      
            return percentage;
          }
        }
      },
          legend: {
            display: false
          },
        }
      }



  return (
    <>
    <div style={{backgroundColor:"white",
                borderRadius:"10px",
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)',
                padding:'20px'}}>
        <section style={{
            color:'#2A38A4',
            fontSize:"24px",
            fontWeight:"700",
            marginBottom:'30px'
        }}>
            Transactions
        </section>

        <section style={{
            padding:'0px 15px',
            boxSizing:"border-box",
            display:"flex",
            justifyContent:'space-between'
        }}>
            <div>
                <section style={{color:'#000000',fontSize:"16px",fontWeight:'700'}}>Debit</section>
                <section style={{
                        display:"flex",
                        alignItems:"center",
                        columnGap:'5px'
                    }}>
                    <section
                     style={{
                        color:'#2A38A4',
                        fontSize:'44px',
                        fontWeight:'700'
                     }}>79%</section>
                    <span 
                    style={{
                        display:"flex",
                        alignItems:"center",
                        justifyContent:'center'
                    }}>
                        <img  src={debitArrow}/>
                    </span>
                </section>
                <section>
                    <span style={{
                        color:'#363C4F',
                        fontSize:"14px",
                        fontWeight:"400"
                    }}>Last Debit: </span>
                    <span style={{color:'#000000',fontSize:"16px",fontWeight:'700'}}>July 15, 2020</span>
                </section>
                <section>
                    <span style={{
                        color:'#363C4F',
                        fontSize:"14px",
                        fontWeight:"400"
                    }}>Most frequent debit: </span>
                    <span style={{color:'#000000',fontSize:"16px",fontWeight:'700'}}>N/A</span>
                </section>
            </div>

            <div>
            <section style={{color:'#000000',fontSize:"16px",fontWeight:'700'}}>Credit</section>
                <section style={{
                        display:"flex",
                        alignItems:"center",
                        columnGap:'5px'
                    }}>
                    <section
                     style={{
                        color:'#407BFF',
                        fontSize:'44px',
                        fontWeight:'700'
                     }}>21%</section>
                    <span 
                    style={{
                        display:"flex",
                        alignItems:"center",
                        justifyContent:'center'
                    }}>
                        <img  src={creditArrow}/>
                    </span>
                </section>
                <section>
                    <span style={{
                        color:'#363C4F',
                        fontSize:"14px",
                        fontWeight:"400"
                    }}>Last Debit: </span>
                    <span style={{color:'#000000',fontSize:"16px",fontWeight:'700'}}>July 15, 2020</span>
                </section>
                <section>
                    <span style={{
                        color:'#363C4F',
                        fontSize:"14px",
                        fontWeight:"400"
                    }}>Most frequent debit: </span>
                    <span style={{color:'#000000',fontSize:"16px",fontWeight:'700'}}>N/A</span>
                </section>
            </div>
        </section>
        
    </div>
    <section style={{marginTop:'30px',height:'auto',display:"flex",justifyContent:"space-between"}}>
        <div style={{width:'45%'}}>
        <DetailsCard 
            title={'Transactions'} 
            titleClass="detail-header" 
            detailClassName={'st-detail-color-background-container'} 
            detailContent={<>
            <div className="details-doughnut" >
                <Doughnut data={{
                    labels: pieLabels,
                    datasets: [
                    {
                        data: pieTransactionsData,
                        backgroundColor: pieChartColors
                    }
                    ]
                }}  
                options={pieChartOptions}
                />
            </div>
            <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} 
            listContent={pieLabels.map((e, i) => {
              return { detail: e, color: pieChartColors[i], header: Utils.formatPercentage(pieTransactionsData[i]) }
            })} />

<div style={{
                  backgroundColor:"#2B52AA",
                  width:"100%",
                  height:'200px',
                  padding:'15px',
                  boxSizing:'border-box',
                  borderRadius:'10px',
                  display:"flex",
                  justifyContent:"space-around",
                  alignItems:"center",
                  flexDirection:"column",
                  marginTop:"30px"
                }}>
              <section style={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                flexDirection:"column"
              }}>
                <p style={{
                  color:'#FFFFFF',
                  fontSize:"14px",
                  fontWeight:"400",
                  textAlign:"center"
                }}>
                  Most Frequent Transaction Range
                </p>
                <section style={{
                  color:"#FFFFFF",
                  fontSize:"24px",
                  fontWeight:"700",
                  textAlign:"center"
                }}>
                  {pieLabels[2]}
                </section>

                
              </section>

              <section style={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                flexDirection:"column"
              
              }}>
                <p style={{
                  color:'#FFFFFF',
                  fontSize:"14px",
                  fontWeight:"400",
                  textAlign:"center"
                }}>
                  {`Number Of Days Transactions Were Less Than ${pieLabels[0]}`}
                </p>
                <section style={{
                  color:"#FFFFFF",
                  fontSize:"24px",
                  fontWeight:"700",
                  textAlign:"center"
                }}>
                  {Utils.formatPercentage(pieTransactionsData[0])}
                </section>

                
              </section>
           </div>


            
            </>}
            
             />
        </div>

        <div style={{width:'45%'}}>
        <DetailsCard 
            title={'Balance'} 
            titleClass="detail-header" 
            detailClassName={'st-detail-color-background-container'} 
            detailContent={<>
          <div className="details-doughnut" >
            <Doughnut data={{
                labels: pieLabels,
                datasets: [
                  {
                    data: pieBalanceData,
                    backgroundColor: pieChartColors
                  }
                ]
              }} options={pieChartOptions}/>
          </div>

          <List listClass={'st-details-list'} listContainerClass={'st-details-list-main-container'} 
            listContent={pieLabels.map((e, i) => {
              return { detail: e, color: pieChartColors[i], header: Utils.formatPercentage(pieTransactionsData[i]) }
            })} />

           <div style={{
                  backgroundColor:"#2B52AA",
                  width:"100%",
                  height:'200px',
                  padding:'15px',
                  boxSizing:'border-box',
                  borderRadius:'10px',
                  display:"flex",
                  justifyContent:"space-around",
                  alignItems:"center",
                  flexDirection:"column",
                  marginTop:"30px"
                }}>
              <section style={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                flexDirection:"column"
              }}>
                <p style={{
                  color:'#FFFFFF',
                  fontSize:"14px",
                  fontWeight:"400",
                  textAlign:"center"
                }}>
                  Most Frequent Transaction Range
                </p>
                <section style={{
                  color:"#FFFFFF",
                  fontSize:"24px",
                  fontWeight:"700",
                  textAlign:"center"
                }}>
                  {pieLabels[2]}
                </section>

                
              </section>

              <section style={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                flexDirection:"column"
              
              }}>
                <p style={{
                  color:'#FFFFFF',
                  fontSize:"14px",
                  fontWeight:"400",
                  textAlign:"center"
                }}>
                  {`Number Of Days Transactions Were Less Than ${pieLabels[0]}`}
                </p>
                <section style={{
                  color:"#FFFFFF",
                  fontSize:"24px",
                  fontWeight:"700",
                  textAlign:"center"
                }}>
                  {Utils.formatPercentage(pieTransactionsData[0])}
                </section>

                
              </section>
           </div>
        

          </>}
        />
        </div>
            
    </section>
    </>
  )
}

export default PatternComponent