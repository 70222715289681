import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPermissions, getAllUsers, getUserRoles, resetAddCustomRole, resetDeleteUser, resetInviteSend, resetUpdateRole, resetUpdateUser, selectUserManagementState } from "../../../redux/Slices/UserManagement";
import { FetchDataStatus, PostDataStatus } from "../../../library/Variables";
import Tabs from "../../../components/Tabs/Tabs";
import People from "./People";
import RolesAndPermissions from "./RolesAndPermissions";
import AuditSection from "./UserManagement/Audit/Audit";
import "./UserManagement.css";
import Modal from "react-modal";
import InviteUser from "../../../components/Modals/UserManagement/InviteUser";
import EditUser from "../../../components/Modals/UserManagement/EditUser";
import ViewUser from "../../../components/Modals/UserManagement/ViewUser";
import DeleteUser from "../../../components/Modals/UserManagement/DeleteUser";
import ManageRole from "../../../components/Modals/UserManagement/ManageRole";
import CustomRole from "../../../components/Modals/UserManagement/CustomRole";

function UserManagement(props) {
  let tabBodiesArray = [];
  
  const accessToken = props.accessToken;
  const platformUser = props.platformUser;
  const configuration = props.configuration;
  const dispatch = useDispatch();
  const userManagement = useSelector(selectUserManagementState);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [editModalIsOpen, setIsEditOpen] = useState(false);
  const [viewModalIsOpen, setIsViewOpen] = useState(false);
  const [deleteModalIsOpen, setIsDeleteOpen] = useState(false);
  const [roleModalIsOpen, setIsRoleModalOpen] = useState(false);
  const [addCustomRoleModalIsOpen, setIsAddCustomRoleModalOpen] = useState(false);
  const [user, setUser] = useState({});
  const [role, setRole] = useState({});

  const customStyles = {
    content: {
      transition: "0.125s ease-in-out",
      transform: "scale(1)",
      height: "33%",
      width: "50%",
      top: "33%",
      left: "33%",
      padding: "0 0 0 0",
      overflowY: "scroll"
    },
    overlay: {
      zIndex: 1000,
      position: "absolute"
    }
  };

  const customDeleteStyles = {
    content: {
      transition: "0.125s ease-in-out",
      transform: "scale(1)",
      height: "33%",
      width: "33%",
      top: "33%",
      left: "33%",
      padding: "0 0 0 0",
      overflowY: "scroll"
    },
    overlay: {
      zIndex: 1000,
      position: "absolute"
    }
  };

  const handleManageRole = (role) => {
    setRole(role);
    setIsRoleModalOpen(true);
  }

  const handleEditViewCallback = (user) => {
    setUser(user);
    setIsEditOpen(true);
  }
  
  const handleViewCallback = (user) => {
    setUser(user);
    setIsViewOpen(true);
  }

  const handleDeleteCallback = (user) => {
    setUser(user);
    setIsDeleteOpen(true);
  }

  const openModal = () => {
    setIsOpen(true);
  }

  const afterOpenModal = () => {}

  const closeModal = () => {
    if(userManagement.inviteNewUserStatus === PostDataStatus.SUCCESS || userManagement.inviteNewUserStatus === PostDataStatus.FAILURE) {
      dispatch(resetInviteSend());
    }
    setIsOpen(false);
  }

  const closeEditModal = () => {
    if(userManagement.updateUserStatus === PostDataStatus.SUCCESS || userManagement.updateUserStatus === PostDataStatus.FAILURE) {
      dispatch(resetUpdateUser());
    }
    setIsEditOpen(false);
  }

  const closeDeleteModal = () => {
    if(userManagement.deleteUserStatus === FetchDataStatus.SUCCESS || userManagement.deleteUserStatus === FetchDataStatus.FAILURE) {
      dispatch(resetDeleteUser());
    }
    setIsDeleteOpen(false);
  }

  const closeUpdateRoleModal = () => {
    if(userManagement.updateRoleStatus === PostDataStatus.SUCCESS || userManagement.updateRoleStatus === PostDataStatus.FAILURE) {
      dispatch(resetUpdateRole());
    }
    setIsRoleModalOpen(false);
  }

  const closeAddCustomRoleModal = () => {
    if(userManagement.addCustomRoleStatus === PostDataStatus.SUCCESS || userManagement.addCustomRoleStatus === PostDataStatus.FAILURE) {
      dispatch(resetAddCustomRole());
    }
    setIsAddCustomRoleModalOpen(false);
  }

  if(userManagement.userListStatus === FetchDataStatus.SUCCESS && userManagement.permissionsListStatus === FetchDataStatus.SUCCESS) {
    tabBodiesArray.push({
      title: "People",
      content: <People data={userManagement.userList} inviteNewUser={() => openModal()} editCallback={(user) => handleEditViewCallback(user)} 
        viewCallback={(user) => handleViewCallback(user)} deleteCallback={(user) => handleDeleteCallback(user)} platformUser={platformUser}
        permissions={userManagement.permissionsList} />
    })
  }

  if(userManagement.userRolesStatus === FetchDataStatus.SUCCESS && userManagement.permissionsListStatus === FetchDataStatus.SUCCESS) {
    tabBodiesArray.push({
      title: "Roles and Permissions",
      content: <RolesAndPermissions data={userManagement.userRoles} parentCallback={(role) => handleManageRole(role)} platformUser={platformUser}
        addCustomRole={() => setIsAddCustomRoleModalOpen(true)} permissions={userManagement.permissionsList} />
    })
  }  

  if(configuration.featureFlags.isAuditLogEnabled){

    tabBodiesArray.push({
      title: "Audit Logs",
      content: <AuditSection  accessToken={accessToken}/>
    })
  }
   



  useEffect(() => {
    if(userManagement.userListStatus === FetchDataStatus.INITIAL) {
      dispatch(getAllUsers(accessToken));
    }

    if(userManagement.userRolesStatus === FetchDataStatus.INITIAL) {
      dispatch(getUserRoles(accessToken));
    }

    if(userManagement.permissionsListStatus === FetchDataStatus.INITIAL) {
      dispatch(getAllPermissions(accessToken));
    }
  }, [userManagement, dispatch])

  return <>
    <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} >
      <InviteUser close={closeModal} userManagement={userManagement} accessToken={accessToken} permissions={userManagement.permissionsList}
        platformUser={platformUser} />
    </Modal>
    <Modal isOpen={editModalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeEditModal} style={customStyles} >
      <EditUser close={closeEditModal} userManagement={userManagement} user={user} accessToken={accessToken} platformUser={platformUser} />
    </Modal>
    <Modal isOpen={viewModalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={() => setIsViewOpen(false)} style={customStyles} >
      <ViewUser close={() => setIsViewOpen(false)} userManagement={userManagement} user={user} />
    </Modal>
    <Modal isOpen={deleteModalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeDeleteModal} style={customDeleteStyles} >
      <DeleteUser close={closeDeleteModal} userManagement={userManagement} user={user} accessToken={accessToken} />
    </Modal>
    <Modal isOpen={roleModalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeUpdateRoleModal} style={customStyles} >
      <ManageRole close={closeUpdateRoleModal} userManagement={userManagement} role={role} accessToken={accessToken} />
    </Modal>
    <Modal isOpen={addCustomRoleModalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeAddCustomRoleModal} style={customStyles} >
      <CustomRole close={closeAddCustomRoleModal} userManagement={userManagement} permissions={userManagement.permissionsList}
        accessToken={accessToken} />
    </Modal>
    <div className="user-management-settings">
      <Tabs tabBodies={tabBodiesArray} extraClass="boxed-tabs" />
    </div>
  </>
}

export default UserManagement;