import React, { useEffect, useMemo } from "react";
import { Table, DateRangeColumnFilter, SelectColumnFilter } from "../../../components/Table/Table";
import {
  selectTransactionState,
  getTransactions
} from '../../../redux/Slices/Transactions';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import Utils from '../../../library/Utils';
import Spinner from '../../../svg/Spinner/Spinner';
import './ConsolidatedAccounts.css';
import { FetchDataStatus } from "../../../library/Variables";
import { useNavigate, Link } from "react-router-dom";
import {
  resetActiveStatement,
  selectStatementDetailsState
} from '../../../redux/Slices/StatementDetails';

function ConsolidatedAccounts(props) {
  let preparedTableData = [];

  const user = (props.user).user;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const transactions = props.data.activeStatement.accounts.statementSummaries;
  const configuration = props.configuration;
  const keyFromRoute = props.keyFromRoute;

  const params = {
    accessToken: props.accessToken,
    key: keyFromRoute
  };

  function handleFormReset() {
    dispatch(resetActiveStatement());
  }

  const theadersTransactions = useMemo(() => [
    {
      Header: 'Account Id',
      accessor: 'accountId',
    },
    {
      Header: 'Created Date',
      accessor: 'createdDate',
      Filter: DateRangeColumnFilter,
      filter: 'dateRange'
    },
    {
      Header: 'Updated Date',
      accessor: 'updatedDate',
      Filter: DateRangeColumnFilter,
      filter: 'dateRange'
    },
    {
      Cell: data => {
        let statusDisplay = data.row.original.status;

        // if(statusDisplay === StatementStatus.NOTENOUGHTRANSACTIONS) {
        //   statusDisplay = "NOT ENOUGH TRANSACTIONS"
        // }

        return (
          <span style={{ 
            color: Utils.formatTextColor(data.row.original.status, "#000", "#E40000", "#00770C", "#FB6D06", null)}}>
            {statusDisplay}
          </span>
        );
      },
      Header: 'Status',
      accessor: 'status',
      Filter: SelectColumnFilter,
      filter: 'includes'
    },
    {
      Header: 'Type',
      accessor: 'type',
      Filter: SelectColumnFilter,
      filter: 'includes'
    },
    {
      id: 'button-column',
      Header: 'Actions',
      accessor: 'key',
      disableSortBy: true,
      Cell: data => {
        let link = `/statement/${data.row.original.key}`;
        return (
          <div className="table-row-buttons-container">
            <Link to={link} state={{ key: data.row.original.key }} className="button-square table-row-buttons edit" onClick={() => handleFormReset()}>View Analysis</Link>
          </div>
        );
       }
    }], []
  )

  // useEffect(() => {
  //   if((Utils.isFieldEmpty(transactions)) || transactions.transactionStatus === FetchDataStatus.INITIAL) {
  //     dispatch(getTransactions(params));
  //   }

  //   if(transactions.transactionStatus === FetchDataStatus.FAILURE) {
  //     navigate('/error');
  //   }
  // }, [transactions.transactionStatus, navigate, dispatch])

  if (!Utils.isFieldEmpty(transactions)) {
    transactions.forEach(element => {
      preparedTableData.push({
        "key": element.key,
        "createdDate": Utils.formatLongDate(element.createdDate),
        "updatedDate": Utils.formatLongDate(element.updatedDate),
        "type": element.type,
        "status": element.status,
        "accountId": element.accountId
      })
    });

    preparedTableData.sort((first, second) => new Date(second.date) - new Date(first.date));
  }

  return (
    <div className="accounts-main">
      <h3 className="detail-header">All Accounts</h3>
      <p className="detail-header">A list with all the banks associated with {props.data.activeStatement.customerName}</p>
      {transactions.isLoading === true
        ? <div className="spinner-container"><Spinner /></div>
        : <Table tableClass="accounts-type-table consolidated-overview-table all-accounts-table" pagination={false} pageSize={[10]} data={preparedTableData}
          columns={theadersTransactions} />
      }
    </div>
  )
}

export default ConsolidatedAccounts;