import React,{useEffect, useState} from 'react'
import './definescorecard.css'
import selectedIcon from '../../../../assests/icons/concentricSelected.png'
import unselectedIcon from '../../../../assests/icons/nonSelected.png'
import dropDownIcon from '../../../../assests/icons/arrowDownWhite.png'
import DeleteIcon from '../../../../assests/icons/Delete.svg'
import Spinner from '../../../../svg/Spinner/Spinner'
import { useForm } from "react-hook-form";
import { useDispatch,useSelector } from 'react-redux'
import { defineScoreCardName,selectSelfServiceState,getSelfServiceCustomParams } from '../../../../redux/Slices/SelfServiceDecision'
import { FetchDataStatus, PostDataStatus } from '../../../../library/Variables'
import ScoreDefinitionSuccess from './DefinitionComponents/ScoreDefinitionSuccess'
import ScoreDefinitionInitial from './DefinitionComponents/ScoreDefinitionInitial'
import ParameterList from './DefinitionComponents/ParameterList'
import Utils from '../../../../library/Utils'
import arrowToLeft from '../../../../assests/icons/arrowToLeft.png'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function DefineScoreCard({setSection,accessToken}) {
    const [toggleState,setToggleState] = useState('POINTS')
    const dispatch = useDispatch()
    const { register,getValues,setValue, formState: { errors }, handleSubmit,trigger, reset } = useForm()
    const [cardState,setCardState] = useState()
    const [ParamContent,setParamContent] = useState(<Spinner/>)
    let cardObj
    const saveScoreCard = ()=>{
        const allFields = getValues()
        cardObj = {
                    name:allFields.scoreName,
                    baseScore:parseInt(allFields.baseScore),
                    isCustomDecisionEngine:true,
                    type: toggleState==='RULES'?'RULES':'POINTS'}
        setCardState(cardObj)

        if(!Utils.isFieldEmpty(cardObj?.name) && !Utils.isFieldEmpty(cardObj?.baseScore) 
            && !Utils.isFieldEmpty(cardObj?.isCustomDecisionEngine)){
                dispatch(defineScoreCardName({data:cardObj,accessToken}))
        }
        else{
            toast.info('Fill all neccessary Fields.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
        }
        
    }
    const selfScoreDef = useSelector(selectSelfServiceState)

    const scoreDefSuccess = <ScoreDefinitionSuccess cardObj={cardState} setToggleState={setToggleState} 
                            selectedIcon={selectedIcon} unselectedIcon={unselectedIcon} setSection={setSection}/>

    const scoreDef = <ScoreDefinitionInitial toggleState={toggleState} saveScoreCard={saveScoreCard} 
                        selectedIcon={selectedIcon} unselectedIcon={unselectedIcon} 
                        setToggleState={setToggleState} register={register} setSection={setSection}/>
                       
    const [scoreCardDisplay,setScoreCardDisplay] = useState(scoreDef)


    
 
    useEffect(()=>{
        if(selfScoreDef.selfServiceCardNameStatus === PostDataStatus.INITIAL){
            setScoreCardDisplay(scoreDef)
        }

        else if(selfScoreDef.selfServiceCardNameStatus === PostDataStatus.FETCHING){
            setScoreCardDisplay(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceCardNameStatus === PostDataStatus.SUCCESS){
            setScoreCardDisplay(scoreDefSuccess)
        }
    },[selfScoreDef.selfServiceCardNameStatus,toggleState])


    useEffect(()=>{
        dispatch(getSelfServiceCustomParams({accessToken}))
    },[])

    useEffect(()=>{
        if(selfScoreDef.selfServiceParametersStatus=== FetchDataStatus.INITIAL){
            setParamContent(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceParametersStatus=== FetchDataStatus.FETCHING){
            setParamContent(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceParametersStatus=== FetchDataStatus.SUCCESS){
            const Content = <ParameterList setSection={setSection} selfScoreDef={selfScoreDef}/>
            setParamContent(Content)
        }

    },[selfScoreDef.selfServiceParametersStatus])


  return (
    <div>
        <section onClick={()=>setSection('proceed')} style={{display:'flex',cursor:'pointer',alignItems:'center',justifyContent:'left',columnGap:'5px',marginBottom:'35px'}}>
            <span style={{display:'flex',alignItems:'center',justifyContent:'center'}}><img src={arrowToLeft}/></span>
            <span style={{color:'rgba(31, 34, 40, 1)',fontSize:"16px"}}>Back to History</span>
        </section>
        <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"}}>
            <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                    Decision Engine
                </span>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"20px"}}>
                    Customize your Decision Engine
                </span>
            </div>
          
        </section>

        <section style={{margin:'30px 0px',padding:'20px 0px'}}>
            {scoreCardDisplay}
        </section>

        {selfScoreDef.selfServiceCardNameStatus === PostDataStatus.SUCCESS && ParamContent}
        
    </div>
  )
}

export default DefineScoreCard