import React,{useState,useEffect} from 'react'
import { useNavigate, useParams } from "react-router-dom";
import Button from '../../../components/Button/Button'
import Modal from "react-modal";
import {
  useSelector,
  useDispatch
} from "react-redux";
import './ConsolidatedNg.css';
import { getStatementDetails,
  selectConsolidatedStatementDetailsState } 
        from '../../../redux/Slices/ConsolidatedStatement';
import AddLinkedPdfNg from '../../../components/Modals/AddLinkedPdfNg';
import { resetDownload } from '../../../redux/Slices/DownloadPdf';
import blueInfoIcon from '../../../assests/icons/blueInfo.png'
import Tabs from '../../../components/Tabs/Tabs';
import blackInfoIcon from '../../../assests/icons/blackInfo.png'
import AffordabilityNg from './Affordability/AffordabilityNg';
import NotEnabled from '../../Statement/NotEnabled/NotEnabled';
import OverViewNg from './Overview/OverViewNg';
import AccountsNg from './Accounts/Accounts';
import { FetchDataStatus,StatementStatus } from '../../../library/Variables';
import Utils from '../../../library/Utils';
import Spinner from '../../../svg/Spinner/Spinner';
import Bureau from '../../Statement/Bureau/Bureau';
import Kyc from '../../Statement/KYC/Kyc';
import { downloadCsvAsync, resetDownloadCsv } from '../../../redux/Slices/DownloadCsv';
import StartKycTour from '../../../components/KycTour/Starter/StartKycTour';
import ConsolidatedcustomCreditScore from './ScoreCard/ConsolidatedCustomScore';
import CreditScore from '../../Statement/CreditScore/CreditScore';
import VOneCreditScore from './ScoreCard/VOneCreditScore';


function ConsolidatedNigeria(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [modalIsOpen, setIsOpen] = useState(false);
  const [refreshStatement,setRefreshStatement] = useState(false)
  const configuration = props.configuration;
  let subtitle;
  const { key } = useParams();
  const accessToken = props.accessToken;
  const user = props.user;
  const statement = useSelector(selectConsolidatedStatementDetailsState);
  const [kycTourPrompt,setKycTourPrompt] = useState(true)
  let scoreCard

  localStorage.setItem("insightsLastVisited", `/consolidatedstatementNg/${key}`);
  const [load,setLoad] = useState(false)
  let tabBodiesArray = []

  useEffect(() => {
    if (Utils.isFieldEmpty(statement) || statement.statementDetailsStatus === FetchDataStatus.INITIAL) {
      dispatch(getStatementDetails(params));
    }

    if (statement.statementDetailsStatus === FetchDataStatus.FAILURE &&
      (statement.activeStatement.processingStatus !== StatementStatus.FAILED
        && statement.activeStatement.processingStatus !== StatementStatus.NOTENOUGHTRANSACTIONS
        && statement.activeStatement.processingStatus !== StatementStatus.LESSTHAN6MONTHS
        && statement.activeStatement.processingStatus !== StatementStatus.UPLOADED)) {
      navigate("/error");
    }

    if ((statement.activeStatement !== null && statement.activeStatement !== undefined)
    && statement.statementDetailsStatus === FetchDataStatus.SUCCESS) {
  setRefreshStatement(false)
  setLoad(true)
        
    }
  }, [statement, dispatch, navigate])


  useEffect(()=>{
    if(load && refreshStatement===true){
      setLoad(false)
      dispatch(getStatementDetails(params))
    }
  },[refreshStatement])

  if ((statement.activeStatement !== null && statement.activeStatement !== undefined)
    && statement.statementDetailsStatus === FetchDataStatus.SUCCESS) {
      let overview = <OverViewNg configuration={configuration} statement={statement}/>
      let consolidatedaccounts = <AccountsNg configuration={configuration} statement={statement}/>
      
    
      tabBodiesArray.push({
        title: "Overview",
        content: overview
      });

      tabBodiesArray.push({
        title: "Accounts",
        content: consolidatedaccounts
      });
      
    }


    if (configuration.featureFlags && configuration.featureFlags.isAffordabilityEnabled === true) {
      let affordabilityNg = <AffordabilityNg analytics={statement} 
      configuration={configuration} accessToken={accessToken} keyFromRoute={key} />
   
      if (user.user.permissions.checkAffordability === true) {
        tabBodiesArray.push({
          title: "Affordability",
          content: affordabilityNg
        });
      }
    }

    else{
      let affordabilityNg =  <NotEnabled name={"Affordability"} message={"to set up affordability"} />; 
      <AffordabilityNg analytics={statement} configuration={configuration} accessToken={accessToken} keyFromRoute={key} />

      tabBodiesArray.push({
        title: "Affordability",
        content: affordabilityNg
      });
    }

  function openModal() {
    setIsOpen(true);
  }

  if (configuration.featureFlags && configuration.featureFlags.isBureauDataEnabled === true
    && user.user.permissions.checkBureauData === true) {
    let bureau = <Bureau accessToken={accessToken} configuration={configuration} keyFromRoute={key} />

    if (!Utils.isFieldEmpty(bureau)) {
      tabBodiesArray.push({
        title: "Bureau Data",
        content: bureau
      });
    }
  }

  if (configuration.featureFlags.isV2ScorecardEnabled === true && configuration.featureFlags.isV1ScorecardEnabled === false) {
    scoreCard = <ConsolidatedcustomCreditScore configuration={configuration} accessToken={accessToken} keyFromRoute={key} user={user} />
  } else if (configuration.featureFlags.isV1ScorecardEnabled === true && configuration.featureFlags.isV2ScorecardEnabled === false) {
    scoreCard = <VOneCreditScore configuration={configuration} accessToken={accessToken} keyFromRoute={key} user={user} />
  }

  if (user.user.permissions.generateScorecard === true || user.user.permissions.checkScorecard === true) {
  tabBodiesArray.push({
    title: "Score Card",
    content: scoreCard
  });
  }

  if (configuration.featureFlags && configuration.featureFlags.isKycEnabled) {

    
    let kyc_verification = <Kyc accessToken={accessToken} keyFromRoute={key}/>


    tabBodiesArray.push({
      title: "KYC Verification",
      content: kyc_verification
    });
  

}




/**
 * if (configuration.featureFlags && !configuration.featureFlags.isKycEnabled) {
 
  let kyc_verification

   if(kycTourPrompt===true){
     kyc_verification =<StartKycTour setKycTourPrompt={setKycTourPrompt}/>
   }

     tabBodiesArray.push({
       title: "KYC Verification",
       content: kyc_verification
     });
  

 }
 */

  function afterOpenModal() { }

  function closeModal() {
    setIsOpen(false);
    dispatch(resetDownload());
  }

  function downloadCsv(params) {
    dispatch(downloadCsvAsync(params));
    dispatch(resetDownloadCsv());
  }

  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '33%',
      width: '50%',
      top: '33%',
      left: '33%',
      padding: '0 0 0 0',
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };

  const params = {
    accessToken: accessToken,
    keyFromRoute: key
  };
  let button = null;
  let mainContent = null;

  if (user.user.permissions.downloadReport === true) {
    button = <div className="st-report-buttons-container">
      <Button name={"Export as .csv"} className="button-link export" iconSrc="/assets/icons/paper-download.png"
          clickFunction={() => downloadCsv(params)} />
    </div>
  }

  mainContent = <Tabs setKycTourPrompt={setKycTourPrompt} tabBodies={tabBodiesArray} extraClass="top-level-tabs" />
  let content = <div className="st-details-body">
  <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles}
    contentLabel="Add Statement" >
    <AddLinkedPdfNg setRefreshStatement={setRefreshStatement} accessToken={accessToken} statementKey={key} subtitle={subtitle} close={closeModal} userId={statement.activeStatement.userId} />
  </Modal>

  {/* <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} contentLabel="Download Report">
    <DownloadPdf accessToken={accessToken} statementKey={key} statementName={statement.activeStatement.name}
      configuration={configuration} close={closeModal} />
  </Modal> */}

  
  <div className="st-top-buttons">
    <Button name={"Back To List"} className="button-link back-button detail-header-dark" iconSrc="/assets/icons/arrow-left.png"
      clickFunction={() => navigate("/home")} />
  </div>
  <div className="st-details-main-header-container">
    <div className="st-details-main-header consolidated-header">
      <div className="st-details-main-header-stats-container">
        {/* {
          button = <div className="st-report-buttons-container">
            <Button name={"This is a consolidated statement"} className="button-link consolidated" iconSrc="/assets/icons/info-circle.png" clickFunction={() => {return}}  />
          </div>
        } */}

        <div style={{border:'0.57px solid rgba(229, 234, 244, 1)',borderRadius:'2.5px',marginBottom:'10px',backgroundColor:'rgba(229, 234, 244, 1)',padding:'10px',width:'fit-content',display:"flex",justifyContent:"space-between",alignItems:"center"}}>
          <span style={{display:"flex"}}><img src={blackInfoIcon} style={{width:'100%'}}/></span>
          <span>THIS IS A CONSOLIDATED STATEMENT.</span>
        </div>

      
        {
          button = <div className="st-report-buttons-container">
          {configuration.featureFlags.isConsolidatedStatementEnabled &&   <Button name={"Link another statement"} className="button-link link-statement" clickFunction={() => openModal()} iconSrc="/assets/icons/link-new.svg" />
          }</div>
        }
      </div>
      <div className="st-details-main-header-stats-container">
       

        {
          button = <div className="st-report-buttons-container">
          <div style={{display:'none'}}>
          <Button name={"Export as .csv"} className="button-link export" iconSrc="/assets/icons/paper-download.png"
              clickFunction={() => downloadCsv(params)} />
          </div>
        </div>
        }
      </div>

      <div>
        <span style={{fontSize:'26px',fontWeight:'500'}}>
          {statement.activeStatement.baseStatementName}
        </span>
      </div>
      <div className="customer-id-consolidated">
        <h4> <span> ACCOUNT NAME: </span> 
             <>
              {
                (Utils.isFieldEmpty(statement.activeStatement.customerName) || statement.activeStatement.customerName===' ')?
                <>N/A</>:
                <>{statement.activeStatement.customerName}</> 
              }
             </>
             
        </h4>
      </div>

      <div className="customer-id-consolidated">
        <h4> <span> CUSTOMER ID: </span><>{statement?.activeStatement?.userId}</></h4>
      </div>
      <div style={{border:'0.57px solid rgba(152, 203, 255, 1)',
      borderRadius:'2.5px',marginBottom:'10px',backgroundColor:'rgba(232, 245, 255, 1)',
      padding:'10px',width:'fit-content',display:"flex",justifyContent:"space-between",
      alignItems:"center"}}>
        <span style={{display:"flex"}}><img src={blueInfoIcon} style={{width:'100%'}}/></span>
        <span>Ths name is information pulled from BVN details submitted.</span>
      </div>
    </div>
  </div>
  <div className="st-main-details-container">
  </div>
  {mainContent}
</div>
  return (
    <>{
     !load?<Spinner/>:
    <div>{content}</div>}
    </>
  )
}

export default ConsolidatedNigeria