
import { TransactionDataHistory } from '../DataHolder/TransactDataBank'

function TransactionHist({TransactionDataHistory,listHeader,fullWidth}) {
    return (
      <section className={fullWidth?'AgentTable':'AgentTableSmall'}>
              <section className='titleBreakHold'>
                <p style={{
                    color:'#2A38A4',
                    fontSize:"28px",
                    fontWeight:"700"
                }}>{TransactionDataHistory.title}</p>
                
              </section>
  
              <div>
                <section style={{display:"flex",justifyContent:'space-around',marginTop:"20px"}}>
                  {listHeader.map((header,index)=>(header==='Action'?
                    <section style={{fontWeight:"700",width:"10%"}}>{header}</section>
                  :<section style={{fontWeight:"700",width:"18%",textAlign:"center"}}>{header}</section>)
                                    )}
                </section>
                <section className='TransactTableRow'>
                  {TransactionDataHistory.data.map((content,index)=>
                  
                  (fullWidth?<div  key={index}>
                    <section>{content.date}</section>
                    <section>{content.description}</section>
                    <section>{content.type}</section>
                    <section>{content.amount}</section>
                    <section>{content.balance}</section>
                  </div>:
                  <div  key={index}>
                    <section>{content.year}</section>
                    <section>{content.count}</section>
                    <section>{content.amount}</section>
                  </div>
                  ))}
                </section>
              </div>
          </section>
    )}

export default TransactionHist