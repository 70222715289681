import React,{useState,useEffect} from 'react'
import { monthlyRepaymentReShape } from './AccMonthlySerializer'
import arrowDownIcon from '../../../../assests/svg-icons/arrowDownThick.svg'
import { monthlyPayReformat } from './SubTabSerializer'
import BreakDownSlice from './BreakDownSlice'
import deleteIcon from '../../../../assests/svg-icons/minusButton.svg'
import AddIcon from '../../../../assests/svg-icons/addButton.svg'
import ArrowUp from '../../../../assests/svg-icons/arrowDownIcon.svg'
import ExtraInfoSlice from './ExtraInfoSlice'
import Utils from '../../../../library/Utils'
import './Repayment.css'

function MonthlyRepaymentTable({userReport}) {

    const headers = ['Subscriber Name','Account Number','Company Name','Date Opened','Date Closed',' ']
    const [mainContent,setMainContent] = useState([])
    const [brokenData,setBrokenData] = useState([])
    const [indexSelected,setIndexSelected] = useState()

    const generateBreakDown = (index)=>{
        if(index===indexSelected){
            setIndexSelected()
            setBrokenData([])
        }
        else{

            const formatted = monthlyPayReformat(userReport.data.report.accountMonthlyPaymentHistory[index])
            setBrokenData(formatted)
            setIndexSelected(index)
        }
        
    }

    useEffect(()=>{
        const data = monthlyRepaymentReShape(userReport.data.report.accountMonthlyPaymentHistory)
        setMainContent(data.data)
    },[])

  return (
    <div className='repaymentHolder'>
        <section className='paySectionOne'>
            Account Information
        </section>

        <section className='paySectionTwo'>
            {headers.map((element,index)=>
            <section className={index===0?'firstItemTitle':index===1?'secondTitle':
            index===headers.length-1?'fourthTitle': 'thirdTitle'}>
                {element}
            </section>)}
        </section>
        <section >
                {!Utils.isFieldEmpty(mainContent) && mainContent.map((element,index)=>
                <>
                    <section className='paySectionThree'>
                        {element.map((item,indexTwo)=>
                        <section className={indexTwo===0?'firstItemTitle':indexTwo===1?'secondTitle':'thirdTitle'}>
                            {item}
                        </section>)}
                        <section className='fourthTitle'
                                 onClick={()=>generateBreakDown(index)}>
                            <img style={{cursor:"pointer"}} src={index===indexSelected?ArrowUp: arrowDownIcon}/>
                        </section>
                    </section>
                    <section style={{
                        display:"flex",
                        justifyContent:"start",
                        flexWrap:'wrap',
                        rowGap:'10px',
                        columnGap:'10px',
                        marginBottom:'30px'
                    }}>
                        {index===indexSelected && 
                        <>
                            {
                                !Utils.isFieldEmpty(userReport.data.report.accountMonthlyPaymentHistory[indexSelected]) &&
                                <ExtraInfoSlice data={userReport.data.report.accountMonthlyPaymentHistory[indexSelected]}/>
                            }
                            <div style={{
                                width:"100%",
                                fontSize:'18px',
                                fontWeight:"700",
                                backgroundColor:"#EBF4FF",
                                padding:"10px 20px",
                                boxSizing:"border-box"
                            }}>
                                Account Summary
                            </div>
                        </>
                        
                        
                        }
                        {!Utils.isFieldEmpty(brokenData) && brokenData.map((element,indexBro)=>
                            <BreakDownSlice indexSelected={indexSelected}
                                            index={index}
                                            element={element}/>
                        )}
                    </section>
                </>
                )
                }
            </section>
    </div>
  )
}

export default MonthlyRepaymentTable