import React,{useEffect, useState} from 'react'
import './preview.css'
import selectedIcon from '../../../assests/icons/concentricSelected.png'
import unselectedIcon from '../../../assests/icons/nonSelected.png'
import Spinner from '../../../svg/Spinner/Spinner'
import { useDispatch,useSelector } from 'react-redux'
import { selectSelfServiceState,getSelfServiceCustomParams } from '../../../redux/Slices/SelfServiceDecision'
import { FetchDataStatus,PostDataStatus } from '../../../library/Variables'
import ParameterList from '../CreateDecision/DefineScoreCard/DefinitionComponents/ParameterList'
import Utils from '../../../library/Utils'
import arrowToLeft from '../../../assests/icons/arrowToLeft.png'


function PreviewScoreCard({setSection,accessToken,cardObj}) {

    const dispatch = useDispatch()
    const [ParamContent,setParamContent] = useState(<Spinner/>)
    
  
    const selfScoreDef = useSelector(selectSelfServiceState)



    useEffect(()=>{
        dispatch(getSelfServiceCustomParams({accessToken}))
    },[])

    useEffect(()=>{
        if(selfScoreDef.selfServiceParametersStatus=== FetchDataStatus.INITIAL){
            setParamContent(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceParametersStatus=== FetchDataStatus.FETCHING){
            setParamContent(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceParametersStatus=== FetchDataStatus.SUCCESS){
            const Content = <ParameterList setSection={setSection} selfScoreDef={selfScoreDef}/>
            setParamContent(Content)
        }

    },[selfScoreDef.selfServiceParametersStatus])


  return (
    <div>
        <section onClick={()=>setSection('proceed')} style={{display:'flex',cursor:'pointer',alignItems:'center',justifyContent:'left',columnGap:'5px',marginBottom:'35px'}}>
            <span style={{display:'flex',alignItems:'center',justifyContent:'center'}}><img src={arrowToLeft}/></span>
            <span style={{color:'rgba(31, 34, 40, 1)',fontSize:"16px"}}>Back to History</span>
        </section>
        <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"}}>
            <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                    Decision Engine
                </span>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"20px"}}>
                    Customize your Decision Engine
                </span>
            </div>
           
        </section>

        <section style={{margin:'30px 0px',padding:'20px 0px'}}>
            <div> 
                <p style={{color:"rgba(85, 87, 90, 1)",fontSize:"18px",textAlign:"center"}}>Preview Score Card</p>
                <section style={{height:"400px",rowGap:"20px",padding:"20px 25px",boxSizing:"border-box",display:'flex'
                        ,flexDirection:"column",justifyContent:"space-around",alignItems:'center',borderRadius:"5px"
                        ,backgroundColor:"white",marginTop:"20px",boxShadow:" 0 3px 6px rgba(0, 0, 0, .1)"}}>

                        <p style={{textAlign:"center",fontSize:"35px",fontWeight:"700"}}>{cardObj?.name}</p>
                        <div style={{display:'flex'}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Name: &nbsp; &nbsp;</span>
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{cardObj?.name}</span>
                                
                        </div>
                    
                        <div style={{display:'flex'}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Base Score: &nbsp; &nbsp;</span>
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{cardObj?.baseScore? cardObj.baseScore:'nil'}</span>
                                
                        </div>

                        <div style={{display:'flex',alignItems:'center',justifyContent:"center"}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Type: &nbsp; &nbsp;</span>
                            <div style={{display:'flex',columnGap:"20px"}}>
                                <section className={cardObj?.type==='RULES'?'selectToggle':'untoggle'}>
                                    <span><img src={cardObj?.type==='RULES'?selectedIcon:unselectedIcon}/></span>
                                    <span className='toggletext'>Rule-based</span>
                                </section>

                                <section className={cardObj?.type==='POINTS'?'selectToggle':'untoggle'}>
                                    <span><img src={cardObj?.type==='POINTS'?selectedIcon:unselectedIcon}/></span>
                                    <span className='toggletext'>Point-based</span>
                                </section>
                                    
                            </div>
                                
                        </div>



                </section>

        
            </div>
        </section>

        {ParamContent}
        
    </div>
  )
}

export default PreviewScoreCard