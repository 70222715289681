import React,{useState,useEffect} from 'react'
import scanIcon from '../../../assests/icons/scanIcon.svg'
import './proceed.css'
import { getSelfServiceScoreCardDefinitions,selectSelfServiceState } from '../../../redux/Slices/SelfServiceDecision'
import { useDispatch,useSelector } from 'react-redux'
import Spinner from '../../../svg/Spinner/Spinner'
import { FetchDataStatus } from '../../../library/Variables'
import cardLogo from '../../../assests/icons/cardIcon.svg'
import editLogo from '../../../assests/icons/editIconBox.png'
import arrowToLeft from '../../../assests/icons/arrowToLeft.png'
import { useNavigate } from "react-router-dom";
import { resetRules,resetParameter } from '../../../redux/Slices/SelfServiceDecision'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function SelfProceed({setSection,accessToken,setPreviewCard,previewCard}) {
    const dispatch = useDispatch()
    const [loader,setLoader] = useState(true)
    const selfServiceState = useSelector(selectSelfServiceState)
    const navigate = useNavigate();
    

    const previewScoreCard = (scoreDetails) =>{
        const objtoPrev = {name:scoreDetails.scorecardDefinitionName,
                            type:scoreDetails.scorecardDefinitionType,
                            baseScore:''}
        setPreviewCard(objtoPrev)
        setSection('viewScoreCard')
    }


    useEffect(()=>{
        dispatch(getSelfServiceScoreCardDefinitions({accessToken}))
    },[])
    let mainContent = <section style={{display:'flex',alignItems:"center",justifyContent:"center"}}>
                            <Spinner/>
                          </section>

    if(selfServiceState.selfServiceDefinitionListStatus === FetchDataStatus.INITIAL){
        mainContent = <section style={{display:'flex',alignItems:"center",justifyContent:"center"}}>
                            <Spinner/>
                          </section>
    }

    if(selfServiceState.selfServiceDefinitionListStatus === FetchDataStatus.FETCHING){
        mainContent = <section style={{display:'flex',alignItems:"center",justifyContent:"center"}}>
                            <Spinner/>
                          </section>
    }

    if(selfServiceState.selfServiceDefinitionListStatus === FetchDataStatus.SUCCESS){
        mainContent = <>
                        <section onClick={()=>navigate('/decisionengine')} style={{display:'flex',cursor:'pointer',alignItems:'center',justifyContent:'left',columnGap:'5px',marginBottom:'35px'}}>
                            <span style={{display:'flex',alignItems:'center',justifyContent:'center'}}><img src={arrowToLeft}/></span>
                            <span style={{color:'rgba(31, 34, 40, 1)',fontSize:"16px"}}>Back to History</span>
                        </section>
                        <section style={{display:'flex',alignItems:'start',rowGap:'20px',flexDirection:'column',justifyContent:"space-between"}}>
                            <div style={{display:'flex',columnGap:"5px"}}>
                                <span style={{display:'flex',alignItems:'center',justifyContent:"center"}}>
                                    <img src={scanIcon}/>
                                </span>
                                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                                    Custom Decision Engine
                                </span>
                            </div>
                            <div style={{display:"flex",columnGap:'15px'}}>
                                <div onClick={()=>setSection('preview')} className='createEngineBut'>
                                    Create Decision Engine
                                </div>
                                <div onClick={()=>{dispatch(resetRules());
                                                dispatch(resetParameter())
                                                navigate('/DecisionEngineSelfService/ParameterCreation')}} 
                                                className='createEngineBut'>
                                    Create Parameters
                                </div>
                                <div onClick={()=>{dispatch(resetRules()); navigate('/DecisionEngineSelfService/RulesCreation')}} className='createEngineBut'>
                                    Create Rules
                                </div>
                            </div>
                        </section>

                        <section style={{marginTop:'30px',padding:'20px 0px'}}>
                            <p style={{color:'rgba(31, 34, 39, 1)',fontSize:"20px",fontWeight:'700'}}>Select a card to proceed</p>
                        </section>

                        <section style={{display:'flex',justifyContent:'start',flexWrap:'wrap',columnGap:'15px',rowGap:'15px'}}>
                            {selfServiceState.selfServiceDefinitionList.map((card,index)=><div className='selfCards' key={index}>
                                <section style={{display:'flex',justifyContent:'space-between'}}>
                                    <span style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={cardLogo}/></span>
                                    <div style={{display:'flex',columnGap:'10px',alignItems:"center",justifyContent:"center"}}>
                                        <span style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={editLogo}/></span>
                                        <span onClick={()=>previewScoreCard(card)} style={{color:'rgba(42, 56, 164, 1)',fontSize:'16px',fontWeight:"500",cursor:'pointer'}}>View</span>
                                    </div>
                                </section>
                                <section style={{color:'rgba(54, 60, 79, 1)',fontSize:"20px",fontWeight:'500'}}>
                                    {card.scorecardDefinitionName}
                                </section>
                                <section style={{fontSize:'12px'}}>
                                    {`This scored card is for ${card.scorecardDefinitionName}`}
                                </section>
                            </div>)}
                        </section>
                     </>
    }

    if(selfServiceState.selfServiceDefinitionListStatus === FetchDataStatus.FAILURE){
        mainContent = <>
                        <section onClick={()=>navigate('/decisionengine')} style={{display:'flex',cursor:'pointer',alignItems:'center',justifyContent:'left',columnGap:'5px',marginBottom:'35px'}}>
                            <span style={{display:'flex',alignItems:'center',justifyContent:'center'}}><img src={arrowToLeft}/></span>
                            <span style={{color:'rgba(31, 34, 40, 1)',fontSize:"16px"}}>Back to History</span>
                        </section>
                        <section style={{display:'flex',alignItems:'start',rowGap:'20px',flexDirection:'column',justifyContent:"space-between"}}>
                            <div style={{display:'flex',columnGap:"5px"}}>
                                <span style={{display:'flex',alignItems:'center',justifyContent:"center"}}>
                                    <img src={scanIcon}/>
                                </span>
                                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                                    Custom Decision Engine
                                </span>
                            </div>
                            <div style={{display:"flex",columnGap:'15px'}}>
                                <div onClick={()=>setSection('preview')} className='createEngineBut'>
                                    Create Decision Engine
                                </div>
                                <div onClick={()=>{dispatch(resetRules());
                                                dispatch(resetParameter())
                                                navigate('/DecisionEngineSelfService/ParameterCreation')}} 
                                                className='createEngineBut'>
                                    Create Parameters
                                </div>
                                <div onClick={()=>{dispatch(resetRules()); navigate('/DecisionEngineSelfService/RulesCreation')}} className='createEngineBut'>
                                    Create Rules
                                </div>
                            </div>
                        </section>
                        <section style={{padding:'20px 0px',width:"75%",margin:"0px auto"}}>
                            <p style={{color:"rgba(85, 87, 90, 1)",fontSize:"18px",textAlign:"center"}}>Rule Definition Failed!</p>
                            <section style={{minHeight:"350px",padding:"30px 25px",boxSizing:"border-box",display:'flex',alignItems:"center",flexDirection:"column",rowGap:'20px',justifyContent:"space-between",borderRadius:"5px",backgroundColor:"white",marginTop:"20px",boxShadow:" 0 3px 6px rgba(0, 0, 0, .1)"}}>
                                <p style={{textAlign:"center",color:"red",fontSize:"35px",fontWeight:"700"}}>ERROR!!!</p>
                                <p style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>Your Rule has not been Created.</p>
                            </section>
                        </section>
                    </>

    }
  return (
    <div>
        {mainContent}
    </div>
  )
}

export default SelfProceed