import React from 'react'
import { AgentsList } from '../MakeUpData/ListOfAgents/AgentsListPreview'
import { useNavigate } from 'react-router'


function ListOfAgents() {
    const listAgentsHeader = ['Agent ID','Agent Name','Business Name','Location','Eligible Amount','Action']
    const navigate = useNavigate()
  return (
    <section className='AgentTable'>
            <section className='titleBreakHold'>
              <p className='title'>{AgentsList.title}</p>
              
            </section>

            <div>
              <section style={{display:"flex",justifyContent:'space-around',marginTop:"20px"}}>
                {listAgentsHeader.map((header,index)=>(header==='Action'?
                  <section style={{fontWeight:"700",width:"10%"}}>{header}</section>
                :<section style={{fontWeight:"700",width:"18%"}}>{header}</section>)
                                  )}
              </section>
              <section>
                {AgentsList.data.map((content,index)=>
                
                <section className='AgentTableRow' key={index}>
                  <section>{content.ID}</section>
                  <section>{content.AgentName}</section>
                  <section>{content.BusinessName}</section>
                  <section>{content.Location}</section>
                  <section>{content.amount}</section>
                  <div 
                  onClick={()=>navigate('/agentBreakdown')}
                  style={{fontWeight:"700",width:"10%",display:'flex',
                              justifyContent:"center",alignItems:"center",
                              cursor:"pointer",backgroundColor:"#2B52AA",
                              color:"white",padding:"10px 0px",
                              borderRadius:"5px"}}>View</div>
                </section>)}
              </section>
            </div>
        </section>
  )
}

export default ListOfAgents