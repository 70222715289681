import React from 'react'
import arrowToLeft from '../../../../../assests/icons/arrowToLeft.png'

function ScoreDefinitionSuccess({cardObj,setToggleState,selectedIcon,unselectedIcon,setSection}) {
  return (
    
        <div> 
                <p style={{color:"rgba(85, 87, 90, 1)",fontSize:"18px",textAlign:"center"}}>Defined Score Card</p>
                <section style={{height:"400px",rowGap:"20px",padding:"20px 25px",boxSizing:"border-box",display:'flex'
                        ,flexDirection:"column",justifyContent:"space-around",alignItems:'center',borderRadius:"5px"
                        ,backgroundColor:"white",marginTop:"20px",boxShadow:" 0 3px 6px rgba(0, 0, 0, .1)"}}>

                        <p style={{textAlign:"center",fontSize:"35px",fontWeight:"700"}}>Successful!!!</p>
                        <div style={{display:'flex'}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Name: &nbsp; &nbsp;</span>
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{cardObj?.name}</span>
                                
                        </div>
                    
                        <div style={{display:'flex'}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Base Score: &nbsp; &nbsp;</span>
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{cardObj?.baseScore? cardObj.baseScore:'nil'}</span>
                                
                        </div>

                        <div style={{display:'flex',alignItems:'center',justifyContent:"center"}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Type: &nbsp; &nbsp;</span>
                            <div style={{display:'flex',columnGap:"20px"}}>
                                <section className={cardObj?.type==='RULES'?'selectToggle':'untoggle'}>
                                    <span><img src={cardObj?.type==='RULES'?selectedIcon:unselectedIcon}/></span>
                                    <span className='toggletext'>Rule-based</span>
                                </section>

                                <section className={cardObj?.type==='POINTS'?'selectToggle':'untoggle'}>
                                    <span><img src={cardObj?.type==='POINTS'?selectedIcon:unselectedIcon}/></span>
                                    <span className='toggletext'>Point-based</span>
                                </section>
                                    
                            </div>
                                
                        </div>



                </section>

        
            </div>

  )
}

export default ScoreDefinitionSuccess