import React from 'react'
import selectedIcon from '../../../../../assests/icons/concentricSelected.png'
import unselectedIcon from '../../../../../assests/icons/nonSelected.png'
import dropDownIcon from '../../../../../assests/icons/arrowDownWhite.png'
import DeleteIcon from '../../../../../assests/icons/Delete.svg'
import dropArrow from '../../../../../assests/icons/dropDownIcon.png'
import checkBoxIcon from '../../../../../assests/icons/checkBoxed.png'
import unCheckBoxedIcon from '../../../../../assests/icons/unCheckBoxed.png'

function DefineParameterSuccess({parameterState}) {
  return (
    <section style={{margin:'30px 0px',padding:'20px 0px',flex:'1'}}>
            <p style={{color:"rgba(85, 87, 90, 1)",fontSize:"18px",textAlign:"center"}}>Defined Parameter</p>
            <section style={{minHeight:"350px",padding:"30px 25px",boxSizing:"border-box",display:'flex',alignItems:"center",flexDirection:"column",rowGap:'20px',justifyContent:"space-between",borderRadius:"5px",backgroundColor:"white",marginTop:"20px",boxShadow:" 0 3px 6px rgba(0, 0, 0, .1)"}}>
                <p style={{textAlign:"center",fontSize:"35px",fontWeight:"700"}}>Successful!!!</p>
                <div style={{display:'flex'}}> 
                    <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Parameter Name: &nbsp; &nbsp;</span>
                    <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{parameterState?.name}</span>
                          
                </div>

                <div style={{display:'flex'}}> 
                    <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Display Type: &nbsp; &nbsp;</span>
                    <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{parameterState?.displayType}</span>
                          
                </div>

                <div style={{display:parameterState?.isComputed?'flex':'none',flexDirection:"column",alignItems:'center'}} >
                    <section  className='addParams' >
                        <span className='imageHolder'>
                            <img src={checkBoxIcon}/>
                        </span>
                        <span>
                            Computed Parameter
                        </span>
                    </section>

                    <div style={{display:'flex',rowGap:'15px',flexDirection:"column",marginTop:'30px'}}>
                        
                        <div style={{display:'flex'}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Field One: &nbsp; &nbsp;</span>
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{parameterState?.field1}</span>
                          
                        </div>

                        <div style={{display:'flex'}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Action: &nbsp; &nbsp;</span>
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{parameterState?.action}</span>
                          
                        </div>
                     
                        <div style={{display:'flex'}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Field Two: &nbsp; &nbsp;</span>
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{parameterState?.field2}</span>
                          
                        </div> 
                    </div>
                  
                </div>
            </section>
        </section>
  )
}

export default DefineParameterSuccess