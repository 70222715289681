import { memo, useMemo, useState } from "react";
import Dots from "../../../../components/Table/Dots";
import {
  DOTS,
  useCustomPagination,
} from "../../../../components/Table/UseCustomPagination";
import Spinner from "../loader";
import "./index.css";

// import { Spinner } from '../Loader';

const classes = {
  table: {
    borderCollapse: "separate",
    width: "100%",
    textAlign: "left",
    borderSpacing: "0 15px",
    position: "relative",
    boxSizing: "border-box",
    // padding: "2rem 2.5rem",
  },

  row: {
    border: "1px solid red",
    marginBottom: "5px",
    borderSpacing: "10px 15px",
  },

  main: {
    width: "100%",
    textAlign: "left",
    padding: "2rem",
    borderCollapse: "separate",
    borderSpacing: "0 15px",
    position: "relative",
  },
};

export const Table = memo(
  ({
    data = [],
    columns,
    error,
    rowSelection,
    page,
    canPreviousPage,
    canNextPage,
    selectedPage,
    pageCount,
    gotoPage,
    nextPage,
    maxPagesNumber,
    previousPage,
    showPagination,
    totalPage,
    loading,
    state: { pageIndex } = { pageIndex: 0 },
  }) => {

    const paginationRange = useCustomPagination({
      totalPageCount: pageCount,
      currentPage: pageIndex,
    });

    const [sortConfig, setSortConfig] = useState({
      key: null,
      direction: "ascending",
    });

    const sortedData = useMemo(() => {
      let sortableItems = [...data];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [data, sortConfig]);

    const sortByHandler = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    const getSortPlaceholder = (column) => {
      if (column.sortable) {
        if (
          column.field === sortConfig.key &&
          sortConfig.direction === "ascending"
        ) {
          return "🡡";
        } else if (
          column.field === sortConfig.key &&
          sortConfig.direction === "descending"
        ) {
          return "🡣";
        } else {
          return " ⇅ Sort";
        }
      }
    };
    return (
      <>
        <table style={classes.table}>
          <thead className="thead">
            <tr className="tr">
              {columns.map((column, index) => (
                <td className="th" onClick={() => sortByHandler(column?.field)}>
                  {column.title}{" "}
                  <span className="text-medium">
                    {getSortPlaceholder(column)}
                  </span>
                </td>
              ))}
            </tr>
          </thead>
          <tbody className="tbody">
            {loading && (
              <div className="spinnerWrapper">
                <Spinner visible={loading} color="#130F26" size={20} />
              </div>
            )}

            {!loading && error && <div className="errorWrap">{error}</div>}

            {!loading && sortedData && sortedData.length ? (
              sortedData.map((entry, entryIndex) => (
                <tr key={entry?.id || entryIndex} component="tr" className="tr">
                  {columns.map(({ Cell, field, title }, columnIndex) => (
                    <td key={title + columnIndex} component="th" className="th">
                      {Cell ? <Cell entry={entry} /> : entry[field]}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <>
                {!loading && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                    }}
                  >
                    No Data
                  </div>
                )}
              </>
            )}
          </tbody>
        </table>

         {showPagination && !loading && data && data.length ? (
          <div className="pagination">
            <div className="pagination-arrows">
              <button
                onClick={() => gotoPage(1)}
                
                disabled={selectedPage === 1  ? true: false}
              >
                <img src="/assets/icons/left-double.svg" alt="" />
              </button>
              <button
                onClick={() => {
                  previousPage();
                }}
                disabled={selectedPage === 1  ? true: false}
              >
                <img src="/assets/icons/left.svg" alt="" />
              </button>
            </div>
            <div className="pagination-center">
              {paginationRange?.map((pageNumber, i) => {
                if (pageNumber === DOTS) {
                  return (
                    <Dots
                      key={i}
                      pageCount={pageCount}
                      gotoPage={(page) => gotoPage(page)}
                    />
                  );
                } else {
                  return (
                    <button
                      key={i}
                      className="pagination-page-number-button"
                      id={
                       pageNumber === selectedPage
                          ? "active-button-page"
                          : null
                      }
                      onClick={() => gotoPage(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  );
                }
              })}
            </div>
            <div className="pagination-arrows">
              <button
                onClick={() => {
                  nextPage();
                }}
                disabled={selectedPage+1 > maxPagesNumber ? true: false}
              >
                <img src="/assets/icons/right.svg" alt="" />
              </button>
              <button
                onClick={() => {
                  gotoPage(maxPagesNumber)
                }}
                disabled={selectedPage+1 > maxPagesNumber ? true: false}
              >
                <img src="/assets/icons/right-double.svg" alt="" />
              </button>
            </div>
          </div>
        ) : null} 
      </>
    );
  }
);

Table.displayName = "Table";
