import React,{useRef,useState} from 'react'
import bankIcon from '../../assests/icons/bankIconBlue.png'
import agencyMap from '../../assests/icons/agencyMap.png'
import './agencyBank.css'
import BreakDown from './AgencyComponentsHold/BreakDown'
import ListOfAgents from './AgencyComponentsHold/ListOfAgents'
import { AgentsPreview} from './MakeUpData/AgentsLoc/AgentsLocPreview';
import { PerformingAgents } from './MakeUpData/PerformingAgents/PerformingAgents';
import { AgentsList } from './MakeUpData/ListOfAgents/AgentsListPreview';
import EllipseIcon from '../../assests/icons/EllipseDot.png'
import PosIcon from '../../assests/icons/POSAgentsIcon.png'
import arrowLeft from '../../assests/icons/arrowLeftNew.png'
import RegionMain from './AgencyComponentsHold/PerformingRegionMain'
import RegionBreakDown from './AgencyComponentsHold/RegionBreakDown'


function AgencyBanking() {
    const navRef = useRef(null)
    const FilterList = ['By State','By City']
    const labelBreakdown =  ['NC:North-Central','NW:North-West','NE:North-East','SS:South-South','SW:South-West','SE:South-East']
    const listAgentsHeader = ['Agent ID','Agent Name','Business Name','Location','Eligible Amount','Action']
    const [viewPort,setViewPort] = useState('breakDown')
    const data = {
      labels: ['NC', 'NW', 'NE', 'SS', 'SW', 'SE'],
      datasets: [
        {
          label: 'Top Performing Regions',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: '#2A38A4',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          barThickness: 20
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        
      },
    };


  return (
    <div style={{backgroundColor:'transparent',height:'100%'}}>
        <section ref={navRef} className='loanHead'>
            {(viewPort!=='breakDown') && <section onClick={()=>setViewPort('breakDown')} className='loanContent'>
                <span><img src={arrowLeft}/></span>
                <span style={{marginLeft:'10px'}}>Back to Applications</span>
            </section>}
            <section className='loanContent'>
                <span><img src={bankIcon}/></span>
                <p>Agency Banking</p>
            </section>
        </section>
        {viewPort==='breakDown' && <section className='summaryHoldAgency'>
            <div className='ATopRc'>
                Filter by: &nbsp; 
                <select  >
                          <option value="">Select filter</option>
                          {FilterList.map((element, i) => {
                            return <option value={element} key={i}>{element}</option>
                          })}
                </select>
            </div>

            <span style={{position:'absolute',top:'40%',left:"50%"}}>
              <img src={EllipseIcon}/>
            </span>

            <span style={{position:'absolute',top:'60%',left:"50%"}}>
              <img src={PosIcon}/>
            </span>
            <div className='AbottomLc'>
                Lagost State-Nigeria
            </div>
            <img src={agencyMap} style={{objectFit:"cover"}}/>  
        </section>}

        <div>
          {viewPort==='breakDown'?
          <BreakDown AgentsPreview={AgentsPreview}
                     PerformingAgents={PerformingAgents} 
                     labelBreakdown={labelBreakdown}
                     AgentsList={AgentsList}
                     listAgentsHeader={listAgentsHeader}
                     data={data}
                     options={options}
                     setViewPort={setViewPort}
                     />:
          viewPort==='listOfAgents'?
          <ListOfAgents/>:
          viewPort==='performingRegions'?
          <RegionMain setViewPort={setViewPort}/>:
          viewPort==='regionFinalBreak'?
          <RegionBreakDown/>
          :''}
        </div>
    </div>
  )
}

export default AgencyBanking