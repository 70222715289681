import React from 'react'
import Utils from '../../../../../../library/Utils'
import LenderSectorSerializer from '../Serializer/LenderSectorSerializer'
import AccountSerializationSerializer from '../Serializer/AccountSerializationSerializer'
import AccountInformationSerializer from '../Serializer/AccountInformationSerializer'

function KenyaCrbComp({data,configuration}) {


  const personalKeys = Object.keys(data.data)
  const keyList = []
  const personalDet = data.data

  for (let i=0; i<personalKeys.length;i++){
      if(typeof data.data[personalKeys[i]] !== 'object' &&
         typeof data.data[personalKeys[i]] !== 'boolean'
      ){
        keyList.push(personalKeys[i])
      }
  }

  let content = 
        <>
        
            <section>
            
                <section className='reportOverallContent'>
                    <section className='headerItem'>
                        Personal Details
                    </section>
                    <div className='report-contentBureauKen'>
                        <div className='personalDetKen'>
                            {keyList.map(key=>
                            <div className='keyValue-holder' key={keyList.indexOf(key)}>
                                <p className='key'>{key}</p>
                                <p className='value'>
                                    {!Utils.isFieldEmpty(personalDet[key])?
                                        personalDet[key]
                                        :'--'}
                                </p>
                            </div>)}
                        </div>
                    </div>
                    <LenderSectorSerializer data={data.data.lender_sector}/>
                    <AccountSerializationSerializer data={data.data}/>
                </section>

                <section className='reportOverallContent'>
                    <section className='headerItem'>
                        Account Information
                    </section>
                    <AccountInformationSerializer configuration={configuration} data={data.data.account_info}/>
                </section>
            </section>

            
        </>
  return (
    <div>
        {content}
        
    </div>
  )
}

export default KenyaCrbComp