import React, { useEffect, useState } from 'react'
import statementIcon from '../../../../assests/icons/total_statement.png'
import transactionIcon from '../../../../assests/icons/total_transaction.svg'
import ClosingBalanceIcon from '../../../../assests/icons/closing_balance.svg'
import BarChart from './BarChart/BarChart'
import Utils from '../../../../library/Utils'
import { useSelector } from 'react-redux'
import { selectStatementDetailsState } from '../../../../redux/Slices/ConsolidatedStatement'

function OverViewNg(props) {
 

    const statement = props.statement


    const loansHead = ['Account','No of Loan Transaction','Total Loan Amount','Latest Loan Amount','Latest Loan Date']
    const configuration = props.configuration;
   


    const [userBarData,setUserBarData] = useState({labels: statement?.activeStatement?.overview?.totalInflowOverview?.totalSummary.map(bar=>bar.bank),
        datasets: [{
            label:'Amount',
            data:statement?.activeStatement?.overview?.totalInflowOverview?.totalSummary.map(bar=>bar.amount),
            backgroundColor:'rgba(51, 84, 244, 1)',
            borderRadius:'4px'
    }]})

    const [userBarOut,setUserBarOut] = useState({
        labels: statement?.activeStatement?.overview?.totalOutflowOverview?.totalSummary.map(bar=>bar.bank),
datasets: [{
    label:'Amount',
    data:statement?.activeStatement?.overview?.totalOutflowOverview?.totalSummary.map(bar=>bar.amount),
    backgroundColor:'rgba(51, 84, 244, 1)',
    borderRadius:'4px'
}]
    })


    useEffect(()=>{
        if(statement){
            setUserBarData({labels: statement?.activeStatement?.overview?.totalInflowOverview?.totalSummary.map(bar=>bar.bank),
                datasets: [{
                    label:'Amount',
                    data:statement?.activeStatement?.overview?.totalInflowOverview?.totalSummary.map(bar=>bar.amount),
                    backgroundColor:'rgba(51, 84, 244, 1)',
                    borderRadius:'4px'
            }]})

            setUserBarOut({
                labels: statement?.activeStatement?.overview?.totalOutflowOverview?.totalSummary.map(bar=>bar.bank),
        datasets: [{
            label:'Amount',
            data:statement?.activeStatement?.overview?.totalOutflowOverview?.totalSummary.map(bar=>bar.amount),
            backgroundColor:'rgba(51, 84, 244, 1)',
            borderRadius:'4px'
    }]
            })
        }
    },[statement])
const processedStatements = (data)=>{
    const holderProcessed = []
    const holderFailed = []
    data?.filter(value=>{

        if(value.status==='PROCESSED'){
            holderProcessed.push(value)
        }

        if(value.status==='FAILED'){
            holderFailed.push(value)
        }

    })

    return [holderProcessed.length,holderFailed.length]
}

/**
 * const [processedState,setProcessedStates] = useState()
useEffect(()=>{
    if(statement){
        console.log(statement)
        setProcessedStates(processedStatements(statement.activeStatement.accounts.statementSummaries))
    }
},[statement])
 */



  return (
    <div style={{width:'100%',height:'auto',margin:'30px auto'}}>
        <section style={{display:'flex',flexWrap:'wrap',columnGap:'15px',rowGap:'10px',height:'auto'}}>
            <div style={{flex:'1',minWidth:'250px',backgroundColor:"white",border:'1px solid rgba(220, 229, 241, 1)',borderRadius:"8px",boxSizing:"border-box"}}>
                <div style={{display:"flex",borderRadius:"8px 8px 0px 0px",height:'auto',alignItems:"center",backgroundColor:'rgba(0, 74, 186, 1)',justifyContent:"space-around"}}>
                    <div style={{display:"flex",justifyContent:"space-around"}}>
                        <span><img src={statementIcon}/></span>
                        <span style={{color:"white",marginLeft:'7px'}}>Total Number of Statements</span>
                    </div>
                    <span style={{fontSize:'40px',lineHeight:'52px',color:'rgba(255, 255, 255, 1)'}}>{statement?.activeStatement?.accounts?.statementSummaries.length}</span>
                </div>
                <div style={{height:'100px',borderRadius:"0px 0px 8px 8px",display:"flex",flexDirection:"column"}}>
                    <section style={{flex:'1',padding:'10px',boxSizing:"border-box",display:"flex",justifyContent:"space-between"}}>
                        <p style={{color:"rgba(12, 188, 139, 1)"}}>Total Processed</p>
                        <p style={{color:"rgba(12, 188, 139, 1)"}}>{processedStatements(statement?.activeStatement?.accounts?.statementSummaries)[0]}</p>
                    </section>
                    <section style={{flex:'1',padding:'10px',boxSizing:"border-box",display:"flex",justifyContent:"space-between"}}>
                        <p style={{color:"rgba(255, 114, 94, 1)"}}>Total Failed</p>
                        <p style={{color:"rgba(255, 114, 94, 1)"}}>{processedStatements(statement?.activeStatement?.accounts?.statementSummaries)[1]}</p>
                    </section>

                </div>
            </div>
            <div style={{flex:'1',minWidth:'250px',height:'150px',padding:'25px',display:"flex",flexDirection:"column",justifyContent:"space-around",alignItems:"left",boxSizing:"border-box",backgroundColor:"white",border:'1px solid rgba(220, 229, 241, 1)',borderRadius:"8px",boxSizing:"border-box",padding:'15px'}}>
                <section style={{display:"flex",justifyContent:"left",alignItems:"center"}}>
                    <span><img src={ClosingBalanceIcon}/></span>
                    <span style={{marginLeft:'7px'}}>Total Closing Balance</span>
                </section> 
                <section style={{fontSize:'40px',lineHeight:"52px",fontWeight:"700",color:'rgba(19, 15, 38, 1)'}}>{Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol,statement?.activeStatement?.overview?.totalClosingBalance)}</section> 
                <section style={{fontSize:'10px',color:'rgba(116, 114, 114, 1)'}}>This is the total closing balance across all accounts</section> 
            </div>
            <div style={{flex:'1',minWidth:'250px',height:'150px',padding:'25px',display:"flex",flexDirection:"column",justifyContent:"space-around",alignItems:"left",boxSizing:"border-box",backgroundColor:"white",border:'1px solid rgba(220, 229, 241, 1)',borderRadius:"8px",boxSizing:"border-box",padding:'15px'}}>
                <section style={{display:"flex",justifyContent:"left",alignItems:"center"}}>
                    <span><img src={transactionIcon}/></span>
                    <span style={{marginLeft:'7px'}}>Total Transactions</span>
                </section> 
                <section style={{fontSize:'40px',lineHeight:"52px",fontWeight:"700",color:'rgba(19, 15, 38, 1)'}}>{statement?.activeStatement?.overview?.totalTransactions}</section> 
                <section style={{fontSize:'10px',color:'rgba(116, 114, 114, 1)'}}>This is the total number of transactions across all accounts</section> 
            </div>
        </section>


        <section style={{display:'flex',flexWrap:'wrap',columnGap:'15px',marginTop:"30px"}}>
            <div style={{flex:'1',minWidth:"400px",backgroundColor:"white",border:'1px solid rgba(220, 229, 241, 1)',borderRadius:"8px",boxSizing:"border-box"}}>
                <section style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"15px",border:'1px solid rgba(217, 229, 255, 1)',borderWidth:"0px 0px 1px 0px"}}>
                    <div style={{display:'flex',flexDirection:"column",alignItems:"left"}}>
                            <span style={{fontSize:'14px',color:'rgba(46, 44, 44, 1)'}}>Total Inflow</span>
                            <span style={{fontSize:"32px",fontWeight:"700",lineHeight:"42px",color:'rgba(19, 15, 38, 1)'}}>{Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, statement?.activeStatement?.overview?.totalInflowOverview?.totalAmount)}</span>
                            <span style={{fontSize:"14px",color:'rgba(0, 0, 0, 0.5)'}}>See break down below</span>
                    </div>

                  
                </section>
                <div style={{width:'95%',borderRadius:'7px',height:'300px',margin:"15px auto",padding:'15px',boxSizing:"border-box",boxShadow:"0 4px 8px rgba(0, 0, 0, 0.1)"}}>
                    <BarChart chartData={userBarData}/>
                </div>
            </div>

            <div style={{flex:'1',minWidth:"400px",backgroundColor:"white",border:'1px solid rgba(220, 229, 241, 1)',borderRadius:"8px",boxSizing:"border-box"}}>
            <section style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"15px",border:'1px solid rgba(217, 229, 255, 1)',borderWidth:"0px 0px 1px 0px"}}>
                    <div style={{display:'flex',flexDirection:"column",alignItems:"left"}}>
                            <span style={{fontSize:'14px',color:'rgba(46, 44, 44, 1)'}}>Total Outflow</span>
                            <span style={{fontSize:"32px",fontWeight:"700",lineHeight:"42px",color:'rgba(19, 15, 38, 1)'}}>{Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, statement?.activeStatement?.overview?.totalOutflowOverview?.totalAmount)}</span>
                            <span style={{fontSize:"14px",color:'rgba(0, 0, 0, 0.5)'}}>See break down below</span>
                    </div>

                </section>
                <div style={{width:'95%',borderRadius:'7px',height:'300px',margin:"15px auto",padding:'15px',boxSizing:"border-box",boxShadow:"0 4px 8px rgba(0, 0, 0, 0.1)"}}>
                    <BarChart chartData={userBarOut}/>
                </div>
            </div>
            
        </section>



        <section style={{display:'flex',flexWrap:"wrap",rowGap:'10px',columnGap:'15px',marginTop:"30px"}}>
            <div style={{flex:'1',minWidth:'300px',backgroundColor:"white",height:"fit-content",border:'1px solid rgba(220, 229, 241, 1)',borderRadius:"8px",boxSizing:"border-box"}}>
                <section style={{display:"flex",justifyContent:"left",alignItems:"center",padding:"15px",border:'1px solid rgba(217, 229, 255, 1)',borderWidth:"0px 0px 1px 0px"}}>
                    <span><img src={ClosingBalanceIcon}/></span>
                    <span style={{marginLeft:'7px',fontSize:"16px",color:'rgba(46, 44, 44, 1)',fontWeight:'500'}}>Account Activity</span>
                </section>
                <div style={{height:'auto',borderRadius:"0px 0px 8px 8px",display:"flex",flexDirection:"column"}}>

                {statement?.activeStatement?.overview?.accountActivity.map((transact,index)=>
                    <section key={index} style={{flex:'1',padding:'10px',boxSizing:"border-box",display:"flex",justifyContent:"space-between"}}>
                        <p style={{color:"rgba(54, 60, 79, 1)",fontSize:"14px"}}>{transact.bankName}</p>
                        <p style={{color:"rgba(19, 15, 38, 1)",fontSize:'14px',fontWeight:"700"}}>{transact.activity*100}</p>
                    </section>)}

                </div>
            </div>

            <div style={{flex:'1',minWidth:'300px',backgroundColor:"white",height:'fit-content',border:'1px solid rgba(220, 229, 241, 1)',borderRadius:"8px",boxSizing:"border-box"}}>
                <section style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"15px",border:'1px solid rgba(217, 229, 255, 1)',borderWidth:"0px 0px 1px 0px"}}>
                    <div style={{display:'flex',flexDirection:"column",alignItems:"left"}}>
                        <section style={{display:'flex',justifyContent:"center",alignItems:"center"}}>
                            <span><img src={ClosingBalanceIcon}/></span>
                            <span style={{marginLeft:'7px',fontSize:"16px",color:'rgba(46, 44, 44, 1)',fontWeight:'500'}}>Number of Salary Accounts</span>
                        </section>
                    </div>

                    <p style={{fontSize:"24px",fontWeight:"700",lineHeight:"30px",color:'rgba(19, 15, 38, 1)'}}>
                        {statement?.activeStatement?.overview?.salaryAccountOverview?.numberOfSalaryAccounts}
                    </p>
                </section>
                <div style={{height:'auto',width:'95%',margin:'7px auto',borderRadius:"0px 0px 8px 8px",display:"flex",flexDirection:"column"}}>
                    <section style={{display:"flex",columnGap:"10px"}}>
                        <p style={{flex:'1',textAlign:"left",fontSize:"12px",color:'rgba(102, 115, 127, 1)'}}>Account</p>
                        <p style={{flex:'1',textAlign:"left",fontSize:"12px",color:'rgba(102, 115, 127, 1)'}}>Bank</p>
                        <p style={{flex:'1',textAlign:"left",fontSize:"12px",color:'rgba(102, 115, 127, 1)'}}>Average Predicted Salary</p>
                    </section>
                {statement?.activeStatement?.overview?.salaryAccountOverview?.salaryAccountSummary.map((salary,index)=>
                    <section key={index} style={{flex:'1',columnGap:'10px',margin:"5px 0px",boxSizing:"border-box",display:"flex",justifyContent:"space-between"}}>
                        <p style={{flex:'1',textAlign:"left"}}>{`Salaray Account ${statement?.activeStatement?.overview?.salaryAccountOverview?.salaryAccountSummary.indexOf(salary)+1}`}</p>
                        <p style={{flex:'1',textAlign:"left",color:"rgba(19, 15, 38, 1)"}}>{salary.bank}</p>
                        <p style={{flex:'1',textAlign:"left",color:"rgba(19, 15, 38, 1)",fontSize:'14px',fontWeight:"700"}}>{Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, salary.averagePredictedSalary)}</p>
                    </section>)}

                </div>
            </div>
            
        </section>


        <section style={{flex:'1',marginTop:'30px',height:'fit-content',borderRadius:"8px",boxSizing:"border-box"}}>
                <p style={{fontSize:"14px",fontWeight:'700',color:'rgba(0, 0, 0, 1)'}}>Loans</p>
                <div style={{display:"flex",margin:'10px 0px'}}>
                    {loansHead.map((head,index)=>
                    <span style={{color:"rgba(102, 115, 127, 1)",fontSize:"16px",flex:loansHead.indexOf(head)===0?'1.5':'1',textAlign:"left",paddingLeft:'7px',boxSizing:"border-box"}} key={index}>{head}</span>)}
                </div>
               
               <div>
                    {statement?.activeStatement?.overview?.loanOverview.map((loan,index)=>
                    <div key={index} style={{display:"flex",backgroundColor:"rgba(255, 255, 255, 1)",padding:'15px 0px',border:"1px solid rgba(217, 229, 255, 1)"}}>
                        <section style={{display:"flex",flex:'1.5',alignItems:"center",justifyContent:"start",paddingLeft:'7px',boxSizing:"border-box"}}>
                          {loan.account}
                        </section>
                        <span style={{flex:'1',color:'rgba(19, 15, 38, 1)',fontSize:'16px',fontWeight:'500',display:"flex",alignItems:"center",justifyContent:'start',paddingLeft:'7px'}}>
                            {loan.noOfLoanTransaction}
                        </span>
                        <span style={{flex:'1',color:'rgba(19, 15, 38, 1)',fontSize:'16px',fontWeight:'500',display:"flex",alignItems:"center",justifyContent:'start',paddingLeft:'7px'}}>
                            {Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, loan.totalLoanAmount)}
                        </span>
                        <span style={{flex:'1',color:'rgba(19, 15, 38, 1)',fontSize:'16px',fontWeight:'500',display:"flex",alignItems:"center",justifyContent:'start',paddingLeft:'7px'}}>
                            {Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, loan.latestLoanAmount)}
                        </span>
                        <span style={{flex:'1',color:'rgba(19, 15, 38, 1)',fontSize:'16px',fontWeight:'500',display:"flex",alignItems:"center",justifyContent:'start',paddingLeft:'7px'}}>
                            {loan.latestLoanDate?loan.latestLoanDate:'-'}
                        </span>
                    </div>)}
               </div>
            </section>

        <section style={{marginTop:"30px"}}>
            <p style={{fontSize:"14px",fontWeight:'700',color:'rgba(0, 0, 0, 1)'}}>Self Transfers</p>
            <div style={{display:'flex',flexWrap:'wrap',columnGap:'15px',marginTop:"10px",height:'fit-content'}}>
                {
                    statement?.activeStatement?.overview?.selfTransferOverview.map((transfer,index)=>
                        <div key={index} style={{flex:1,minWidth:'350px',backgroundColor:"white",height:'fit-content',marginTop:'20px',border:'1px solid rgba(220, 229, 241, 1)',borderRadius:"8px",boxSizing:"border-box"}}>
                    <section style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"15px"}}>
                                <span style={{marginLeft:'7px',fontSize:"16px",color:'rgba(46, 44, 44, 1)',fontWeight:'500'}}>{transfer.bankName}</span>
                    
                    </section>
                    <div style={{height:'auto',width:'95%',maximumHeight:'300px',height:'300px',overflow:'auto',margin:'7px auto',borderRadius:"0px 0px 8px 8px",display:"flex",flexDirection:"column"}}>
                        <section style={{display:"flex",columnGap:"10px"}}>
                            <p style={{flex:'1',textAlign:"left",fontSize:"12px",color:'rgba(0, 0, 0, 1)'}}>Date</p>
                            <p style={{flex:'1',textAlign:"left",fontSize:"12px",color:'rgba(0, 0, 0, 1)'}}>Amount</p>
                            <p style={{flex:'1',textAlign:"left",fontSize:"12px",color:'rgba(0, 0, 0, 1)'}}>Transaction Details</p>
                        </section>
                    {transfer.selfTransferSummary.map((transaction,index)=>
                        <section key={index} style={{flex:'1',columnGap:'10px',margin:"5px 0px",boxSizing:"border-box",display:"flex",justifyContent:"space-between"}}>
                            <p style={{flex:'1',textAlign:"left",fontWeight:"700"}}>{Utils.formatDate(transaction.date)}</p>
                            <p style={{flex:'1',textAlign:"left",color:"rgba(19, 15, 38, 1)"}}> {Utils.formatCurrency(configuration.currency.format, configuration.currency.symbol, transaction.amount)}</p>
                            <p style={{flex:'1',textAlign:"left",color:"rgba(19, 15, 38, 1)",fontSize:'14px'}}>{transaction.details}</p>
                        </section>)}

                    </div>
                </div>)
                }
                

                
            </div>
            
        </section>
    </div>
  )
}

export default OverViewNg