import React from 'react'
import './SelectOptions.css'
import { useSelector } from 'react-redux'
import { selfCustomParameters } from '../../../../../../redux/Slices/ParameterStateHolder'

function SelectOptionsSuccessful() {
    const data = useSelector(selfCustomParameters)
  return (
    <section style={{margin:'30px 0px',padding:'20px 0px'}}>
            <p style={{color:"rgba(85, 87, 90, 1)",fontSize:"18px",textAlign:"center"}}>Defined Options</p>
            <section style={{minHeight:"350px",padding:"30px 25px",boxSizing:"border-box",display:'flex',flexDirection:"column",rowGap:'20px',justifyContent:"space-between",borderRadius:"5px",backgroundColor:"white",marginTop:"20px",boxShadow:" 0 3px 6px rgba(0, 0, 0, .1)"}}>
                <p style={{textAlign:"center",fontSize:"35px",fontWeight:"700"}}>Successful!!!</p>
                <div style={{display:'flex',flexDirection:'column',alignItems:"center"}}> 
                    <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Options Added</span>
                    <div style={{maxHeight:'200px',overflowY:'auto',width:'90%',display:"flex"
                                ,rowGap:'15px',padding:'15px',alignItems:"center",
                                flexDirection:"column"}}>
                                    {data.parameters.map((feature,index)=>
                                        <span key={index} style={{fontWeight:"700"}}>
                                        {feature.value}    
                
                                        </span>
                                    )}
                        
                    </div>

                    
                               
                </div> 
            </section>
            

        </section>
  )
}

export default SelectOptionsSuccessful