import React, {useCallback, useState, useMemo} from "react";
import { 
  useDispatch
} from 'react-redux';
import '../../../../components/Modals/Modal.css';
import { PostDataStatus } from '../../../../library/Variables';
import Spinner from '../../../../svg/Spinner/Spinner';
import {useDropzone} from "react-dropzone";
import './CreditConfirmBox.css'

function CreditConfirmBox(props) {
  let component = null;
  const [filesSelected, setFilesSelected] = useState([]);
  const [filesRejected, setFilesRejected] = useState([]);

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 3,
    backgroundColor: "#F0F6FF",
    borderStyle: "dashed",
    outline: "none",
    transition: "border .24s ease-in-out"
  };
  
  const activeStyle = {
    borderColor: "#F7F7F7"
  };
  
  const acceptStyle = {
    borderColor: "#F7F7F7"
  };
  
  const rejectStyle = {
    borderColor: "#F7F7F7"
  };

  
  const onDropRejected = useCallback(fileRejections => {
    setFilesRejected([...filesRejected, ...fileRejections]);
    setFilesSelected([]);
  }, [filesRejected])

  const onDrop = useCallback(acceptedFiles => {
    setFilesSelected([...filesSelected, ...acceptedFiles]);
    setFilesRejected([]);
    props.setConfirmStatus('POSTING')
  }, [filesSelected])

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: { 'application/pdf': ['.pdf'] },
    maxSize: 10000000,
    filesizeBase: 1000,
    multiple: false,
    onDrop,
    onDropRejected
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  function submitConfirmType() {
    props.parentCallback();
  }

  if(props.typeStatus === PostDataStatus.INITIAL) {
    component = <div className='modal-dialog add-csv'>
      <div className='modal-body-container'>
        <div {...getRootProps({style})} className="drop-container">
            <input {...getInputProps()} />
            <img src="/assets/icons/download-color.svg" alt="" />
            <p className="drop-caption" onClick={open} >Select file here<span className="detail-header" ></span>
            </p>
            <p className="drop-subcaption text-medium">File Supported: .csv</p>
        </div>       
      </div>   
    </div>
  } else if(props.typeStatus === PostDataStatus.FETCHING) {
    component = <div className='modal-dialog credit-score'>
      <div className='modal-header header-single'>
        <Spinner />
      </div>
      <div className='modal-body-container'>
        <h2 className="detail-header">{props.fetchingTitle}</h2>
        <div>
            <p>This action will incur a charge to your account</p>
            <p>Do you wish to proceed?</p>
        </div>

        <div className='submit-container'>
          <button className="button-square button-outline">Cancel</button>
          <button className="button-square button-solid" onClick={() => props.setConfirmStatus('SUCCESS')}>Proceed</button>
        </div>
      </div>   
    </div>
  } else if (props.typeStatus === PostDataStatus.SUCCESS) {
    component = <div className='modal-dialog credit-score'>
      <div className="modal-header header-single">
          <img src="/assets/icons/success-tick.svg" alt="success" /> 
      </div>
      <div className='modal-body-container'>
        <div>
            <h2>{props.successMessage}</h2>
            <p>Click Done to see</p>       
        </div>

        <div className='submit-container'>
          <button className="button-square button-solid done-btn" onClick={() => props.setPageActive('history-page')} >Done</button>
        </div>
      </div> 
    </div>
  } else if(props.typeStatus === PostDataStatus.FAILURE) {
    component = <div className='modal-dialog credit-score'>
      <div className="modal-header header-single">
          <img src="/assets/icons/failed-tick.svg" alt="failed" /> 
      </div>

      <div className='modal-body-container'>
        <div>
            <h2>{props.failTitle}</h2>
            <p>The application has encountered an unknown error. Please, refresh and try again.</p>       
        </div>

        <div className='submit-container'>
          <button className="button-square button-solid done-btn">Try again</button>
        </div>
      </div>   
    </div>
  }

  return (
    component
  )
}

export default CreditConfirmBox;