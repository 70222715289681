import { combineReducers, configureStore } from "@reduxjs/toolkit";
import addPdfReducer from "./Slices/AddPdf";
import affordabilityReducer from "./Slices/Affordability";
import applicationReducer from "./Slices/Application";
import authReducer from "./Slices/Auth";
import bureauReducer from "./Slices/Bureau";
import configurationReducer from "./Slices/Configuration";
import consolidatedAffordabilityReducer from "./Slices/ConsolidatedAffordability";
import changePassWordReducer from "./Slices/PasswordCollection/ChangePassword/ChangePasswordClient"
import VOneCreditScoreReducer from "./Slices/ConsolidatedCreditScore";
import ConsolidatedCreditScoreReducer from "./Slices/ConsolidatedCustomCreditScore";
import consolidatedStatementDetailsReducer from "./Slices/ConsolidatedStatement";
import migrationReducer from "./Slices/CreateKeyCloackUser";
import crbKenyaDataReducer from "./Slices/CRB/Kenya/CrbKenya"
import clientAuditReducer from "./Slices/Settings/userManagement/ClientAudit"
import crbGhanaDataReducer from "./Slices/CRB/Ghana/CrbGhana"
import creditScoreReducer from "./Slices/CreditScore";
import customCreditScoreReducer from "./Slices/CustomCreditScore";
import customDecisionCreditScoreReducer from "./Slices/CustomDecisionCustomCreditScore";
import downloadPdfReducer from "./Slices/DownloadPdf";
import draftEditReducer from "./Slices/DraftAndEditLoan";
import editSectionsReducer from "./Slices/EditLoanSection";
import kenyaAmlExtractionReducer from "./Slices/IdVerification/Kenya/IdVerificationKenyaAml";
import kenyaIdReducer from "./Slices/IdVerificationKenya";
import kenyaKybReducer from "./Slices/IdVerificationKenyaKyb";
import kybReducer from "./Slices/KybOptions";
import kycReducer from "./Slices/KycOptions";
import loanReducer from "./Slices/Loan";
import loanFileReducer from "./Slices/LoanFile";
import loanReportReducer from "./Slices/LoanReportDownload";
import loanScoreReducer from "./Slices/LoanScores";
import signatureReducer from "./Slices/LoanSignatureUpload";
import AuditTrailReducer from "./Slices/Loans/AuditTrail/AuditTrail";
import LoanManagementReducer from "./Slices/Loans/LoanManagement/LoanManagement";
import mainReducer from "./Slices/Main";
import mobileInsightReducer from "./Slices/MobileInsight";
import parameterSliceReducer from "./Slices/ParameterStateHolder";
import SelfServiceDecisionReducer from "./Slices/SelfServiceDecision";
import statementDetailsReducer from "./Slices/StatementDetails";
import KenyaKycStatementDataReducer from "./Slices/StatementKyc/Kenya/KenyaStatementKyc";
import resetPassWordReducer from './Slices/PasswordCollection/ResetPassword/ResetPasswordClient';
import subscriptionReducer from "./Slices/Subscription";
import supportingDocsReducer from "./Slices/SupportDocs";
import transactionsReducer from "./Slices/Transactions";
import userReducer from "./Slices/User";
import userManagementReducer from "./Slices/UserManagement";
import walletReducer from "./Slices/Wallet";
import webHookReducer from "./Slices/webHook/WebHookClient"
import { parameterActionSlice } from "./Slices/fraudDetect/actionSlice";
import { fraudUserAddressSlice } from "./Slices/fraudDetect/addressSlice";
import { createTransactionCommentSlice } from "./Slices/fraudDetect/commentSlice";
import { fraudMatchCriteriaSlice } from "./Slices/fraudDetect/matchCreteriaSlice";
import { parameterActionValueSlice } from "./Slices/fraudDetect/parameterActionValueSlice";
import { parameterSlice } from "./Slices/fraudDetect/parametersSlice";
import {
  createRulesSlice,
  deleteRulesSlice,
  rulesSlice,
  updateRulesSlice,
  viewRuleSlice,
} from "./Slices/fraudDetect/ruleSlice";
import { updateReviewSlice } from "./Slices/fraudDetect/reviewSlice/updateReview";
import { uploadTransactionAsync } from "./Slices/fraudDetect/transactionUploadSlice/thunks";
import {
  createCustomRulesSlice,
  customRuleSlice,
  customRulesSlice,
  deleteCustomRuleSlice,
} from "./Slices/fraudDetect/ruleSlice/customRule";
import { addSupportingDocSlice } from "./Slices/fraudDetect/supportDocumentSlice";
import {
  flaggedFraudTransactionSlice,
  fraudTransactionByIdSlice,
  fraudTransactionDetailSlice,
  fraudTransactionStatisticSlice,
  pendingFraudTransactionSlice,
  reviewedFraudTransactionSlice,
} from "./Slices/fraudDetect/transactionSlice";
import { fraudUploadTransactionSlice } from "./Slices/fraudDetect/transactionUploadSlice/uploadTransaction";
import { userTransactionProfileSlice } from "./Slices/fraudDetect/transactionSlice/transactionProfile";
import amlHistoryExtractionReducer from "./Slices/IdVerification/Kenya/AmlHistory"
import multiAccountSliceReducer from "./Slices/Multinenant/MultiTenancyFile"

const combinedReducer = combineReducers({
  auth: authReducer,
  main: mainReducer,
  transactions: transactionsReducer,
  wallet: walletReducer,
  statementDetails: statementDetailsReducer,
  consolidatedStatementDetails: consolidatedStatementDetailsReducer,
  user: userReducer,
  subscription: subscriptionReducer,
  addpdf: addPdfReducer,
  affordability: affordabilityReducer,
  consolidatedAffordability: consolidatedAffordabilityReducer,
  kycEntireData: kycReducer,
  kybEntireData: kybReducer,
  clientAudit:clientAuditReducer,
  configuration: configurationReducer,
  loan: loanReducer,
  creditScore: creditScoreReducer,
  customCreditScore: customCreditScoreReducer,
  customDecisionCreditScore: customDecisionCreditScoreReducer,
  mobileInsight: mobileInsightReducer,
  bureau: bureauReducer,
  downloadPdf: downloadPdfReducer,
  application: applicationReducer,
  userManagement: userManagementReducer,
  loanFile: loanFileReducer,
  loanScore: loanScoreReducer,
  loanSignature: signatureReducer,
  migrant: migrationReducer,
  loanReportPdf: downloadPdfReducer,
  ConsolidatedCreditScore: ConsolidatedCreditScoreReducer,
  crbGhanaData:crbGhanaDataReducer,
  VOneCreditScore: VOneCreditScoreReducer,
  selfServiceCreditScore: SelfServiceDecisionReducer,
  customParameters: parameterSliceReducer,
  kenyaIdVerification: kenyaIdReducer,
  kenyaKybVerification: kenyaKybReducer,
  loanReportingPdf: loanReportReducer,
  supportingDocs: supportingDocsReducer,
  draftEdit: draftEditReducer,
  editSections: editSectionsReducer,
  kenyaAmlExtraction: kenyaAmlExtractionReducer,
  loanManagement: LoanManagementReducer,
  auditTrail: AuditTrailReducer,
  webHook:webHookReducer,
  resetPassWord:resetPassWordReducer,
  parameters: parameterSlice.reducer,
  parameterActions: parameterActionSlice.reducer,
  parameterActionValues: parameterActionValueSlice.reducer,
  rules: rulesSlice.reducer,
  flaggedFraudTransactions: flaggedFraudTransactionSlice.reducer,
  createRule: createRulesSlice.reducer,
  rule: viewRuleSlice.reducer,
  fraudTransactionsStatistic: fraudTransactionStatisticSlice.reducer,
  matchCriteria: fraudMatchCriteriaSlice.reducer,
  createCustomRule: createCustomRulesSlice.reducer,
  customRules: customRulesSlice.reducer,
  pendingFraudTransactions: pendingFraudTransactionSlice.reducer,
  reviewFraudTransactions: reviewedFraudTransactionSlice.reducer,
  userTransactionProfile: userTransactionProfileSlice.reducer,
  fraudTransactionDetails: fraudTransactionDetailSlice.reducer,
  userAddress: fraudUserAddressSlice.reducer,
  fraudTransactionsById: fraudTransactionByIdSlice.reducer,
  addSupportingDocs: addSupportingDocSlice.reducer,
  createTransactionComment: createTransactionCommentSlice.reducer,
  updateRule: updateRulesSlice.reducer,
  customRule: customRuleSlice.reducer,
  deleteRule: deleteRulesSlice.reducer,
  deleteCustomRule: deleteCustomRuleSlice.reducer,
  updateReview: updateReviewSlice.reducer,
  fraudUploadTransactionSlice: fraudUploadTransactionSlice.reducer,
  kenyaKycStatementData: KenyaKycStatementDataReducer,
  amlHistoryExtraction:amlHistoryExtractionReducer,
  changePassWord:changePassWordReducer,
  crbKenyaData:crbKenyaDataReducer,
  multiAccount:multiAccountSliceReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/logout" || action.type === "app/resetApp") {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
});
