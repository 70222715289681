import React, {useState, useEffect, useMemo} from 'react'
import './DecisionEngine.css'
import Utils from '../../library/Utils';
import { 
    useSelector,
    useDispatch
} from 'react-redux';
import { FetchDataStatus, CustomCreditScoreViews, PostDataStatus } from "../../library/Variables";
import Spinner from "../../svg/Spinner/Spinner";
import { 
  getAllCustomDecisionCreditScore,
  getCustomDecisionScoreCardDefinitions,
  resetAllCustomDecisionCreditScore,
  resetCustomDecisionCreditScore,
  selectCustomDecisionCreditScoreState,
  updateCustomDecisionCreditScoreAsync,
  getAllCustomDecisionEngineScores,
} from "../../redux/Slices/CustomDecisionCustomCreditScore";

  
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "../../components/Button/Button";
import { useNavigate, useParams  } from "react-router-dom";
import ProceedPage from './ProceedPage/ProceedPage';
import FormPage from './FormPage/FormPage';
import HistoryPage from './HistoryPage/HistoryPage';
import { ConfigureInitialView } from "./ViewScorePage/ViewScore";
import ViewScore from './ViewScorePage/ViewScore';

function DecisionEngine({configuration, accessToken, user}) {
    let content = <Spinner />;
    let initialView = null;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const customCreditScore = useSelector(selectCustomDecisionCreditScoreState);
    const [pageActive, setPageActive] =  useState(CustomCreditScoreViews.INITIAL)
    const [radioSelected, setRadioSelected] = useState(0);
    const [initialViewTitle, setInitialViewTitle] = useState(false)
    const [individualScoreBreakdowns, setIndividualScoreBreakdowns] = useState([])

    const params = {
        accessToken: accessToken,
    }

    const filterParams = { 
      accessToken: accessToken,
      startDate: '',
      endDate: '',
      name: '',
      type: ''
    };

     // Custom Generated Score List from Filter
   if((Utils.isFieldEmpty(customCreditScore)) || (customCreditScore.customDecisionStatementStatus === FetchDataStatus.INITIAL)) {
      dispatch(getAllCustomDecisionEngineScores(filterParams)); 
    
      
    }  
    
     

    const theadersCreditScoreRules = useMemo(() => [
        { 
          id: 'friendlyLeftHandSide',
          accessor: 'friendlyLeftHandSide'
        },
        { 
          id: 'actualLeftHandSide',
          accessor: 'actualLeftHandSide'
        },
        {
          id: 'friendlyOperator',
          accessor: 'friendlyOperator'
        },
        {
          id: 'friendlyRightHandSide',
          accessor: 'friendlyRightHandSide'
        },
        {
          Cell: data => {
            return (
              <span className="detail-header">
                {data.row.original.obtainedPoints}
              </span>
            );
          },
          id: 'obtainedPoints',
          accessor: 'obtainedPoints'
        }], []
      )

    // HANDLE LOAN CARD CHOSEN
    const handleRadioCallback = (type, selection) => {
        setRadioSelected(selection);

    
        if(!Utils.isFieldEmpty(customCreditScore.customDecisionCustomScoreCards[selection].customParameters)) {
          setPageActive(type);
        } 
        // else {
        //   openModal();
        // }
    }

    
    const [showData,setShowData] = useState(false)
  
    useEffect(()=>{
      if(customCreditScore.particularDecisionCreditScoresStatus=== FetchDataStatus.SUCCESS){
        setIndividualScoreBreakdowns(customCreditScore.particularDecisionCreditScore.scoresBreakdowns)
        setShowData(true)
      }
    },[customCreditScore,customCreditScore.particularDecisionCreditScoresStatus])
   




    useEffect(() => {
        if(customCreditScore.customDecisionCreditScoresStatus === FetchDataStatus.INITIAL) {
          //dispatch(getAllCustomDecisionCreditScore(params));
          
        }
    
        if(customCreditScore.customDecisionCreditScoreStatus === FetchDataStatus.INITIAL) {
          dispatch(getCustomDecisionScoreCardDefinitions(params));
        }
    

      

        if(customCreditScore.updateCustomDecisionCreditScoreStatus === PostDataStatus.SUCCESS) {
          toast.success("Score Card created successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });                     

          dispatch(resetAllCustomDecisionCreditScore());
          setRadioSelected(0);
        //   closeModal();
          setPageActive(CustomCreditScoreViews.INITIAL);
        } 
        
        if(customCreditScore.updateCustomDecisionCreditScoreStatus === PostDataStatus.SUCCESS){
          //dispatch(resetAllCustomDecisionCreditScore());
          dispatch(getAllCustomDecisionEngineScores(filterParams)); 
        }

        
        else if(customCreditScore.updateCustomDecisionCreditScoreStatus === PostDataStatus.FAILURE) {
          toast.error("Unable to create score card", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
    
          dispatch(resetCustomDecisionCreditScore());
          setPageActive(CustomCreditScoreViews.INITIAL);
        }
    }, [customCreditScore.customDecisionStatementStatus,customCreditScore.updateCustomDecisionCreditScoreStatus,customCreditScore.customDecisionCreditScoreStatus,customCreditScore.updateCustomDecisionCreditScoreStatus, dispatch])

    // GOING TO THE FORM
    const handleFormCallback = (formData) => {
        setPageActive(CustomCreditScoreViews.FETCHING);
        let skipProperty = "Customer";
    
        let formParams = {
          accessToken: accessToken,
          scorecardDefinitionName: customCreditScore.customDecisionCustomScoreCards[radioSelected].scorecardDefinitionName,
          CustomScorecardFields: [],
          CustomerId: formData.CustomerId
        };
    
        for(const property in formData) {
          if(property?.includes(skipProperty)) {
            continue
          }

          const formFields = formData["CustomScorecardFields"]
          formParams.CustomScorecardFields = [...formFields]
        /**  formParams.CustomScorecardFields.push({
            fieldname: property,
            fieldvalue: formData[property]
          }); **/
        }

        
        dispatch(resetAllCustomDecisionCreditScore())
        dispatch(updateCustomDecisionCreditScoreAsync(formParams));
        // window.location.reload();
    }

    if((!Utils.isFieldEmpty(customCreditScore.customDecisionStatements)) 
    && customCreditScore.customDecisionStatementStatus === FetchDataStatus.SUCCESS
    && customCreditScore.customDecisionCreditScoreStatus === FetchDataStatus.SUCCESS) {
    initialView = ConfigureInitialView({
      credit: customCreditScore.customDecisionCreditScores,
      individualScoreBreakdowns: individualScoreBreakdowns,
      accordion: {
        id: "custom-credit-score-accordion"
      },
      configuration: configuration, 
      tableHeaders: theadersCreditScoreRules, 
      update: () => setPageActive(CustomCreditScoreViews.SCORE_CARDS),
      isUpdatable: true,
      accessToken: accessToken,
      customCreditScore: customCreditScore,
      user: user,
      individualScoreBreakdowns,
      showData,
      view: initialViewTitle,
      updateInitialView: () => setInitialViewTitle(true),
      handleViewScore: (element) => setIndividualScoreBreakdowns(element)
    }).mainBodyContent;

    if(pageActive === CustomCreditScoreViews.INITIAL) {
      content = initialView;
    } else if(pageActive === CustomCreditScoreViews.SCORE_CARDS) {  
      content = 
      <ProceedPage 
        radioSelected={radioSelected}
        data={customCreditScore.customDecisionCustomScoreCards} 
        parentCallback={(type, selection) => handleRadioCallback(type, selection)}
      />
    } else if(pageActive === CustomCreditScoreViews.FORM) {
      content = 
      <FormPage 
        setPageActive={setPageActive}
        data={customCreditScore.customDecisionCustomScoreCards} 
        radioSelected={radioSelected} 
        parentBackCallback={() => setPageActive(CustomCreditScoreViews.SCORE_CARDS)} 
        parentCallback={(formData) => handleFormCallback(formData)}
      />
    } 
    // else {
    //   content = <Spinner />
    // }
  }



  return (
    <>
        <div className='engine-page-header'>

          {
            pageActive === CustomCreditScoreViews.INITIAL && individualScoreBreakdowns?.length === 0 ? 
            " " :
            <div className='de-btn-wrap'>
                    <Button name={"Back To List"} className="button-link back-button detail-header-dark" iconSrc="/assets/icons/arrow-left.png"
                clickFunction={() => {
                  setPageActive(CustomCreditScoreViews.INITIAL);
                  setIndividualScoreBreakdowns([])
                }} />
            </div>  
          }  

            <div className='de-subhead-wrap'>
                <img src='/assets/icons/scan.svg' alt=''/>

                <h2>Custom Decision Engine </h2>
            </div>            
        </div>

        <div className='de-body'>
            {content}            
        </div>
    </>
  )
}

export default DecisionEngine