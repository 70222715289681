import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from '../../api/Httpclient';
import { FetchDataStatus, PostDataStatus } from '../../library/Variables';


export const getSelfServiceScoreCardDefinitions = createAsyncThunk('selfService/scorecard-definitions', async (params) => {
    
  const customDecisionCreditScore = await client.get(`/creditscore/custom-decision-scorecard-definitions`, {
    "headers": {
      "Authorization": "Bearer " + params.accessToken
    }
  })

  return customDecisionCreditScore.data;
})

export const defineScoreCardName = createAsyncThunk('selfService/scoreName', async(params)=>{
    const data = JSON.stringify(params.data)
    const selfCardName = await client.post('/creditscore/scorecard-definitions',data,{
        "headers":{
            "Authorization": "Bearer " + params.accessToken,
            'Content-Type': 'application/json'
        }
    })

    return selfCardName;
})

export const getSelfServiceCustomParams = createAsyncThunk('selfService/parameters', async (params) => {
    
  const customParameters = await client.get(`/creditscore/custom-parameters`, {
    "headers": {
      "Authorization": "Bearer " + params.accessToken
    }
  })

  return customParameters.data;
})


export const defineCustomParameters = createAsyncThunk('selfService/postParameters', async(params)=>{
  const data = JSON.stringify(params.data)
  const postCustomParameters = await client.post('/creditscore/custom-parameter',data,{
      "headers":{
          "Authorization": "Bearer " + params.accessToken,
          'Content-Type': 'application/json'
      }
  })

  return postCustomParameters;
})

export const defineCustomParametersOptions = createAsyncThunk('selfService/postParameterOptions', async(params)=>{
  const data = JSON.stringify(params.data)
  const postParameterOptions = await client.post('/creditscore/custom-parameter-options',data,{
      "headers":{
          "Authorization": "Bearer " + params.accessToken,
          'Content-Type': 'application/json'
      }
  })

  return postParameterOptions;
})

export const createRules = createAsyncThunk('selfService/createRules',async(params)=>{
  const data = JSON.stringify(params.data)
  const rulesResponse = await client.post('/creditscore/scorecard-rules',data,{
    "headers":{
      "Authorization": "Bearer " + params.accessToken,
      'Content-Type': 'application/json'
    }
  })

  return rulesResponse;
})

export const getScoreCardRules = createAsyncThunk('selfService/getRules', async(params)=>{
       const scoreRules = await client.get(`/creditscore/scorecard-rules-options/${params.key}`, {
        "headers": {
          "Authorization": "Bearer " + params.accessToken
        }
      })
    
      return scoreRules.data;
})




export const SelfServiceCreditScoreSlice = createSlice({
  name: 'selfServiceCreditScore',
  initialState: {
    customDecisionCreditScores: { },
    customDecisionCreditScoresStatus: FetchDataStatus.INITIAL,
    selfServiceDefinitionList: [],
    customDecisionStatements: [],
    particularDecisionCreditScore : { },


    selfServiceDefinitionListStatus: FetchDataStatus.INITIAL,
    selfServiceCardNameStatus: PostDataStatus.INITIAL,
    selfServiceCardRepsonse:{},
    selfServiceCardNameError:false,
    selfServiceCardNameErrorMessage:null,

    selfServiceParametersStatus: FetchDataStatus.INITIAL,
    selfServiceParameterData: {},
    selfServiceParameterError:false,
    selfServiceParameterErrorMessage:null,

    customParametersCreationStatus:PostDataStatus.INITIAL,
    customParametersState:{},
    customParametersStateError:false,
    customParametersErrorMessage:null,
    isError: false,
    errorMessage: null,

    parameterOptionsStatus: PostDataStatus.INITIAL,
    parameterOptionsStatusData: {},
    parameterOptionsStatusError:false,
    parameterOptionsStatusErrorMessage:null,

    parameterRulesStatus: PostDataStatus.INITIAL,
    parameterRulesStatusData: {},
    parameterRulesStatusError:false,
    parameterRulesStatusErrorMessage:null,

    scoreCardRulesStatus : FetchDataStatus.INITIAL,
    scoreCardRuleData : [],
    scoreCardStatusError: false,
    scoreCardStatusErrorMessage:null,
  },
  reducers: {
    resetAllCustomDecisionCreditScore: state => {
      state.customDecisionCreditScores = { };
      state.particularDecisionCreditScore = { };
      state.particularDecisionCreditScoresStatus = FetchDataStatus.INITIAL
      state.customDecisionCreditScoresStatus = FetchDataStatus.INITIAL;
      state.selfServiceDefinitionList = [];
      state.selfServiceDefinitionListStatus = FetchDataStatus.INITIAL;
      state.updateCustomDecisionCreditScoreStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetCustomDecisionCreditScore: state => {
      state.selfServiceDefinitionList = [];
      state.selfServiceDefinitionListStatus = FetchDataStatus.INITIAL;
      state.updateCustomDecisionCreditScoreStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },
    resetCustomUpdateCreditScoreStatus: state => {
      state.updateCustomDecisionCreditScoreStatus = PostDataStatus.INITIAL;
      state.isError = false;
      state.errorMessage = null;
    },

    resetRules: state =>{
      state.parameterRulesStatus =  PostDataStatus.INITIAL;
      state.parameterRulesStatusData =  {};
      state.parameterRulesStatusError = false;
      state.parameterRulesStatusErrorMessage = null;
    }

    ,
    resetParameter: state =>{
      state.customParametersCreationStatus = PostDataStatus.INITIAL;
      state.customParametersState = {};
      state.customParametersStateError = false;
      state.customParametersErrorMessage = null;
    },

    resetSelfService: state =>{
      state.selfServiceDefinitionListStatus = FetchDataStatus.INITIAL;
      state.selfServiceDefinitionList = [];
      state.selfServiceCardNameStatus = PostDataStatus.INITIAL;
      state.selfServiceCardRepsonse = {};
      state.selfServiceParametersStatus = FetchDataStatus.INITIAL;
      state.selfServiceParameterData = {};
      state.selfServiceParameterError = false;
      state.selfServiceCardNameErrorMessage = null;
      state.selfServiceParameterError = null;
      state.customParametersCreationStatus = PostDataStatus.INITIAL;
      state.customParametersState = {};
      state.customParametersStateError = false;
      state.customParametersErrorMessage = null;
    

      state.parameterOptionsStatus = PostDataStatus.INITIAL;
      state.parameterOptionsStatusError = false;
      state.parameterOptionsStatusErrorMessage = null;
      state.parameterOptionsStatusData = {}

      state.parameterRulesStatus =  PostDataStatus.INITIAL;
      state.parameterRulesStatusData =  {};
      state.parameterRulesStatusError = false;
      state.parameterRulesStatusErrorMessage = null;
    }
  },
  extraReducers(builder) {
    builder.addCase(getSelfServiceScoreCardDefinitions.pending, (state) => {
      state.selfServiceDefinitionListStatus = FetchDataStatus.FETCHING;
    })
    .addCase(getSelfServiceScoreCardDefinitions.fulfilled, (state, action) => {      
      state.selfServiceDefinitionListStatus = FetchDataStatus.SUCCESS;
      state.selfServiceDefinitionList = action.payload;
    })
    .addCase(getSelfServiceScoreCardDefinitions.rejected, (state, action) => {
      state.selfServiceDefinitionListStatus = FetchDataStatus.FAILURE;
      state.isError = true;
      state.errorMessage = action.error.message;
    })
    .addCase(defineScoreCardName.pending, (state) => {
        state.selfServiceCardNameStatus = PostDataStatus.FETCHING;
      })
      .addCase(defineScoreCardName.fulfilled, (state, action) => {      
        state.selfServiceCardNameStatus = PostDataStatus.SUCCESS;
        state.selfServiceCardRepsonse = action.payload;
      })
      .addCase(defineScoreCardName.rejected, (state, action) => {
        state.selfServiceCardNameStatus = PostDataStatus.FAILURE;
        state.isError = true;
        state.errorMessage = action.error.message;
      })
      .addCase(getSelfServiceCustomParams.pending, (state) => {
        state.selfServiceParametersStatus = FetchDataStatus.FETCHING;
      })
      .addCase(getSelfServiceCustomParams.fulfilled, (state, action) => {      
        state.selfServiceParametersStatus = FetchDataStatus.SUCCESS;
        state.selfServiceParameterData = action.payload;
      })
      .addCase(getSelfServiceCustomParams.rejected, (state, action) => {
        state.selfServiceParametersStatus = FetchDataStatus.FAILURE;
        state.selfServiceParameterError = true;
        state.selfServiceParameterErrorMessage = action.error.message;
      })

      .addCase(defineCustomParameters.pending, (state) => {
        state.customParametersCreationStatus = PostDataStatus.FETCHING;
      })
      .addCase(defineCustomParameters.fulfilled, (state, action) => {      
        state.customParametersCreationStatus = PostDataStatus.SUCCESS;
        state.customParametersState = action.payload;
      })
      .addCase(defineCustomParameters.rejected, (state, action) => {
        state.customParametersCreationStatus = PostDataStatus.FAILURE;
        state.customParametersStateError = true;
        state.customParametersErrorMessage = action.error.message;
      })

      .addCase(defineCustomParametersOptions.pending, (state) => {
        state.parameterOptionsStatus = PostDataStatus.FETCHING;
      })
      .addCase(defineCustomParametersOptions.fulfilled, (state, action) => {      
        state.parameterOptionsStatus = PostDataStatus.SUCCESS;
        state.parameterOptionsStatusData = action.payload;
      })
      .addCase(defineCustomParametersOptions.rejected, (state, action) => {
        state.parameterOptionsStatus = PostDataStatus.FAILURE;
        state.parameterOptionsStatusError = true;
        state.parameterOptionsStatusErrorMessage = action.error.message;
      })

      .addCase(createRules.pending, (state) => {
        state.parameterRulesStatus = PostDataStatus.FETCHING;
      })
      .addCase(createRules.fulfilled, (state, action) => {      
        state.parameterRulesStatus = PostDataStatus.SUCCESS;
        state.parameterRulesStatusData = action.payload;
      })
      .addCase(createRules.rejected, (state, action) => {
        state.parameterRulesStatus = PostDataStatus.FAILURE;
        state.parameterRulesStatusError = true;
        state.parameterOptionsStatusErrorMessage = action.error.message;
      })

      .addCase(getScoreCardRules.pending, (state) => {
        state.scoreCardRulesStatus = FetchDataStatus.FETCHING;
      })
      .addCase(getScoreCardRules.fulfilled, (state, action) => {      
        state.scoreCardRulesStatus = FetchDataStatus.SUCCESS;
        state.scoreCardRuleData = action.payload;
      })
      .addCase(getScoreCardRules.rejected, (state, action) => {
        state.scoreCardRulesStatus = FetchDataStatus.FAILURE;
        state.scoreCardStatusError = true;
        state.scoreCardStatusErrorMessage = action.error.message;
      })
  
  }
});

export const { resetCustomDecisionCreditScore,resetParameter,resetSelfService, 
              resetCustomUpdateCreditScoreStatus,resetAllCustomDecisionCreditScore,
              resetRules } = SelfServiceCreditScoreSlice.actions;

export const selectSelfServiceState = state => state.selfServiceCreditScore;

export default SelfServiceCreditScoreSlice.reducer;