import React,{useEffect, useState} from 'react'
import './CrbTables.css'
import { nameHistoryReShape,telephoneHistoryReShape,addressHistoryReShape,
        identificationHistoryReShape,employmentHistoryReShape } from './ObjectReOrder'

function CrbHistoryTables({columnTitle,spread,content,title}) {
    
    const [dataState,setDataState] = useState([])
    const [historyTitle,setHisTitle] = useState('')

    useEffect(()=>{

        if(title==='nameHistory'){
            const data = nameHistoryReShape(content)
            setHisTitle(data.title)
            setDataState(data.data)

        }
        else if (title==='addressHistory'){
            const data = addressHistoryReShape(content)
            setHisTitle(data.title)
            setDataState(data.data)
        }

        else if (title === 'telephoneHistory'){
            const data = telephoneHistoryReShape(content)
            setHisTitle(data.title)
            setDataState(data.data)
        }

        else if(title === 'employmentHistory'){
            const data = employmentHistoryReShape(content)
            setHisTitle(data.title)
            setDataState(data.data)
        }

        else if(title === 'identificationHistory'){
            const data = identificationHistoryReShape(content)
            setHisTitle(data.title)
            setDataState(data.data)
        }
    },[])
    
  return (
    <section>
        <section className='histHold'>
            {historyTitle}
        </section>
        <div className='crbTableCont'>
        
            <section className={spread==='wide'?'wideCrbTitle':'leanCrbTitle'}>
                {columnTitle.map((element,index)=>
                <section >
                    {element}
                </section>)}
            </section>

            <section >
                {dataState.map((element,index)=>
                <section className={spread==='wide'?'wideCrbContent':'leanCrbContent'}>
                    {element.map((item,indexTwo)=>
                    <section >
                        {item}
                    </section>)}
                </section>)}
            </section>

        
        </div>
    </section>
  )
}

export default CrbHistoryTables

/**
 *  <section className={spread==='thin'?'':''}>
            {content.map((element,index)=>
            <section>
                {element}
            </section>)}
        </section>
 */