import React from 'react'
import './startkyc.css'

function StartKycTour({setKycTourPrompt}) {
    return (

        <div style={{position:"fixed",transform:'translate(-50%,-50%)',top:'50%',left:"50%",backgroundColor:"transparent",zIndex:"12",minHeight:"200px",padding:"10px",boxSizing:"border-box",height:"30%",width:"100%"}}>
            <section className='stepOne'>
            <div className="inner-tour-hold">
            <p style={{color:'rgba(31, 34, 40, 1)',fontWeight:'700',fontSize:'25px'}}>Experience KYC</p>
            <p style={{color:'rgba(0, 0, 0, 1)',fontSize:'18px'}}>This feature is currently not available to you yet. You can see how companies use &nbsp;
                <strong>KYC</strong> with Insights.
            </p>
            <div className="tour-button-hold">
                
                <div className='tour-button-two' onClick={()=>{setKycTourPrompt(false)}}>Start  a tour</div>
            </div>
            </div>
        </section>
        </div>
    )
  }

export default StartKycTour


