import "./ConsolidatedStatement.css";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  useSelector,
  useDispatch
} from "react-redux";
import {
  resetConsolidatedActiveStatement,
  getStatementDetails
} from "../../redux/Slices/ConsolidatedStatement";
import Spinner from "../../svg/Spinner/Spinner";
import Utils from "../../library/Utils";
import Overview from "./Overview/Overview"; 
import Tabs from "../../components/Tabs/Tabs";
import DetailsCard from "../../components/DetailsCard/DetailsCard";
import Button from "../../components/Button/Button";
import DownloadPdf from "../../components/Modals/DownloadPdf";
import Modal from "react-modal";
import { resetDownload } from "../../redux/Slices/DownloadPdf";
import { FetchDataStatus, StatementStatus } from "../../library/Variables";
import ConsolidatedAccounts from "./ConsolidatedAccounts/ConsolidatedAccounts";
import AddLinkedPdf from '../../components/Modals/AddLinkedPdf';

import { 
  selectTransactionState,
  getTransactions 
} from '../../redux/Slices/Transactions';
import { selectDownloadCsvStatus, downloadCsvAsync, resetDownloadCsv } from "../../redux/Slices/DownloadCsv";

if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

function ConsolidatedStatement(props) {
  let tabBodiesArray = [];
  let content = <div className="st-details-body-spinner"><Spinner /></div>;
  let subtitle;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { key } = useParams();
  const accessToken = props.accessToken;
  const user = props.user;
  const statement = useSelector(resetConsolidatedActiveStatement);
  const [modalIsOpen, setIsOpen] = useState(false);
  const transactions = useSelector(selectTransactionState); 
  const configuration = props.configuration;



  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '33%',
      width: '50%',
      top: '33%',
      left: '33%',
      padding: '0 0 0 0',
      overflowY: 'scroll'
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };

  localStorage.setItem("insightsLastVisited", `/statement/${key}`);

  const params = {
    accessToken: accessToken,
    keyFromRoute: key
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() { }

  function closeModal() {
    setIsOpen(false);
    dispatch(resetDownload());
  }

  function downloadCsv(params) {
    dispatch(downloadCsvAsync(params));
    dispatch(resetDownloadCsv());
  }

  useEffect(() => {
    if (Utils.isFieldEmpty(statement) || statement.statementDetailsStatus === FetchDataStatus.INITIAL) {
      dispatch(getStatementDetails(params));
    }

    if (statement.statementDetailsStatus === FetchDataStatus.FAILURE &&
      (statement.activeStatement.processingStatus !== StatementStatus.FAILED
        && statement.activeStatement.processingStatus !== StatementStatus.NOTENOUGHTRANSACTIONS
        && statement.activeStatement.processingStatus !== StatementStatus.LESSTHAN6MONTHS
        && statement.activeStatement.processingStatus !== StatementStatus.UPLOADED)) {
      navigate("/error");
    }
  }, [statement, dispatch, navigate])


  /* Prepare Tabs */
  if ((statement.activeStatement !== null && statement.activeStatement !== undefined)
    && statement.statementDetailsStatus === FetchDataStatus.SUCCESS) {
    let overview = <Overview data={statement} user={user} configuration={configuration} accessToken={accessToken} keyFromRoute={key} />;
    let consolidatedaccounts = <ConsolidatedAccounts data={statement} user={user} configuration={configuration} accessToken={accessToken} keyFromRoute={key} />;
    let bureau = null;
    // let scoreCard = <NotEnabled name={"Scorecard"} message={"to set up your credit scorecard."} />;

    tabBodiesArray.push({
      title: "Overview",
      content: overview
    });

    tabBodiesArray.push({
      title: "Accounts",
      content: consolidatedaccounts
    });
  }

  if (statement.isLoading === false) {
    let button = null;
    let mainContent = null;

    if (statement.activeStatement.processingStatus !== StatementStatus.FAILED
      && statement.activeStatement.processingStatus !== StatementStatus.NOTENOUGHTRANSACTIONS
      && statement.activeStatement.processingStatus !== StatementStatus.LESSTHAN6MONTHS
      && statement.activeStatement.processingStatus !== StatementStatus.ALTERED
      && statement.activeStatement.processingStatus !== StatementStatus.UPLOADED) {


      if (user.user.permissions.downloadReport === true) {
        button = <div className="st-report-buttons-container">
          <Button name={"Export as .csv"} className="button-link export" iconSrc="/assets/icons/paper-download.png"
              clickFunction={() => downloadCsv(params)} />
        </div>
      }

      mainContent = <Tabs tabBodies={tabBodiesArray} extraClass="top-level-tabs" />
    } else if (statement.activeStatement.processingStatus === StatementStatus.FAILED) {
      mainContent = <div className="st-not-processed-container">
        <DetailsCard title={"Upload Failed"}
          headerExtra={<img src="/assets/icons/close-square-color-red.svg" alt="" />}
          detailContent={<>
            <p>There was an error during file processing. Periculum will work to resolve this issue.</p>
            <p>Please <a href={"mailto:support@periculum.io"}>
              Contact Us</a>  if you have further questions.</p>
          </>} />
      </div>
    } else if (statement.activeStatement.processingStatus === StatementStatus.NOTENOUGHTRANSACTIONS) {
      mainContent = <div className="st-not-processed-container">
        <DetailsCard title={"Upload Failed"}
          headerExtra={<img src="/assets/icons/close-square-color-red.svg" alt="" />}
          detailContent={<>
            <p>This statement does not have enough transactions needed for processing.</p>
            <p>Please <a href={"mailto:support@periculum.io"}>
              Contact Us</a>  if you have further questions.</p>
          </>} />
      </div>
    } else if (statement.activeStatement.processingStatus === StatementStatus.LESSTHAN6MONTHS) {
      mainContent = <div className="st-not-processed-container">
        <DetailsCard title={"Upload Failed"}
          headerExtra={<img src="/assets/icons/close-square-color-red.svg" alt="" />}
          detailContent={<>
            <p>This statement does not have up to 6 months transactions needed for processing.</p>
            <p>Please <a href={"mailto:support@periculum.io"}>
              Contact Us</a>  if you have further questions.</p>
          </>} />
      </div>
    }else if (statement.activeStatement.processingStatus === StatementStatus.ALTERED) {
      mainContent = <div className="st-not-processed-container">
        <DetailsCard title={"Upload Failed"}
          headerExtra={<img src="/assets/icons/close-square-color-red.svg" alt="" />}
          detailContent={<>
            <p>Periculum detected that the content of this statement may have been altered</p>
            <p>Please <a href={"mailto:support@periculum.io"}>
              Contact Us</a> for more details.</p>
          </>} />
      </div>
    } else {
      mainContent = <div className="st-not-processed-container">
        <DetailsCard title={"Processing File"}
          headerExtra={<img src="/assets/icons/arrows-rotate-color.svg" alt="" />}
          detailContent={<p>This statement has not been fully processed. Try viewing it again at a later time.</p>} />
      </div>
    }

    content = <div className="st-details-body">

      <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles}
        contentLabel="Add Statement" >
        <AddLinkedPdf configuration={configuration} accessToken={accessToken} statementKey={key} subtitle={subtitle} close={closeModal} userId={statement.activeStatement.userId} />
      </Modal>

      {/* <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} contentLabel="Download Report">
        <DownloadPdf accessToken={accessToken} statementKey={key} statementName={statement.activeStatement.name}
          configuration={configuration} close={closeModal} />
      </Modal> */}
      <div className="st-top-buttons">
        <Button name={"Back To List"} className="button-link back-button detail-header-dark" iconSrc="/assets/icons/arrow-left.png"
          clickFunction={() => navigate("/home")} />
      </div>
      <div className="st-details-main-header-container">
        <div className="st-details-main-header consolidated-header">
          <div className="st-details-main-header-stats-container">
            {/* {
              button = <div className="st-report-buttons-container">
                <Button name={"This is a consolidated statement"} className="button-link consolidated" iconSrc="/assets/icons/info-circle.png" clickFunction={() => {return}}  />
              </div>
            } */}
            {
              button = <div className="st-report-buttons-container">
              {configuration.featureFlags.isConsolidatedStatementEnabled && <Button name={"Link another statement"} className="button-link link-statement" clickFunction={() => openModal()} iconSrc="/assets/icons/link-new.svg" />
              }</div>
            }
          </div>
          <div className="st-details-main-header-stats-container">
            {
              button = <div className="st-report-buttons-container">
                <h2 className="detail-header-cs">{statement.activeStatement.customerName}</h2>
              </div>
            }

            {
              button = <div className="st-report-buttons-container">
              <Button name={"Export as .csv"} className="button-link export" iconSrc="/assets/icons/paper-download.png"
                  clickFunction={() => downloadCsv(params)} />
            </div>
            }
          </div>
          <div className="customer-id-consolidated">
            <h4> <span> CUSTOMER ID: </span> {statement.activeStatement.userId} </h4>
          </div>

        </div>
      </div>
      <div className="st-main-details-container">
      </div>
      {mainContent}
    </div>
  }

  return content;
}

export default ConsolidatedStatement;