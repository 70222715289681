import React,{useState,useEffect} from 'react'
import NewSection from './NewSection'
import Utils from '../../../../library/Utils'
import Spinner from '../../../../svg/Spinner/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { getScoreCardRules,selectSelfServiceState,getSelfServiceCustomParams,getSelfServiceScoreCardDefinitions } from '../../../../redux/Slices/SelfServiceDecision'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FetchDataStatus } from '../../../../library/Variables'




function NewRule({ruleIndex,setRulesLength,rulesLength,accessToken,setScoreCardDet,storeScoreCardDet,ruleContent,paramData,scoreCard,scoreCardList,definitionRules,setDefinitionRules}) {
    const [sectionLength,setSectionLength] = useState([{key:'1',content:{}}])
    const [ruleSaveable,setRuleSaveAble] = useState(false)
    const dispatch = useDispatch()
    const [ruleSaved,setRuleSaved] = useState(false)
    const [param,setParam] = useState()
    const selfServiceState = useSelector(selectSelfServiceState)
    const [paramKey,setParamKey] = useState()
    const [ruleObj,setRuleObj] = useState({name:'',displayType:'',customParameterKey:''})
    
    const removeRules = (index)=>{
        let mainArr = [...rulesLength]
        mainArr.splice(index,1)
        setRulesLength(mainArr)
    }

    useEffect(()=>{
        if(!Utils.isFieldEmpty(param) ){
            setRuleSaveAble(true)
            const ruleData = selfServiceState.scoreCardRuleData[param]
            setRuleObj({name:ruleData.name,displayType:ruleData.displayType,
            customParameterKey:parseInt(ruleData.customParameterKey)})
        }
        else{
            setRuleSaveAble(false)
        }

    },[param])

    const saveRules = (index)=>{
        let newRanges = [];
        sectionLength.map((section,index)=>newRanges.push(section.content)) 
        const ruleFinalObj = {
            ruleName:ruleObj.name,
            customParameterKey:parseInt(ruleObj.customParameterKey),
            ruleRanges: [...newRanges]
        }
        const newDefinitionRules = [...definitionRules]
        newDefinitionRules[index] = ruleFinalObj
        setDefinitionRules(newDefinitionRules)
        setRuleSaveAble(false)
    }
  
  
    useEffect(()=>{
        if (!Utils.isFieldEmpty(scoreCard)) {
            dispatch(getScoreCardRules({accessToken,key:scoreCard}))
        }
    },[scoreCard])
    

    

    useEffect(()=>{
  
        dispatch(getSelfServiceScoreCardDefinitions({accessToken}))
    },[])

    const getRulesList = ()=>{
        if(Utils.isFieldEmpty(scoreCard)){
            toast.info('Select Score Card first.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
        }
    }
  return (
        <>
            {(Utils.isFieldEmpty(storeScoreCardDet))?<Spinner/>:
                <section style={{marginBottom:'45px'}}>
            <div style={{backgroundColor:'white',padding:"15px",height:"400px",borderRadius:'10px',
                    overflowY:'auto',display:"flex",flexDirection:"column",
                    justifyContent:"space-between"}}>
            <div style={{display:'flex',marginBottom:"25px",justifyContent:'space-between'}}>
                <section onClick={()=>getRulesList()} style={{display:'flex',flexDirection:"column",width:"40%",alignItems:"start",justifyContent:"center",columnGap:"10px"}}>
                    <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px",textAlign:"left"}}>Name</label>
                    
                    <select  style={{display:'flex',alignItems:"center",height:"45px",width:"100%",
                            border:"1px solid rgba(223, 232, 255, 1)",justifyContent:"space-between",
                            padding:"0px 7px",outline:"none"}} 
                            onChange={(e) => setParam(e.target.value)}
                        >
                            <option value="">Select an option</option>
                            {selfServiceState.scoreCardRuleData.map((element, i) => {
                                return <option  value={i} key={i}>{element.friendlyName}</option>
                            })}
                        
                    </select>
                
                </section>

            </div>
            <section style={{fontSize:'18px',margin:'',fontWeight:"500"}}>Add Extra Rules</section>
            
            {sectionLength.map((section,index)=><NewSection section={section} sectionLength={sectionLength} 
                                setSectionLength={setSectionLength} index={index} ruleObj={ruleObj}/>)}

            <section style={{display:'flex',alignItems:'center',justifyContent:'end'}}>
                <div className='createParameter' onClick={()=>setSectionLength(()=>[...sectionLength,{key:`${sectionLength.length+1}`,content:{}}])} >Add Extra Rule</div>
            </section>
          
            
        </div>
        <section style={{display:'flex',alignItems:'center',justifyContent:'end',columnGap:'15px'}}>
                <div className={ruleSaveable?'createdParameter':'createNewParameter'} onClick={()=>saveRules(ruleIndex)} >Save Rules</div>
                <div className='createParameter' onClick={()=>removeRules(ruleIndex)} >Remove Rules</div>
            </section>
        </section>
            }
        </>
  )
}

export default NewRule