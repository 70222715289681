import React from 'react'
import './kybReport.css'
import { resetReport } from '../../../../../redux/Slices/KybOptions';
import { selectKybEntireDataState } from '../../../../../redux/Slices/KybOptions';
import { useSelector } from 'react-redux';
import Spinner from '../../../../../svg/Spinner/Spinner';
import { FetchDataStatus } from '../../../../../library/Variables';
import Utils from '../../../../../library/Utils';
import no_image from '../../../../../assests/icons/no_image.png'


function KybExtractionReport(props) {
  let kycState = useSelector(selectKybEntireDataState)


  const mimeType = 'data:image/jpeg;base64,'
  const basic_Keys = ["rcNumber","registrationDate","state","id","status","city","companyStatus","lga",
                      "classisficationId","branchAddress","type","uuid","approvedName","email","address"]


    let content

    const ObjSpreader = (obj)=>{
    
      const sub_headers = ["affiliateTypeFk","countryFk"]
      let storeObj = {...obj}
      let allKeys = Object.keys(obj);
      
      for(let j=0;j<allKeys.length;j++){
        if(sub_headers.includes(allKeys[j])){
          for(let i =0;i<sub_headers.length;i++){
            let newKeys = Object.keys(obj[sub_headers[i]])
            let newObject = {}
            newKeys.forEach(key => {
              newObject[sub_headers[i] + '_' + key] = obj[sub_headers[i]][key];
          });
          storeObj = {...storeObj,...newObject}
          delete storeObj[sub_headers[i]]
          }
        }
      }

      return storeObj

    }


    function downloadCSV(csvData, filename) {
      // Create a Blob from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv' });
  
      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);
  
      // Create a link element
      const link = document.createElement('a');
      link.href = url;
    
      link.setAttribute('download', filename); // Set the download attribute and filename
  
      // Append the link to the document body
      document.body.appendChild(link);
  
      // Programmatically click the link to trigger the download
      link.click();
  
      // Cleanup: Remove the link and revoke the URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
  }


    function convertJSONtoCSV(jsonData) {
      // Extract headers
 
      let mainData = jsonData.directorData
      let newData = []
      
      for(let element = 0; element<mainData.length; element++){
        const response = ObjSpreader(mainData[element])

        newData.push(response)
      }


      const headers = Object.keys(newData[0]);
     
      const csv = [
          headers.join(','),
          ...newData.map(row =>
              headers.map(fieldName =>
                  JSON.stringify(row[fieldName])
              ).join(',')
          )
      ].join('\n');
      downloadCSV(csv,'AdvancedCacfile')
     
      //return csv;
  }


    if(kycState.reportStatus === FetchDataStatus.FETCHING){

      content = <Spinner/>
    }

    if(kycState.reportStatus === FetchDataStatus.SUCCESS){

      let userReport = {...kycState.reportData}
      let userImg = null;
      let userSignature = null;

      if(!Utils.isFieldEmpty(kycState.reportData?.base64Image)){
        userImg = mimeType + kycState.reportData?.base64Image
      }
      if(!Utils.isFieldEmpty(kycState.reportData?.signaturee)){
        userSignature = mimeType + kycState.reportData?.signature
      }
      
    let reportKeys = Object.keys(userReport);
    let filteredKeys = []
    let NonAdvancedKeys = []
    for(let i=0;i<basic_Keys.length;i++){
        if(!Array.isArray(userReport[basic_Keys[i]])){
            filteredKeys.push(basic_Keys[i])
        }
    }

    for(let i=0;i<reportKeys.length;i++){
      if(!Array.isArray(userReport[reportKeys[i]])){
        NonAdvancedKeys.push(reportKeys[i])
      }
  }

  

  const fetchKeysOnReport = (data)=>{

    let reportKeys = Object.keys(data);
    let filteredKeys = []

    for(let i=0;i<reportKeys.length;i++){
        if(typeof data[reportKeys[i]] !== 'object'){
            filteredKeys.push(reportKeys[i])
        }
    }

    return filteredKeys

  }
      content = 
          <>
            <div className='roof'>
              <span 
                  className={props.viewReport?'span-view-report':'span-view-history'} 
                  onClick={()=>{props.setViewReport(false)}}>
                  History
              </span>
              <span>/</span>
              <span className={props.viewReport?'span-view-history':'span-view-report'}>View report</span>
            </div>

            <p className='title-class'>{`${props.verificationType} Verification`}</p>
            <p style={{margin:'10px 0px'}}>{`Here's the report from the ${props.verificationType} identity check`}</p>
            {(props.verificationType!=='TIN' && userReport?.isAdvanced)?
            <>
              <>
                {userReport?.isAdvanced && <p 
                      onClick={()=>convertJSONtoCSV(userReport)} 
                      style={{width:'fit-content',padding:'5px 10px',
                      textAlign:"center",boxShadow:' 0 4px 8px rgba(0, 0, 0, 0.1)',
                      cursor:'pointer',backgroundColor:"white",margin:'20px 0px'}}>Download CAC as csv</p>}   
              </>

              <div className='report-contentKYB'>
                <div className='report-fields'>
                      <p style={{color:'rgba(42, 56, 164, 1)',fontSize:'20px',fontWeight:'700',margin:"25px 0px"}}>Information report on CAC</p>
                      {filteredKeys.map(key=> <div className='keyValue-holder' key={filteredKeys.indexOf(key)}>
                        <p className='key'>{key}</p>
                        <p className='value'>{userReport[key]}</p>
                      </div>
                      )}
                </div>

                {userImg?<div className='img-container'>
                    <p className='photo'>Photo</p>
                    <div className='imgKycBox'>
                      <img src={userImg} style={{width:'auto'}}/>
                    </div>
                  
                </div>: <div className='no-img'>
                    <p className='text-top'>Photo</p>
                    <div style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"Center"}}>
                      <img src={no_image} style={{width:'auto'}}/>
                    </div>
                  
                </div>
                }
              </div>


              {
                userReport.directorData.map((element,index)=><>
                <div className='info-report'>
                  <p style={{color:'rgba(42, 56, 164, 1)',fontSize:'20px',fontWeight:'700',margin:"25px 0px"}}>{`Report on Director(s) ${index+1}`}</p>
                    {fetchKeysOnReport(element).map((key,index)=> <div key={index} className='keyValue-holder'>
                      <p className='key'>{key}</p>
                      <p className='value'>{element[key]}</p>
                    </div>
                    )}
                </div>
                </>)
              }
         
           </>:
           <>
           <div className='report-contentKYB'>
              <div className='report-fields'>
                  {NonAdvancedKeys.map(key=>
                  <div className='keyValue-holder' key={reportKeys.indexOf(key)}>
                    <p className='key'>{key}</p>
                    <p className='value'>{userReport[key]}</p>
                  </div>)}
              </div>

              {userImg?<div className='img-container'>
                  <p className='photo'>Photo</p>
                  <div className='imgKycBox'>
                    <img src={userImg} style={{width:'auto'}}/>
                  </div>
                
              </div>: ''
              }
            </div>
           </> }
          </>
    }

  if(kycState.reportStatus===FetchDataStatus.FAILURE){
    content = 
    <>
      <div className='roof'>
        <span 
            className={props.viewReport?'span-view-report':'span-view-history'} 
            onClick={()=>{props.setViewReport(false)}}>
            History
        </span>
        <span>/</span>
        <span className={props.viewReport?'span-view-history':'span-view-report'}>View report</span>
      </div>
      <div style={{minHeight:"150px",display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"space-around",backgroundColor:'white',width:'70%',borderRadius:'10px',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',margin:'30px auto'}}>
          <p style={{fontWeight:'700',fontStyle:'italic',fontSize:'22px'}}>Something Went Wrong</p>
          <p style={{textAlign:'center',fontSize:'15px',marginTop:'10px'}}>{kycState.reportErrorMessage?kycState.reportErrorMessage:'Try again'}</p>
      </div>
    </>
    
  }
  return (
   
    <div className='report-hold'>
      {content}
    </div>

  )
}

export default KybExtractionReport

//