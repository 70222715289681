import React from 'react'

import CrbHistoryTables from '../CrbHistoryTables'
import Utils from '../../../../../library/Utils'

function HistoryBreakDown({userReport}) {
  return (
    <div style={{
        width:"100%",
        backgroundColor:"white",
        padding:'10px',
        boxSizing:"border-box"
    }}>
        <CrbHistoryTables columnTitle={['Address','Address Type','Update Date','Update on Date']}
                        spread = {'lean'}
                        content={userReport.data.report.addressHistory}
                        title = {'addressHistory'}/>
        <section className='lowerInfoHold'>
          <div className='segmentHold'>
            <section>
              Adverse Details
            </section>
            <section style={{fontWeight:'700',fontSize:"16px"}}>
              {Utils.checkNull(userReport.data.report.adverseDetails)}
            </section>
          </div>

          <div className='segmentHold'>
            <section>
              Guarantor Details
            </section>
            <section style={{fontWeight:'700',fontSize:"16px"}}>
              {Utils.checkNull(userReport.data.report.guarantorDetails)}
            </section>
          </div>


          <div className='segmentHold'>
            <section>
              Guarantors Secured
            </section>
            <section style={{fontWeight:'700',fontSize:"16px"}}>
              {userReport.data.report.guarantorCount.guarantorsSecured}
            </section>
          </div>


          <section className='guarantorHold'>
            Guarantor Count
          </section>
          <div className='segmentHold'>
            <section>
              Accounts
            </section>
            <section style={{fontWeight:'700',fontSize:"16px"}}>
              {userReport.data.report.guarantorCount.accounts}
            </section>
          </div>

          <div className='segmentHold'>
            <section>
              Guarantors Secured
            </section>
            <section style={{fontWeight:'700',fontSize:"16px"}}>
              {userReport.data.report.guarantorCount.guarantorsSecured}
            </section>
          </div>
        </section>

        <CrbHistoryTables columnTitle={['Update Date','Home','Update Date','Work','Update Date','Mobile']}
                            spread = {'wide'}
                            content={userReport.data.report.telephoneHistory}
                            title = {'telephoneHistory'}/>

        <CrbHistoryTables columnTitle={['Occupation','Employment Detail','Update Date','Update on Date']}
                        spread = {'lean'}
                        content={userReport.data.report.employmentHistory}
                        title = {'employmentHistory'}/>

        <CrbHistoryTables columnTitle={['Identification Type','Identification Number','Update Date','Update on Date']}
                            spread = {'lean'}
                            content={userReport.data.report.identificationHistory}
                            title = {'identificationHistory'}/>
    </div>
  )
}

export default HistoryBreakDown