import React,{useState,useEffect} from 'react'
import { useForm } from "react-hook-form";
import Utils from '../../../../library/Utils';

function NewSection({sectionLength,section,setSectionLength,index,ruleObj}) {
    const { register,getValues,setValue, formState: { errors }, handleSubmit,trigger, reset } = useForm()
    const [rulePoints,setRulePoints] = useState('')
    const [action,setActions] = useState()
    const [sentiment,setSentiment] = useState()
    const [newValueOne,setNewValueOne] = useState()
    const [newValueTwo,setNewValueTwo] = useState()

    const [sectionAdded,setSectionAdded] = useState(false)
    const [addAble,setAddAble] = useState(false)
    const addSection = (index)=>{
        if(!sectionAdded && !Utils.isFieldEmpty(action) && !Utils.isFieldEmpty(sentiment) &&
        !Utils.isFieldEmpty(rulePoints) && !Utils.isFieldEmpty(newValueOne)
        ){
            setAddAble(false)
            const newArray = [...sectionLength];

            // Replace the object at the specified index
            newArray[index] = {key:index
                ,content:{
                    ruleValueOne:newValueOne,
                    ruleValueTwo:newValueTwo?newValueTwo:null,
                    operator:action,
                    rulePoints:parseInt(rulePoints),
                    passingSentiment:sentiment,
                    displayType:ruleObj.displayType
                }};
        
            // Update the state with the modified array
            setSectionLength(newArray);
            setSectionAdded(true)
                
            
        }
    }

    const removeSection = (index)=>{
        let mainArr = [...sectionLength]
        mainArr.splice(index,1)
        //setOptionArray()
        //dispatch(deleteParameter(index))
        setSectionLength(mainArr)

        //let features = [...lengthOfFeatures]
        //features.splice(index,1)
        //setLengthOfFeatures(features)
    }

    useEffect(()=>{
        if(!Utils.isFieldEmpty(rulePoints) && !Utils.isFieldEmpty(action) 
            && !Utils.isFieldEmpty(sentiment) && !Utils.isFieldEmpty(newValueOne)
          ){
            setAddAble(true)
        }

    },[rulePoints,action,sentiment,newValueOne])


    const Action = ['EQUALS','BETWEEN','LESS_THAN','GREATER_THAN']
    const Sentiments = ['GOOD','BAD']
    const displayType = ['TEXT','NUMBER','CURRENCY']

    const onChangeRulePoints = (e)=>{
        if(!sectionAdded){
            setRulePoints(e.target.value)
        }
    }

    const onChangeNewValues = (e)=>{
        if(!sectionAdded){
            setNewValueOne(e.target.value)
        }
    }

    const onChangeNewValuesTwo = (e)=>{
        if(!sectionAdded){
            setNewValueTwo(e.target.value)
        }
    }


  return (
    <div key={index} style={{display:'flex',flexDirection:'column',marginTop:'20px',marginBottom:"35px"}}> 
        <div style={{display:'flex',justifyContent:"left",rowGap:"15px",flexDirection:'column'}}>
            <div style={{display:"flex",justifyContent:"space-between"}}>
                <section style={{display:'flex',flexDirection:"column",width:"40%",alignItems:"start",justifyContent:"center",columnGap:"10px"}}>
                    <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px",textAlign:"left"}}>Rule Points</label>
                    
                    <input  style={{border:'2px solid rgba(223, 232, 255, 1)',height:"45px",width:'100%',paddingLeft:"30px"}} 
                                placeholder='input rule points' autocomplete="off" type='text'
                                value={sectionAdded?section.content.rulePoints:rulePoints}
                                onChange={(e)=>onChangeRulePoints(e)}
                                />
                
                </section>

                <section style={{display:'flex',flexDirection:"column",alignItems:"start",width:"40%",justifyContent:"center",columnGap:"10px"}}>
                    <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px",textAlign:"left"}}>Action</label>
                    
                    {sectionAdded?
                        <section  style={{display:'flex',alignItems:"center",boxSizing:"border-box",height:"50px",width:"100%",
                            border:"1px solid rgba(223, 232, 255, 1)",justifyContent:"space-between",
                            padding:"0px 7px",outline:"none"}} 
                    >
                        {section.content.operator}
                    </section>:
                    <select  style={{display:'flex',alignItems:"center",height:"50px",width:"100%",
                            border:"1px solid rgba(223, 232, 255, 1)",justifyContent:"space-between",
                            padding:"0px 7px",outline:"none"}} 
                            onChange={(e) => setActions(e.target.value)}

                    >
                        <option value="">Select an option</option>
                        {Action.map((element, i) => {
                            return <option value={element} key={i}>{element}</option>
                        })}
                    </select>}
                
                </section>
            </div>
            

            <div style={{display: "flex",justifyContent:"space-between"}}>
                <section style={{display:'flex',flexDirection:"column",alignItems:"start",width:'40%',justifyContent:"center",columnGap:"10px"}}>
                    <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px",textAlign:"left"}}>Value One</label>
                    
                    <input  style={{border:'2px solid rgba(223, 232, 255, 1)',width:"100%",boxSizing:"border-box",height:"45px",paddingLeft:"30px"}} 
                                placeholder='input value one' autocomplete="off" type='text'
                                value={sectionAdded?section.content.ruleValueOne:newValueOne}
                                onChange={(e)=>onChangeNewValues(e)}
                                
                                />
                
                </section>

                <section style={{display:action==='Between'?'flex':'none',flexDirection:"column",alignItems:"start",width:'40%',justifyContent:"center",columnGap:"10px"}}>
                    <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px",textAlign:"left"}}>Value Two</label>
                    
                    <input  style={{border:'2px solid rgba(223, 232, 255, 1)',width:"100%",boxSizing:'border-box',height:"45px",paddingLeft:"30px"}} 
                                placeholder='input value two' autocomplete="off" type='text'
                                value={sectionAdded?section.content.ruleValueTwo:newValueTwo}
                                onChange={(e)=>onChangeNewValuesTwo(e)}
                                
                                />
                
                </section>

               
            </div>


            <div style={{display:"flex",justifyContent:"space-between"}}>
            

                <section style={{display:'flex',flexDirection:"column",alignItems:"start",width:"40%",justifyContent:"center",columnGap:"10px"}}>
                    <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px",textAlign:"left"}}>Passing Sentiments</label>
                    
                    {sectionAdded?
                        <section  style={{display:'flex',alignItems:"center",height:"50px",width:"100%",
                            border:"1px solid rgba(223, 232, 255, 1)",justifyContent:"space-between",
                            padding:"0px 7px",outline:"none",boxSizing:'border-box'}} 
                           
                        >
                            {section.content.passingSentiment}
                        </section>:
                        <select  style={{display:'flex',alignItems:"center",height:"50px",width:"100%",
                                border:"1px solid rgba(223, 232, 255, 1)",justifyContent:"space-between",
                                padding:"0px 7px",outline:"none"}} 
                                onChange={(e) => setSentiment(e.target.value)}
                        >
                            <option value="">Select an option</option>
                            {Sentiments.map((element, i) => {
                                return <option  value={element} key={i}>{element}</option>
                            })}
                        </select>}
                
                </section>

               
            </div>
            <section style={{display:'flex',alignItems:'center',justifyContent:'end',columnGap:'15px'}}>
                    <div className={addAble?'addButtonSelected':'addButton'} onClick={()=>addSection(index)}>+</div>
                    <div className='addButtonSelected' onClick={()=>removeSection(index)} >-</div>
                </section>
            

            
        </div>
</div>
  )
}

export default NewSection