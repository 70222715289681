import React,{useState,useEffect} from 'react'
import { useForm } from "react-hook-form";
import './SelectOptions.css'
import ParamOptions from './ParamOptions';
import { useDispatch } from 'react-redux';
import { defineCustomParametersOptions } from '../../../../../../redux/Slices/SelfServiceDecision';
import Utils from '../../../../../../library/Utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function DefineSelectOptions({response,accessToken}) {
    const dispatch = useDispatch()
    const [lengthOfFeatures,setLengthOfFeatures] = useState([{key:'1',value:''}])
    const { register,getValues,setValue, formState: { errors }, handleSubmit,trigger, reset } = useForm()
    const [optionArray,setOptionArray] = useState([{key:'1',value:''}])
    const addFeature = ()=>{
        const featureLength = lengthOfFeatures.length;
        const newFeat = {key:`feature${featureLength+1}`,value:''}
        setLengthOfFeatures((prev)=>[...prev,newFeat])
    }
   
    const submit = ()=>{
        let arrayObj = []
        lengthOfFeatures.map(item=>{
            if(!Utils.isFieldEmpty(item.value)){
                arrayObj.push({"value":item.value})
            }
        })
        const dataHolder = {"customParameterKey":response.key,"options":arrayObj}
       

        if(arrayObj.length>0){
            dispatch(defineCustomParametersOptions({data:dataHolder,accessToken}))
        }
        else{
            toast.info('Fill in Parameter Options.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
        }
    }


  return (
    <section style={{margin:'30px 0px',padding:'0px 0px',width:"100%"}}>
            <p style={{color:"rgba(85, 87, 90, 1)",fontSize:"18px"}}>Define Options</p>
            <section style={{minHeight:"350px",padding:"30px 25px",boxSizing:"border-box",display:'flex',flexDirection:"column",rowGap:'20px',justifyContent:"space-between",borderRadius:"5px",backgroundColor:"white",marginTop:"20px",boxShadow:" 0 3px 6px rgba(0, 0, 0, .1)"}}>
                <div style={{display:'flex',flexDirection:'column'}}> 
                    <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Option Name</label>
                    <div style={{height:'300px',overflowY:'auto',width:'90%',display:"flex",flexDirection:"column",border:"1px solid rgba(223, 232, 255, 1)",padding:'15px'}}>
                        {lengthOfFeatures.map((feature,index)=>
                        <ParamOptions lengthOfFeatures={lengthOfFeatures} setLengthOfFeatures={setLengthOfFeatures} index={index}
                                      feature={feature.value} optionArray={optionArray} setOptionArray={setOptionArray}
                        />
                        )}
                        
                    </div>

                    <section onClick={()=>addFeature()} style={{display:'flex',alignItems:'center',justifyContent:'end'}}>
                        <div className='createParameter'  >Add Feature</div>
                    </section>
                               
                </div> 
            </section>
            <section onClick={()=>submit()} style={{display:'flex',alignItems:'center',justifyContent:'end'}}>
                <div className='createParameter'  >Submit</div>
            </section>

        </section>
  )
}

export default DefineSelectOptions