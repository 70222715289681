import React,{useMemo} from 'react'
import ClosingBalanceIcon from '../../../../assests/icons/closing_balance.svg'
import gtIcon from '../../../../assests/icons/gtb.png'
import ecoIcon from '../../../../assests/icons/EcoBank.png'
import firstIcon from '../../../../assests/icons/firstBank.png'
import accessIcon from '../../../../assests/icons/AccessBank.png'
import Utils from '../../../../library/Utils'
import { useNavigate } from 'react-router'
import { resetActiveStatement } from '../../../../redux/Slices/StatementDetails'
import { useDispatch } from 'react-redux'
import { Table, SelectColumnFilter, DateRangeColumnFilter } from '../../../../components/Table/Table'
import { Link,useParams } from 'react-router-dom'



function AccountsNg(props) {
    const { key } = useParams();
    const loansHead = ['Account','Created Date','Updated Date','Status','Type','Action']
    const statement = props.statement
    let tableData = [];
    const navigate = useNavigate()
    const dispatch = useDispatch()

    statement?.activeStatement?.accounts?.statementSummaries.map((acc,index)=> {
        tableData.push({
          bankName: acc.bankName,
          isNameMatched: acc.isNameMatched,
          createdDate: acc.createdDate,
          updatedDate: acc.updatedDate,
          status: acc.status,
       
        })
      })

    const theadersStatements = useMemo(() => [
        { 
          Header: 'Account',
          accessor: 'bankName'
        },
        {
            Cell: data => {
              let statusDisplay = data.row.original.isNameMatched;
  
              return (
                <span style={{ 
                  color: statusDisplay===true?'green':'red'}}>
                  {statusDisplay?'MATCHED':'MISMATCHED'}
                </span>
              );
            },
          Header: 'Name Match',
          accessor: 'isNameMatched',
        },
        {
            Cell: data => {
              let createdDate = data.row.original.createdDate;
  
              return (
                <span>
                {Utils.formatLongDateAndTime(createdDate)}
              </span>
              );
            }, 
          Header: 'Created Date',
          accessor: 'createdDate',
          Filter: DateRangeColumnFilter,
          filter: 'dateRange'
        },
        {
            Cell: data => {
              let updatedDate = data.row.original.updatedDate;
  
              return (
                <span>
                  {Utils.formatLongDateAndTime(updatedDate)}
                </span>
              );
            },
          Header: 'Updated Date',
          accessor: 'updatedDate',
          Filter: DateRangeColumnFilter,
          filter: 'dateRange'
        },
        {
          Cell: data => {
            let statusDisplay = data.row.original.status;

            return (
              <span style={{ 
                color: statusDisplay==='PROCESSED'?'green':'red'}}>
                {statusDisplay}
              </span>
            );
          },
          Header: 'Status',
          accessor: 'status',
          Filter: SelectColumnFilter,
          filter: 'includes'
        },
        {
          id: 'button-column',
          disableSortBy: true,
          Cell: data => {
            let link = `/statement/${key}`;
            return (
              <div className="table-row-buttons-container">
                <Link to={link} style={{flex:'1',cursor:'pointer',textDecoration:'None',fontSize:'14px',color:'rgba(17, 98, 220, 1)',fontWeight:'400',display:"flex",alignItems:"center",justifyContent:'start',paddingLeft:'7px'}} onClick={() => dispatch(resetActiveStatement())}>VIEW ANALYSIS</Link>
              </div>
            );
          },
          Header: 'Action',
          
        }]
    )

    const navigatetoStatement = (key)=>{
        dispatch(resetActiveStatement())
        navigate(`/statement/${key}`)
    }

   const content = <Table tableClass="statement-type-table" pagination={true} pageSize={[10]} data={tableData} columns={theadersStatements}
    tableExtra={false} tableFilter={false} />;

  return (
    <section style={{flex:'1',marginTop:'30px auto',display:"flex",flexDirection:"column",alignItems:"center",height:'fit-content',borderRadius:"8px",boxSizing:"border-box"}}>
                <p style={{fontSize:"20px",fontWeight:'500',margin:'7px 0px',color:'rgba(54, 60, 79, 1)'}}>ALL ACCOUNTS</p>
                <p style={{fontSize:"14px",fontWeight:'400',margin:'7px 0px',color:'rgba(54, 60, 79, 1)'}}>A list of all the banks associated with Bob John</p>
                <div style={{display:"flex",margin:'10px 0px'}}>
                    {content}
                </div>
            </section>
  )
}

export default AccountsNg



