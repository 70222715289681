import React,{useState} from 'react'
import selectedIcon from '../../../../../assests/icons/concentricSelected.png'
import unselectedIcon from '../../../../../assests/icons/nonSelected.png'
import dropDownIcon from '../../../../../assests/icons/arrowDownWhite.png'
import DeleteIcon from '../../../../../assests/icons/Delete.svg'
import { useNavigate } from 'react-router'

function ParameterList({setSection,selfScoreDef}) {
  const [headers, setHeader] = useState(['Parameter Name','Type of Parameter','Last Updated','Action'])
  const [parameterState,setParameterState] = useState('custom')
    const [showParameter,setShowParameter] = useState(false)
    const [progress,setProgress] = useState(false)
    const navigate = useNavigate()
  return (
    <div>
        <section>
            <div style={{display:"flex",justifyContent:'space-between',alignItems:"center"}}>
                <div>
                    <span style={{fontSize:"24px",fontWeight:"700",color:"black"}}>Parameter</span>
                </div>
                <div style={{position:"relative",width:"fit-content"}}>
                    <div className='createParameter' onClick={()=>navigate('/DecisionEngineSelfService/ParameterCreation')}>
                        <span style={{fontSize:"18px",fontWeight:"700",color:"white"}}>Create Parameter</span>
                        
                    </div>

                </div>
            </div>

            <div style={{marginTop:'20px'}}>
                <section style={{display:"flex",justifyContent:"space-between"}}>
                    {headers.map((head,index)=><span style={{fontWeight:'700',flex:'1',fontSize:'16px'}} key={index}>{head}</span>)}
                </section>
                <section style={{display:"flex",justifyContent:"space-between",height:'300px',overflow:'auto',flexDirection:"column",rowGap:'25px',boxShadow:" 0 3px 6px rgba(0, 0, 0, .1)",borderRadius:"7px",marginTop:"10px",backgroundColor:"white",padding:"10px"}}>
                    {selfScoreDef.selfServiceParameterData.map((item,index)=>
                        <div key={index} style={{display:"flex",justifyContent:"space-between"}}>
                            <span style={{fontWeight:'700',flex:'1',fontSize:'16px'}}>{item.name}</span>
                            <span style={{flex:'1'}}>Rule-based</span>
                            <span style={{flex:'1'}}>--</span>
                            <div style={{flex:'1',display:"flex",justifyContent:'left',columnGap:'4px'}}>
                                <section className='editButton'>Edit</section>
                                <span className='imageHolder'><img src={DeleteIcon}/></span>
                            </div>
                        </div>
                        )}
                </section>
                    
            </div>
        </section>
    </div>
  )
}

export default ParameterList