import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../components/Button/Button.css";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import SideBar from "../../components/SideBar/SideBar";
import { ProtectedRoute } from "../../library/ProtectedRoute";
import Utils from "../../library/Utils";
import { FetchDataStatus } from "../../library/Variables";
import { loggedIn, logout, selectLoggedIn } from "../../redux/Slices/Auth";
import {
  getConfigurationAsync,
  selectConfigurationState,
} from "../../redux/Slices/Configuration";
import {
  getUserSubscription,
  selectSubscription,
  setSubscription,
} from "../../redux/Slices/Subscription";
import { getUserAsync, selectUser, setUser } from "../../redux/Slices/User";
import AgencyBanking from "../AgencyBanking/AgencyBanking";
import AgentsBreakdown from "../AgencyBanking/AgencyPages/AgentsBreakdown";
import ConsolidatedStatement from "../ConsolidatedStatement/ConsolidatedStatement";
import ChangeClientPassword from "../ForceChangePassword/ChangeClientPassword";
import ConsolidatedNigeria from "../ConsolidatedStatement/NigeriaConsolidatedStatements/ConsolidatedNigeria";
import CreateRule from "../FraudDetect/Rules/routes/CreateRules";
import CreditBureauStandAlone from "../CreditBureau/CreditBureauStandAlone";
import { CountryCodes } from "../../library/CountryCodes";
import CustomRules from "../FraudDetect/Rules/routes/CustomRule";
import DecisionEngine from "../DecisionEngine/DecisionEngine";
import DecisionSelfService from "../DecisionSelfService";
import DefineParameters from "../DecisionSelfService/CreateDecision/DefineParameters/DefineParameters";
import DefineRulesInitials from "../DecisionSelfService/RulesDefinition/DefineRulesInitials";
import DraftForm from "../Loans/DraftLoanForms/DraftForm";
import EditRules from "../FraudDetect/Rules/routes/EditRules";
import Error from "../Error/Error";
import FormPreview from "../Loans/FormPreview/FormPreview";
import FraudDetect from "../FraudDetect";
import Home from "../Home/Home";
import IdVerification from "../IdVerification";
import IdVerificationKenya from "../idVerificaTionKenya";
import LoanManagePage from "../Loans/LoanManager/LoanManagementPage";
import Loans from "../Loans/Loans";
import Login from "../Login/Login";
import ResetPassword from "../ResetPassword/ResetPassword";
import MobileInsight from "../MobileInsight/MobileInsight";
import RulesDashboard from "../FraudDetect/Rules/routes";
import NotFound from "../NotFound/NotFound";
import Settings from "../Settings/Settings";
import Spinner from "../../svg/Spinner/Spinner";
import Statement from "../Statement/Statement";
import Transaction from "../FraudDetect/transactions/routes";
import TransactionDetails from "../FraudDetect/transactions/routes/TransactionDetail";
import ViewCustomRule from "../FraudDetect/Rules/routes/ViewCustomRule";
import Wallet from "../Wallet/Wallet";













import "./App.css";

function App() {
  let component = (
    <div className="app-loading-container">
      <Spinner />
    </div>
  );
  let lastVisited = localStorage.getItem("insightsLastVisited");
  let redirectToHome = localStorage.getItem("insightsRedirectToHome");
  let placeholderOrigin;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("insightsAccessToken");
  const accessExpiry = localStorage.getItem("insightsAccessExpiry");
  const configuration = useSelector(selectConfigurationState);
  const loggedInState = useSelector(selectLoggedIn);
  const user = useSelector(selectUser);
  const subscriptionState = useSelector(selectSubscription);
  const tokenCheckerInterval = useRef(null);
  const [isFinishedLoading, setIsFinishedLoading] = useState(false);
  const location = useLocation();

  const urlParams = new URLSearchParams(window.location.search);
  const tkValue = urlParams.get('tkValue')
  const tkDate = urlParams.get('tkDate')
  const [urlState,setUrlState] = useState(urlParams)
  const [tkValueState, setTkValueState] = useState(urlParams.get('tkValue'));
  const [tkDateState, setTkDateState] = useState(urlParams.get('tkDate'));
  const [accessTokenState,setAccessTokenState] = useState('')


  const redirectOnLogin = () => {
    if (
      !Utils.isFieldEmpty(lastVisited) &&
      Utils.isFieldEmpty(redirectToHome)
    ) {
      navigate(lastVisited);
    } else {
      navigate("/home");
    }
  };

  useEffect(()=>{

    const oneVal = urlParams.get('tkValue')
    const twoVal = urlParams.get('tkDate')

    if(!Utils.isFieldEmpty(tkValueState) && !Utils.isFieldEmpty(tkDateState)){
      setAccessTokenState(tkValue)
      localStorage.setItem('insightsAccessToken',tkValueState)
      localStorage.setItem('insightsLoggedInTime',tkDateState)

    }
  },[tkValueState,tkDateState])

  useEffect(() => {
    
    if (
      Utils.isFieldEmpty(configuration) ||
      configuration.configurationStatus === FetchDataStatus.INITIAL
    ) {
      dispatch(getConfigurationAsync());
    }

    if (!Utils.isFieldEmpty(accessToken) || !Utils.isFieldEmpty(accessTokenState)) {
      if(!Utils.isFieldEmpty(accessToken)){
        dispatch(loggedIn({ accessToken: accessToken }));
      }

      else if (!Utils.isFieldEmpty(accessTokenState)){
        dispatch(loggedIn({ accessToken: accessTokenState }));
      }
      
    }

    if (loggedInState === true && (!Utils.isFieldEmpty(accessToken) || !Utils.isFieldEmpty(accessTokenState)) ) {
  
      let loggedInDate = localStorage.getItem("insightsLoggedInTime");
      let expiresDate = DateTime.fromISO(loggedInDate).plus({
        seconds: accessExpiry,
      });

      if (user.status === FetchDataStatus.INITIAL) {
        if(!Utils.isFieldEmpty(accessToken)){

          dispatch(getUserAsync(accessToken))
            .unwrap()
            .then((data) => {
              dispatch(setUser(data));
              if(data.forcePasswordChange){
                navigate('/changePassword')
              }
            });
        }

        else{
          dispatch(getUserAsync(accessTokenState))
            .unwrap()
            .then((data) => {
              dispatch(setUser(data));
              if(data.forcePasswordChange){
                navigate('/changePassword')
              }
            });
        }
      }

      if (subscriptionState.status === FetchDataStatus.INITIAL) {
        if(!Utils.isFieldEmpty(accessToken)){
          dispatch(getUserSubscription(accessToken))
          .unwrap()
          .then((data) => {
            dispatch(setSubscription(data));
          });
        }

        else{
          dispatch(getUserSubscription(accessTokenState))
          .unwrap()
          .then((data) => {
            dispatch(setSubscription(data));
          });
        }
        
      }

      if (
        user.status === FetchDataStatus.SUCCESS &&
        !Utils.isFieldEmpty(user.user)
      ) {
        let originToRedirect;

        if (
          ["/login", "/"].includes(location.pathname) &&
          loggedInState === true
        ) {
          redirectOnLogin();
        }

        /**
         *   if(!Utils.isFieldEmpty(user.user.origin) && !window.location.href.includes(user.user.origin, 0)) {  
          console.log('here now') 
          const urlOrigin = window.location.origin;
          setIsFinishedLoading(false);     
         
          originToRedirect = urlOrigin;
        
      
          let p = new Promise((resolve, reject) => {
            dispatch(logout());
            localStorage.removeItem('insightsRedirectToHome');
            resolve();
          });
          
          p.then(() => {
            window.location.href = originToRedirect;
            console.log('in home')
            
          })
        } else {
          console.log('step 1')
          if (["/login", "/"].includes(location.pathname) && loggedInState === true) {
            console.log('step 2')
            redirectOnLogin();
          }
        }
         */
      }

      if (tokenCheckerInterval.current === null) {
        tokenCheckerInterval.current = setInterval(() => {
          let currentDateTime = DateTime.now();
          if (
            Utils.isTokenExpired(
              expiresDate.toMillis(),
              currentDateTime.toMillis()
            )
          ) {
            localStorage.removeItem("insightsRedirectToHome");
            dispatch(logout());
            navigate("/");
          }
        }, 120000);
      }
    } else {
      clearInterval(tokenCheckerInterval.current);
      tokenCheckerInterval.current = null;
    }

    if (
      user.status === FetchDataStatus.FAILURE &&
      !Utils.isFieldEmpty(accessToken)
    ) {
      dispatch(logout());
    }
  }, [
    accessToken,
    accessTokenState,
    dispatch,
    user,
    subscriptionState,
    configuration,
    loggedInState,
    location.pathname,
  ]);

  if (
    (user.status === FetchDataStatus.SUCCESS ||
      user.status === FetchDataStatus.FAILURE ||
      Utils.isFieldEmpty(accessToken)) &&
    configuration.configurationStatus === FetchDataStatus.SUCCESS
  ) {
    if (isFinishedLoading === false) {
      setIsFinishedLoading(true);
    }
  }

  if (
    configuration.configuration.isInMaintenance === false &&
    isFinishedLoading === true
  ) {
    component = (
      <Routes>
        <Route
          path="/"
          element={<Login configuration={configuration.configuration} />}
        />
        <Route
          path="/login"
          element={<Login configuration={configuration.configuration} />}
        />

        <Route
          path="/changePassword"
          element={
            <ProtectedRoute user={user}>


              <ChangeClientPassword 
                        accessToken={accessToken}
                        configuration={configuration.configuration}
                        />
            
            </ProtectedRoute>}
        />


        <Route
          path="/confirmPassword"
          element={<ResetPassword configuration={configuration.configuration} />}
        />

        <Route
          path="/home"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body-with-navbar">
         
                    <NavBar
                      configuration={configuration.configuration}
                      user={user}
                      
                    />
          
                    
                  <Home
                    configuration={configuration.configuration}
                    subscriptionState={subscriptionState}
                    user={user}
                    accessToken={accessToken}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/wallet"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <Wallet
                    configuration={configuration.configuration}
                    user={user}
                    subscriptionState={subscriptionState}
                    accessToken={accessToken}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/error"
          element={
            <Error configuration={configuration.configuration} user={user} />
          }
        />
        <Route
          path="/statement/:key"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <Statement
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/consolidatedstatement/:key"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <ConsolidatedStatement
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/loanApproval/:key"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <FormPreview
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/draftedForm/:key"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <DraftForm
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/consolidatedstatementNg/:key"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <ConsolidatedNigeria
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/IdentityVerification"
          element={
            <ProtectedRoute user={user}>
              {configuration.configuration.countryCode === CountryCodes.NIGERIA ? (
                <div className="parent-body">
                  <SideBar
                    user={user}
                    configuration={configuration.configuration}
                    setAccessTokenState={setAccessTokenState}
                  />
                  <div className="main-body">
                    <IdVerification
                      configuration={configuration.configuration}
                      accessToken={accessToken}
                      user={user}
                    />
                    <Footer />
                  </div>
                </div>
              ) : (
                <div className="parent-body">
                  <SideBar
                    user={user}
                    configuration={configuration.configuration}
                    setAccessTokenState={setAccessTokenState}
                  />
                  <div className="main-body">
                    <IdVerificationKenya
                      configuration={configuration.configuration}
                      accessToken={accessToken}
                      user={user}
                    />
                    <Footer />
                  </div>
                </div>
              )}
            </ProtectedRoute>
          }
        />

        <Route
          path="/LoanManagement"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <LoanManagePage
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/DecisionEngineSelfService"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <DecisionSelfService
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/creditBureau"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <CreditBureauStandAlone
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/DecisionEngineSelfService/ParameterCreation"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <DefineParameters
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/DecisionEngineSelfService/RulesCreation"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <DefineRulesInitials
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/decisionengine"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <DecisionEngine
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />


        <Route
          path="/agencyBanking"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                
                <div className="main-body">
                  <AgencyBanking
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/agentBreakdown"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <AgentsBreakdown
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/loanApproval"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <Loans
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/fraud-detect"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <FraudDetect
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/fraud-detect/rules"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <RulesDashboard
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/fraud-detect/:userId/transactions"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <Transaction
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/fraud-detect/:userId/transactions/:transactionId"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <TransactionDetails
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/fraud-detect/create-rule"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <CreateRule
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/fraud-detect/custom-rule"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <CustomRules
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/fraud-detect/custom-rule/:id"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <ViewCustomRule
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="/fraud-detect/rules/:id"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <EditRules
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                    user={user}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/mobileinsight/:key"
          element={
            <ProtectedRoute user={user}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <MobileInsight
                    configuration={configuration.configuration}
                    accessToken={accessToken}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute user={user} isSettingsPage={true}>
              <div className="parent-body">
                <SideBar
                  user={user}
                  configuration={configuration.configuration}
                  setAccessTokenState={setAccessTokenState}
                />
                <div className="main-body">
                  <Settings
                    configuration={configuration.configuration}
                    user={user}
                    accessToken={accessToken}
                  />
                  <Footer />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
        <Route path="/404" element={<NotFound />} />
      </Routes>
    );
  } else if (
    configuration.configuration.isInMaintenance === true &&
    isFinishedLoading === true
  ) {
    component = <h3>The site is currently undergoing maintenance.</h3>;
  }

  return component;
}

export default App;

//<IdKyc configuration={configuration.configuration} accessToken={accessToken} user={user} />

/**
 *  <Route path="/IdentityVerification" element={
        <ProtectedRoute user={user}>
          <div className="parent-body">
            <SideBar user={user} configuration={configuration.configuration}/>
            <div className="main-body">
              <IdVerification configuration={configuration.configuration} accessToken={accessToken} user={user}/>
              <Footer />
            </div>
          </div>
        </ProtectedRoute>
      } /> 
 */
