import React from "react";
import "./Settings.css";
import Tabs from "../../components/Tabs/Tabs";
import Api from "./Sections/Api";
import Profile from "./Sections/Profile";
import UserManagement from "./Sections/UserManagement";

function Settings(props) {
  let tabBodiesArray = [];
  const configuration = props.configuration;
  const accessToken = props.accessToken;
  const user = props.user;

  if(user.user.role === 'DEVELOPER'){
    tabBodiesArray = [
      {
        title: "Profile",
        content: <Profile user={user} configuration={configuration} accessToken={accessToken} />
      },
      {
        title: "API Settings",
        content: <Api accessToken={accessToken} 
                      configuration={configuration}
                      user={user}
                      />
      },
    ]
  }

  else if(user.user.role === 'ADMIN'){
    tabBodiesArray = [
      {
        title: "Profile",
        content: <Profile user={user} configuration={configuration} accessToken={accessToken} />
      },
      {
        title: "API Settings",
        content: <Api accessToken={accessToken} 
                      configuration={configuration}
                      user={user}
                      />
      },
      {
        title: "User Management",
        content: <UserManagement accessToken={accessToken} platformUser={user} configuration={configuration}/>
      }
    ]
  }

  else{
    tabBodiesArray = [
      {
        title: "Profile",
        content: <Profile user={user} configuration={configuration} accessToken={accessToken} />
      }]
  }
 

  return (
    <div className="settings-body">
      <h2 className="text-darker">Settings</h2>
      <Tabs tabBodies={tabBodiesArray} extraClass="top-level-tabs" />
    </div>
  )
}

export default Settings;